export const deliverTypes = [
    {key: "anything", text: "Anything", value: 1},
    {key: "document", text: "Document", value: 2}
]

export const disabilitiesTypes = [
    {key: "none", text: "None", value: 1},
    {key: "wheeledChair", text: "Wheeled Chair", value: 2},
    {key: "blind", text: "Blind", value: 3},
    {key: "deaf", text: "Deaf", value: 4},
]

export const transportationTypes = [
    {key: "plane", text: "Plane", value: 1},
    {key: "car", text: "Car", value: 2},
    {key: "train", text: "Train", value: 3},
    {key: "ship", text: "Ship", value: 4}
]

export const weightUnits = [
    {key: "kg", text: "Kg", value: 1},
    {key: "lbs", text: "Lbs", value: 2}
]

export const dimensionUnit = [
    {key: "in", text: "In", value: 1},
    {key: "cm", text: "Cm", value: 2}
]

export const paymentMethods = [
    {key: "cash", text: "Cash", value: 1},
    {key: "credit", text: "Credit card", value: 2},
    {key: "Paypal", text: "PayPal", value: 3}
]

export const serviceTypes = [
    {key: "deliver", text: "Deliver only", value: 1},
    {key: "escorting", text: "Escorting", value: 3},
    {key: "animals", text: "Transporting animals", value: 4},
    {key: "purchase-deliver ", text: "Purchase and deliver (Soon)", value: 2, disabled: true},
]

export const requestStatuses = [
    {key: "created", text: "Created", value: 1},
    {key: "confirmed ", text: "Confirmed", value: 2},
    {key: "picked ", text: "Picked", value: 3},
    {key: "beingDelivered ", text: "Being delivered", value: 4},
    {key: "delivered ", text: "Delivered", value: 5},
    {key: "expired ", text: "Expired", value: -1},
]

export const tripStatuses = [
    {key: "scheduled", text: "Scheduled", value: 1},
    {key: "ongoing ", text: "On going", value: 2},
    {key: "completed ", text: "Completed", value: 3},
    {key: "Cancelled ", text: "Cancelled", value: 4},
    {key: "expired ", text: "Expired", value: -1},
]


export const ticketStatuses = [
    {key: "pending", text: "Pending", value: 1},
    {key: "processing ", text: "Processing", value: 2},
    {key: "closed ", text: "Closed", value: 3}
]

export const ticketCategories = [
    {key: "problem", text: "Problem", value: 1},
    {key: "inquiry ", text: "Inquiry", value: 2},
    {key: "suggestion ", text: "Suggestion", value: 3}
]

export const ticketsPriorities = [
    {key: "low", text: "Low", value: 1},
    {key: "normal ", text: "Normal", value: 2},
    {key: "high ", text: "High", value: 3},
    {key: "urgent ", text: "Urgent", value: 4}
]


