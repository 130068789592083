import AxiosApi from '../../api/axios-api';


export default class ProfileApi {

    static update = async (body) => {
        const {oldPassword, newPassword, firstName, lastName,
            emailNotificationsEnabled, phoneNumberNSN, phoneNumberCountryCode} = body;
        const requestBody = {
            oldPassword,
            newPassword,
            firstName,
            lastName,
            emailNotificationsEnabled,
            phoneNumberNSN,
            phoneNumberCountryCode
        };
        return await AxiosApi.call(requestBody, '/user', 'put', false, false, true);
    };

    static getProfileInfo = async (body) => {
        return await AxiosApi.call(body, '/user', 'get', false, false, true);
    }
}