import React, {Component} from 'react';

export default class HealthChecker extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                Success
            </div>

        );
    }
}
