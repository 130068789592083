import React, {Component} from 'react';
import {Tab, Menu, Icon, Loader, Dimmer} from 'semantic-ui-react';
import AddRequest from './add-request';
import GetRequestAvailableTrips from './get-reuest-available-trips'

export default class AddRequestTabs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tripsTabEnabled: !props.match.params.id,
            defaultActiveIndex: 0,
            requestId: props.match.params.id ? props.match.params.id : "",
            loader: props.match.params.id
        }
    }

    enableAvailableTrips = (data) => {
        let tripsTabEnabled = this.state.tripsTabEnabled;
        this.setState({
            tripsTabEnabled: this.props.match.params.id ? true : !tripsTabEnabled,
            defaultActiveIndex: 1,
            availableTrips: data ? data.availableTrips : [],
            requestId: data ? data.requestId : ""
        })
    }

    updateLoader = () => {
        this.setState({
            loader: false
        })
    }

    changeTab = (index) => {
        this.setState({
            defaultActiveIndex: index
        })
    }

    render() {
        let width = `${100 / 2}%`;
        let {tripsTabEnabled, defaultActiveIndex, requestId, loader} = this.state;

        let panes = [{
            menuItem: (
                <Menu.Item onClick={() => this.changeTab(0)} key='request_details'
                           style={{width: width, textAlign: "center"}}>
                    <h4 className={'tab-header'}>Request details
                        <Icon style={{marginLeft: '15px'}} name={'arrow right'}/>
                    </h4>
                </Menu.Item>
            ),
            render: () => <Tab.Pane>
                <Dimmer active={loader}>
                    <Loader active={loader} size={'small'}/>
                </Dimmer>
                <AddRequest props={this} requestId={requestId}
                            enableAvailableTrips={this.enableAvailableTrips}/>
            </Tab.Pane>,
        },
            {
                menuItem: (
                    <Menu.Item onClick={() => this.changeTab(1)} key='available_trips'
                               style={{width: width, textAlign: "center"}} disabled={tripsTabEnabled}>
                        <h4 className={'tab-header'}>Available trips
                            <Icon style={{marginLeft: '15px'}} name={'arrow right'}/>
                        </h4>
                    </Menu.Item>
                ),
                render: () => <Tab.Pane><GetRequestAvailableTrips notify={this.props.notify} props={this}/></Tab.Pane>,
            }
        ]

        return (
            <div>
                <Tab
                    className={'request-tabs'}
                    menu={{attached: false}}
                    activeIndex={defaultActiveIndex}
                    //defaultActiveIndex={0}
                    panes={panes}/>
            </div>
        );
    }
}
