import * as CryptoJS from "crypto-js"
import {errorsMessages} from "./errorMessages"
import {tSRestType} from "@babel/types/lib";

const keys = {
    encryptionKey: "fMefNfHmFdWusNxNhPXnCR",
    initializationVector: "FxaHV4nuHnnHPSxdsBzDoB"
}
export default class HelperFunctions {
    static isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    static toIsoString(date, tzo, withTime = true) {
        let calculateTz = tzo ? tzo : 0;
        let absTz = Math.abs(calculateTz)
        let formattedTz = absTz.toString().length === 1 ? '0' + absTz : absTz;
        var
            dif = tzo >= 0 ? '+' : '-',
            pad = function (num) {
                var norm = Math.floor(Math.abs(num));
                return (norm < 10 ? '0' : '') + norm;
            };
        let returnedDate = typeof date === 'string' ? date
            : date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate())
        if (withTime) {
            returnedDate = returnedDate + 'T' +
                pad(date.getHours()) +
                ':' + pad(date.getMinutes()) +
                ':' + pad(date.getSeconds())
        }
        returnedDate = returnedDate + dif
            + formattedTz + ':00';

        return returnedDate;
    }


    static getResponseData = (response) => {
        if (this.isEmpty(response)) return null;

        let result = {};

        if (response.status === 200) {
            // const firstKey = Object.keys(data);
            // return data[firstKey];
            if(response.data){
                if(response.data.hasContent){
                    result = response.data
                }
            }
            response.data.messages[0].message = response.data.messages[0].text;
            result = {...result,...response.data.messages[0]}

        }

        if(response.status !== 200){
            if(response.data.messages.length === 1){
                response.data.messages[0].message = response.data.messages[0].text;
                result.data = response.data.messages[0]
            } else{
                result.data = response.data.messages
            }
        }

        if (response.data && response.data.errors) {
            result = response.data.errors[0].message;

        }

        return !this.isEmpty(result) ? result : null;
    }


    static isObject = (obj) => typeof obj === 'object'

    static isArray = (array) => Array.isArray(array)

    static spreadFields = (obj) => Object.keys(obj).map(key => {
        let item;
        if (typeof obj[key] === 'string') {
            item = `${key}: "${obj[key]}"`;
        } else if (typeof obj[key] === 'object') {
            item = `${key}: "${JSON.stringify(obj[key])}"`;
        }

        return item;

    }).join(',')

    static unique = () => Math.random().toString().substr(2, 8)

    static dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }

    static findObjectInArrayByKey(array, key, value) {
        if (!array)
            return {}

        return array.find(item => item[key] === value) ? array.find(item => item[key] === value) : {}
    }

    static findFavoriteItem = (id) => {
        const wishList = localStorage.getItem('wishlist');
        if (!wishList) return null;

        return (JSON.parse(wishList).find(item => item._id === id))
    }


    static getDateAndTime = (dateTimeStr) => {
        const date = new Date(dateTimeStr);
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
    }

    static empty = (value) => {
        return value || '-'
    }

    static emptyString(value) {
        return (typeof value === "undefined" || typeof value === 'undefined' || typeof value === undefined || value === null || value === '' || value === "" || value.length === 0 || value === 0 || value === 'undefined');
    }

    static generateEncryptionKey = () => {
        const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
        let randomString = ""
        for (let i = 0; i <= 21; i++) {
            let randomNum = Math.floor(Math.random() * characters.length);
            randomString += characters.substring(randomNum, randomNum + 1);
        }
        return randomString
    }
    static encryptText = (text) => {
        let key = CryptoJS.enc.Base64.parse(keys.encryptionKey) //length=22
        let iv = keys.initializationVector //length=22
        iv = CryptoJS.enc.Base64.parse(iv)
        return CryptoJS.AES.encrypt(text, key, {iv: iv}).toString()
    }

    static decryptText = (text) => {
        let key = CryptoJS.enc.Base64.parse(keys.encryptionKey) //length=22
        let iv = keys.initializationVector //length=22
        iv = CryptoJS.enc.Base64.parse(iv)
        let decryptedText = CryptoJS.AES.decrypt(text, key, {iv: iv})
        return decryptedText.toString(CryptoJS.enc.Utf8)
    }

    static timeStampToDateTimeAsString(event, withTime, isTimeOnly = false) {
        if (typeof event === 'number') {
            event *= 1000
        }

        let date = new Date(event)
        let hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
        let minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        let seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
        let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
        let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
        let year = date.getFullYear()
        if (isTimeOnly) {
            return `${hours}:${minutes}:${seconds}`;
        } else {
            return withTime ? `${year}-${month}-${day} ${hours}:${minutes}:${seconds}` : `${year}-${month}-${day}`
        }
    }

    static errorObjectFormater(error) {
        let errorObject = errorsMessages.find(a => a.code === error.code)
        if (!this.isEmpty(errorObject)) {
            let serverValidationError = {}
            serverValidationError[errorObject.field] = errorObject.message
            return serverValidationError
        }
    }

    static statusColorMapping(status) {
        if (status === null)
            return null;
        let color = '';

        switch (status) {
            case 'Created':
            case 'Scheduled':
                color = "created-color"; //#b5ca31
                break;
            case 'Confirmed':
            case 'On going':
                color = "confirmed-color"; //#2db84b
                break;
            case 'Delivered':
            case 'Completed':
                color = "complete-color"; //#0090FF
                break;
            case 'Expired':
            case 'Cancelled':
                color = "red";
                break;
            default:
                color = "gray";
                break
        }
        return color

    }

    static passwordStrength(object, password) {

        const isNumberRegx = /\d/;
        const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        const lowerRegex = /[a-z]/;
        const upperRegex = /[A-Z]/;

        object.minChar = password.length >= 8 ? true : false;
        object.number = isNumberRegx.test(password) ? true : false;
        object.lowerCase = lowerRegex.test(password) ? true : false;
        object.upperCase = upperRegex.test(password) ? true : false;
        object.specialChar = specialCharacterRegx.test(password) ? true : false

        return object;
    }
}