import React, {Component} from 'react';


export default class PasswordStrengthIndicator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShow: true,
            passwordValidity: this.props.validity
        };
    }

    render() {

        let highlightClass = "red"
        let text = "Weak"
        let minChar = this.state.passwordValidity.minChar
        let number = this.state.passwordValidity.number
        let lowerCase = this.state.passwordValidity.lowerCase
        let upperCase = this.state.passwordValidity.upperCase
        let specialChar = this.state.passwordValidity.specialChar

        if (minChar) {
            if ((number && lowerCase && specialChar) || (number && lowerCase && upperCase) || (specialChar && lowerCase && upperCase)
                || (number && upperCase && specialChar)) {
                text = "Mid"
                highlightClass = "blue"
            }
        }
        if (minChar && specialChar && number && lowerCase && upperCase) {
            text = "Strong"
            highlightClass = "green"
        }
        return <div className={"ui " + highlightClass + " label floated-right strength"}>{text}</div>;
    }
}
