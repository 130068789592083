import AxiosApi from '../../api/axios-api';
import Helper from "../../utils/helper";

export default class TripApi {

    constructor(props){
        this.body = props;
    }

    add = async (body) => {
        body.date= Helper.toIsoString(body.date2, body.timezone, false);
        body.date = `${body.date.substring(0, 10)}T${body.time}${body.date.substring(10)}`;
        body.from= {
            countryId: body.fromCountryId,
            stateId: body.fromStateId,
            cityId: body.fromCityId
        };
        body.to= {
            countryId: body.toCountryId,
            stateId: body.toStateId,
            cityId: body.toCityId
        };
        body.deliverableInfo={
            weight: body.weight,
            weightUnit: body.weightUnit,
            weightUnitPrice: body.weightUnitPrice,
            volumetricPrice: body.volumetricPrice,
            documentPrice: body.documentPrice,
            price: body.servicePrice,
            kidsAccepted: body.kidsAccepted,
            elderlyAccepted: body.elderlyAccepted,
            airportFrom: body.airportFrom,
            airportTo: body.airportTo,
            flightNumber: body.flightNumber
        }
        return await AxiosApi.call(body, '/trip', 'post');
    };

    update = async (body) => {
        body.date= Helper.toIsoString(body.date2, body.timezone, false)
        body.date = `${body.date.substring(0, 10)}T${body.time}${body.date.substring(10)}`;
        body.from= {
            countryId: body.fromCountryId,
            stateId: body.fromStateId,
            cityId: body.fromCityId
        };
        body.to= {
            countryId: body.toCountryId,
            stateId: body.toStateId,
            cityId: body.toCityId
        };
        body.deliverableInfo={
            weight: body.weight,
            weightUnit: body.weightUnit,
            weightUnitPrice: body.weightUnitPrice,
            volumetricPrice: body.volumetricPrice,
            documentPrice: body.documentPrice,
            price: body.servicePrice,
            kidsAccepted: body.kidsAccepted,
            elderlyAccepted: body.elderlyAccepted,
            airportFrom: body.airportFrom,
            airportTo: body.airportTo,
            flightNumber: body.flightNumber
        }
        return await AxiosApi.call(body, `/trip/${body.id}`, 'put');
    };

    getOne = async (body) => {
        return await AxiosApi.call(body, `/trip/${body.id}`, 'get');
    };

    getAll = async (body) => {
        return await AxiosApi.call(body, `/trip/${body.pageNumber}?fromCountryId=${body.fromCountryId}&fromStateId=${body.fromStateId}&fromCityId=${body.fromCityId}&toCountryId=${body.toCountryId}&toStateId=${body.toStateId}&toCityId=${body.toCityId}&sortByDate=${body.sort}`, 'get');
    };

    getMine = async (body) => {
        return await AxiosApi.call(body, `/trip/mine/${body.pageNumber}?fromCountryId=${body.fromCountryId}&fromStateId=${body.fromStateId}&fromCityId=${body.fromCityId}&toCountryId=${body.toCountryId}&toStateId=${body.toStateId}&toCityId=${body.toCityId}&sortByDate=${body.sort}`, 'get');
    };

    getAvailableForRequest = async (body) => {
        return await AxiosApi.call(body, `/trip/${body.pageNumber}/for-request/${body.requestId}`, 'get');
    };

    createQuoteRequest = async (tripId, requestId) => {
        let body= {tripId, requestId}
        return await AxiosApi.call(body, `/quote/request`, 'post');
    };

    deleteTrip = async (body) => {

        return await AxiosApi.call(body, `/trip/${body.id}`, 'delete');
    };

    updateRequestStatus = async (body) => {
        return await AxiosApi.call(body, `/trip/${body.tripId}/request-status`, 'patch');
    };

    updateTripStatus = async (body) => {
        return await AxiosApi.call(body, `/trip/${body.id}`, 'patch');
    };

    getCountries = async (body) => {
        return await AxiosApi.call(body, `/country?filter=${body.filter}`, 'get')
    };

    getStates = async (body) => {
        return await AxiosApi.call(body, `/country/${body.countryId}/states`, 'get')
    };

    getCities = async (body) => {
        return await AxiosApi.call(body, `/state/${body.stateId}/cities`, 'get')
    };

    revealTrip = async (body) => {
        return await AxiosApi.call(body, `/trip/${body.id}/revelation`, 'patch')
    }

    hideTrip = async (body) => {
        return await AxiosApi.call(body, `/trip/${body.id}/hiding`, 'patch')
    }
}