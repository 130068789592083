import React, {Component} from "react"
import {Button, Form, Segment, Grid, Divider} from "semantic-ui-react"
import update from "immutability-helper";
import {ruleRunner, run} from "../../../utils/ruleRunner";
import {match, required, textRegex} from "../../../utils/rules";
import AppContext from "../../../../context/app-context";
import Helper from "../../../utils/helper";
import ProfileApi from "../../api/profile-api"

export default class EmailPassword extends Component {
    static contextType = AppContext;
    fieldValidations = [ruleRunner("phoneNumberNSN",
        'phoneNumberNSN', textRegex('phoneNumberNSN', 'phoneNumber')),
        ruleRunner("phoneNumberCountryCode",
            'phoneNumberCountryCode', textRegex('phoneNumberCountryCode', 'country'))];

    constructor(props) {
        super(props);
        let profileData = props.data ? props.data : {};
        this.state = {
            validationErrors: {},
            updateForm: {
                oldPassword: "",
                newPassword: "",
                firstName: profileData ? profileData.firstName : "",
                lastName: profileData ? profileData.lastName : "",
                phoneNumberCountryCode: profileData ? profileData.phoneNumberCountryCode : "",
                phoneNumberNSN: profileData ? profileData.phoneNumberNSN : "",
                repeatPassword: "",
            },
            innerWidth: window.innerWidth,
            disableOldPassword: true,
            disableRepeatPassword: true,
            disableLastName: true,
            disableFirstName: true,
            disableCountryCode: true,
            disablePhoneNumber: true,
        }
    }

    validateState = () => {
        this.setState({validationErrors: run(this.state.updateForm, this.fieldValidations)});
    };

    handleFieldChanged = (field) => {
        return (e, data) => {
            let {
                disableOldPassword, disableRepeatPassword,
                disableLastName, disableFirstName, updateForm,
                disablePhoneNumber, disableCountryCode
            } = this.state;
            switch (field) {
                case 'newPassword':
                    if (Helper.emptyString(data.value)) {
                        disableOldPassword = true;
                        disableRepeatPassword = true
                    }
                    if (disableOldPassword && !Helper.emptyString(data.value)) {
                        this.fieldValidations.push(
                            ruleRunner("oldPassword", 'oldPassword', required));
                        disableOldPassword = false
                    }
                    if (disableRepeatPassword && !Helper.emptyString(data.value)) {
                        this.fieldValidations.push(
                            ruleRunner("repeatPassword", 'repeatPassword', required, match('newPassword', 'repeatPassword')));
                        disableRepeatPassword = false
                    }
                    break;
                case 'firstName':
                    if (Helper.emptyString(data.value)) {
                        disableLastName = true;
                        if (!Helper.emptyString(updateForm.lastName)) {
                            this.fieldValidations.push(ruleRunner("firstName", 'firstName', required));
                            disableFirstName = false
                        }
                    }
                    if (disableLastName && !Helper.emptyString(data.value)) {
                        this.fieldValidations.push(ruleRunner("lastName", 'lastName', required));
                        disableLastName = false
                    }
                    break;
                case 'lastName':
                    if (Helper.emptyString(data.value)) {
                        disableFirstName = true;
                        if (!Helper.emptyString(updateForm.firstName)) {
                            this.fieldValidations.push(ruleRunner("lastName", 'lastName', required));
                            disableLastName = false
                        }

                    }
                    if (disableFirstName && !Helper.emptyString(data.value)) {
                        this.fieldValidations.push(ruleRunner("firstName", 'firstName', required));
                        disableFirstName = false
                    }
                    break;
                case 'phoneNumberCountryCode':
                    if (Helper.emptyString(data.value)) {
                        disablePhoneNumber = true;
                        if (!Helper.emptyString(updateForm.phoneNumberNSN)) {
                            this.fieldValidations.push(
                                ruleRunner("phoneNumberCountryCode", 'phoneNumberCountryCode', required),
                                ruleRunner("phoneNumberCountryCode",
                                    'phoneNumberCountryCode', textRegex('phoneNumberCountryCode', 'country'))
                            );
                            disableCountryCode = false
                        }

                    }
                    if (disablePhoneNumber && !Helper.emptyString(data.value)) {
                        this.fieldValidations.push(
                            ruleRunner("phoneNumberNSN", 'phoneNumberNSN', required),
                            ruleRunner("phoneNumberNSN",
                            'phoneNumberNSN', textRegex('phoneNumberNSN', 'phoneNumber')),
                            ruleRunner("phoneNumberCountryCode",
                                'phoneNumberCountryCode', textRegex('phoneNumberCountryCode', 'country'))
                        );
                        disablePhoneNumber = false
                    }

                    break;
                case 'phoneNumberNSN':
                    if (Helper.emptyString(data.value)) {
                        disableCountryCode = true;
                        if (!Helper.emptyString(updateForm.phoneNumberCountryCode)) {
                            this.fieldValidations.push(
                                ruleRunner("phoneNumberNSN", 'phoneNumberNSN', required),
                                ruleRunner("phoneNumberNSN",
                                    'phoneNumberNSN', textRegex('phoneNumberNSN', 'phoneNumber')));
                            disablePhoneNumber = false
                        }

                    }
                    if (disableCountryCode && !Helper.emptyString(data.value)) {
                        this.fieldValidations.push(
                            ruleRunner("phoneNumberCountryCode", 'phoneNumberCountryCode', required),
                            ruleRunner("phoneNumberCountryCode",
                                'phoneNumberCountryCode', textRegex('phoneNumberCountryCode', 'country')),
                            ruleRunner("phoneNumberNSN",
                                'phoneNumberNSN', textRegex('phoneNumberNSN', 'phoneNumber'))
                        );
                        disableCountryCode = false
                    }
                    break;
                default:
                    break
            }

            this.setState({
                updateForm: update(this.state.updateForm, {
                    [field]: {
                        $set: data.value
                    }
                },),
                disableOldPassword: disableOldPassword,
                disableRepeatPassword: disableRepeatPassword,
                disableLastName: disableLastName,
                disableFirstName: disableFirstName,
                disableCountryCode: disableCountryCode,
                disablePhoneNumber: disablePhoneNumber,
            }, () => {
                this.validateState()
            })
        }
    };

    showErrorMessage = (errorObject = null) => {
        if (Helper.isEmpty(errorObject)) {
            this.setState({
                serverError: true,
                success: false,
                serverErrorMessage: 'errorPleaseTryAgain'
            })
        } else {
            this.setState({
                serverError: true,
                success: false,
                serverErrorMessage: errorObject.serverErrorMessage,
                validationErrors: errorObject.validationErrors
            })
        }
    };

    handleSubmit = async () => {
        this.setState({showErrors: true});
        if (!Helper.isEmpty(this.state.validationErrors)) return null;
        let {updateForm} = this.state;


        this.setState({loading: true});
        const response = await ProfileApi.update(updateForm);
        this.setState({loading: false});
        if (response.code === 'SUCCESS005') {
            if (!Helper.emptyString(updateForm.repeatPassword) && !Helper.emptyString(updateForm.newPassword)) {
                this.props.props.history.push('/logout')
            }
            if(!Helper.emptyString(updateForm.firstName) && !Helper.emptyString(updateForm.lastName)){
                localStorage.setItem('identifier', `${updateForm.firstName} ${updateForm.lastName}`)
            }
            this.props.props.notify(false, 'Updated successfully')

        } else {
            //this.props.props.notify(true, 'Error, please try again!')
            let errorData = response.data;
            if (!Helper.isEmpty(errorData)) {
                if(Helper.isArray(errorData)) {
                    let errorObject = {
                        serverError: true,
                        validationErrors: {},
                    };
                    errorData.map((error) => {
                        let serverValidationError = Helper.errorObjectFormater(error);
                        if (!Helper.isEmpty(serverValidationError)) {
                            errorObject = {
                                serverError: true,
                                validationErrors: errorObject.validationErrors ? {...errorObject.validationErrors, ...serverValidationError} : {}
                            };
                        }
                    });
                    this.showErrorMessage(errorObject)
                } else {
                    let serverValidationError = Helper.errorObjectFormater(errorData);
                    if (!Helper.isEmpty(serverValidationError)) {
                        let errorObject = {
                            serverError: true,
                            success: false,
                            validationErrors: serverValidationError,
                            serverErrorMessage: errorData.message
                        };
                        this.showErrorMessage(errorObject)
                    }
                }
            }
        }

    };

    changeInnerWidth = () => {
        this.setState({
            innerWidth: window.innerWidth
        })
    };

    componentWillMount() {
        window.onresize = this.changeInnerWidth;
    }


    render() {
        let {
            updateForm, loading, disableOldPassword,
            disableRepeatPassword, disableLastName, disableFirstName,
            disableCountryCode, disablePhoneNumber
        } = this.state;

        const oldPasswordError = this.context.errorFor(this.state, 'oldPassword', null, true);
        const repeatPasswordError = this.context.errorFor(this.state, 'repeatPassword', null, true);
        const firstNameError = this.context.errorFor(this.state, 'firstName', null, true);
        const lastNameError = this.context.errorFor(this.state, 'lastName', null, true);
        const countryCodeError = this.context.errorFor(this.state, 'phoneNumberCountryCode', null, true);
        const phoneNumberError = this.context.errorFor(this.state, 'phoneNumberNSN', null, true);
        let innerWidth = this.state.innerWidth;

        return (
            <div className={'profile-section'}>
                <Form onSubmit={this.handleSubmit}>
                    <Segment.Group>
                        <Segment>
                            <Grid columns={2} stackable textAlign='left'>
                                <Divider vertical>Or</Divider>

                                <Grid.Row verticalAlign='middle'>
                                    <Grid.Column>
                                        <Form.Field
                                            required={!disableFirstName}
                                            error={disableFirstName ? null : !!firstNameError}
                                        >
                                            <Form.Input placeholder="First name"
                                                        label={"First name"}
                                                        value={updateForm.firstName}
                                                        onChange={this.handleFieldChanged("firstName")}/>
                                            {firstNameError}
                                        </Form.Field>
                                        <Form.Field
                                            required={!disableLastName}
                                            error={disableLastName ? null : !!lastNameError}
                                        >
                                            <Form.Input placeholder="Last name"
                                                        label={"Last name"}
                                                        value={updateForm.lastName}
                                                        onChange={this.handleFieldChanged("lastName")}/>
                                            {lastNameError}
                                        </Form.Field>
                                        <Form.Group>
                                            <Form.Field
                                                required={!disableCountryCode}
                                                error={disableCountryCode ? null : !!countryCodeError}
                                            >
                                                <Form.Input placeholder="Country code"
                                                            label={"Country code"}
                                                            value={updateForm.phoneNumberCountryCode}
                                                            onChange={this.handleFieldChanged("phoneNumberCountryCode")}/>
                                                {countryCodeError}
                                            </Form.Field>
                                            <Form.Field
                                                required={!disablePhoneNumber}
                                                error={disablePhoneNumber ? null : !!phoneNumberError}
                                            >
                                                <Form.Input placeholder="Phone number"
                                                            label={"Phone number"}
                                                            value={updateForm.phoneNumberNSN}
                                                            onChange={this.handleFieldChanged("phoneNumberNSN")}/>
                                                {phoneNumberError}
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>

                                    {
                                        innerWidth < 768 ?
                                            <Divider horizontal>Or</Divider>
                                            : null
                                    }
                                    <Grid.Column>
                                        <Form.Field
                                            required={!disableOldPassword}
                                            error={disableOldPassword ? null : !!oldPasswordError}
                                        >
                                            <Form.Input type="password" placeholder="Old password"
                                                        label={'Old password'}
                                                        value={updateForm.oldPassword}
                                                        onChange={this.handleFieldChanged("oldPassword")}/>
                                            {oldPasswordError}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input type="password" placeholder="New password"
                                                        label={'New password'}
                                                        value={updateForm.newPassword}
                                                        onChange={this.handleFieldChanged("newPassword")}/>
                                        </Form.Field>
                                        <Form.Field
                                            required={!disableRepeatPassword}
                                            error={disableRepeatPassword ? null : !!repeatPasswordError}
                                        >
                                            <Form.Input type="password" placeholder="Confirm new password"
                                                        label={'Confirm new password'}
                                                        value={updateForm.repeatPassword}
                                                        onChange={this.handleFieldChanged("repeatPassword")}/>
                                            {repeatPasswordError}
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Segment.Group>
                    <Form.Field className={'btn-field'}>
                        <Button floated='right' loading={loading}>Update</Button>
                    </Form.Field>
                </Form>
            </div>
        );
    }
}