import React, {Component} from 'react'
import './login.css'
import update from "immutability-helper"
import {
    Button, Input, Form,
    Message, Icon, Segment, Popup
} from 'semantic-ui-react'

import Helper from "../../utils/helper";
import {ruleRunner, run} from "../../utils/ruleRunner";
import {required, email, match} from "../../utils/rules";
import UserApi from "../api/user-api"
import AppContext from "../../../context/app-context";
import PasswordStrengthBar from 'react-password-strength-bar';
import PasswordStrengthIndicator from "../../table/password-strength-indicator"


class LoginForm extends Component {
    static contextType = AppContext;
    loginFieldValidations = [
        ruleRunner("email", 'email', required, email),
        ruleRunner("password", 'password', required)
    ];
    signUpFieldValidations = [
        ruleRunner("email", 'email', required, email),
        ruleRunner("password", 'password', required),
        ruleRunner("repeatPassword", 'repeatPassword', required, match('password', 'repeatPassword')),
    ];

    verifyFieldValidations = [
        ruleRunner("code", 'code', required)
    ];

    resetFieldValidations = [
        ruleRunner("email", 'email', required, email)
    ];

    constructor(props) {
        super(props);
        this.pathName = props ? props.location.pathname.substring(1) : 'trips';
        this.activeForm = this.pathName === "sign-in" ? "login" :
            this.pathName === "sign-up" ? "signUp" : this.pathName === "reset-password" ? "reset" : "verify";
        this.state = {
            validationErrors: {},
            showErrors: false,
            passwordValidity: {

                minChar: null,
                number: null,
                lowerCase: null,
                upperCase: null,
                specialChar: null
            },
            maskStyle: {
                left: 512,
                right: 0
            },
            activeForm: this.activeForm,
            loginForm: {
                email: "",
                password: ""
            },
            resetForm: {
                email: "",
                code: "",
                newPassword: "",
                repeatPassword: ""
            },
            verifyForm: {
                email: "",
                code: ""
            },
            signUpForm: {
                email: "",
                password: "",
                repeatPassword: ""
            },
            signUpLoading: false,
            verifyLoading: false,
            loginLoading: false,
            sendCodeLoading: false,
            resetLoading: false,
            showServerError: false,
            signUpActive: this.activeForm === 'signUp',
            showReset: this.activeForm === 'reset'
        };

        this.container = React.createRef();

        this.onSignIn = this.onSignIn.bind(this);
        this.onSignUp = this.onSignUp.bind(this)

    }

    clearData = () => {
        this.setState({
            loginForm: {
                email: "",
                password: ""
            },
            resetForm: {
                email: "",
                code: "",
                newPassword: "",
                repeatPassword: ""
            },
            verifyForm: {
                email: "",
                code: ""
            },
            signUpForm: {
                email: "",
                password: "",
                repeatPassword: ""
            },
            serverError: false,
            serverErrorMessage: '',
            showErrors: false
        })
    }

    componentDidMount() {
        if (this.state.activeForm === 'signUp') {
            this.onSignUp()
        }
        this.validateState()
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    validateState = () => {
        const {activeForm} = this.state;
        switch (activeForm) {
            case 'login':
                this.setState({validationErrors: run(this.state.loginForm, this.loginFieldValidations)});
                break;
            case 'signUp':
                this.setState({validationErrors: run(this.state.signUpForm, this.signUpFieldValidations)});
                break;
            case 'verify':
                this.setState({validationErrors: run(this.state.verifyForm, this.verifyFieldValidations)});
                break;
            case 'reset':
                this.setState({validationErrors: run(this.state.resetForm, this.resetFieldValidations)});
                break;
            default:
                break
        }
    };

    onSignUp() {
        this.setState({
            activeForm: 'signUp',
            showErrors: false,
            serverError: false,
            serverErrorMessage: '',
            success: false,
            signUpActive: true,
            signUpForm: {
                email: "",
                password: "",
                repeatPassword: ""
            },
        }, () => {
            this.container.current.classList.add("right-panel-active");
            this.props.history.push('/sign-up');
            this.clearData()
        })
    }

    onSignIn() {
        this.setState({
            activeForm: "login",
            showErrors: false,
            serverError: false,
            serverErrorMessage: '',
            showVerifyForm: false,
            showReset: false,
            signUpActive: false,
            loginForm: {
                email: "",
                password: ""
            },

        }, () => {
            this.container.current.classList.remove("right-panel-active");
            this.props.history.push('/sign-in');
            this.clearData()
        })
    }

    handleFieldChanged = (form, field) => {
        return (e, data) => {
            if ((field === 'password' && form === 'signUpForm') ||
                (field === 'newPassword' && form === 'resetForm')) {
                this.state.passwordValidity = Helper.passwordStrength(this.state.passwordValidity, data.value)
            }
            let value = data.value;
            if (field === 'email' && value) {
                value = value.toLowerCase()
            }
            this.setState({
                [form]: update(this.state[form], {
                    [field]: {
                        $set: value
                    }
                })
            }, this.validateState)
        }
    };

    showErrorMessage = (errorObject = null) => {
        if (Helper.isEmpty(errorObject)) {
            this.setState({
                serverError: true,
                success: false,
                serverErrorMessage: 'errorPleaseTryAgain'
            })
        } else {
            this.setState({
                serverError: true,
                success: false,
                serverErrorMessage: errorObject.serverErrorMessage,
                validationErrors: errorObject.validationErrors
            })
        }
    };

    signUp = async () => {
        this.setState({showErrors: true});
        this.validateState();
        if (!Helper.isEmpty(this.state.validationErrors)) return null;
        const {email, password} = this.state.signUpForm;
        const requestBody = {
            email,
            password
        };
        this.setState({signUpLoading: true});
        const response = await UserApi.signUp(requestBody);
        this.setState({signUpLoading: false});
        if (response.code === "SUCCESS002") {

            this.setState({
                showVerifyForm: true,
                activeForm: 'verify',
                verifyForm: {
                    email: email,
                    code: ""
                },
                serverError: false,
            }, () => {
                this.props.notify(false, response.message)
            })

        } else {
            let errorData = response.data;
            if (!Helper.isEmpty(errorData)) {
                let serverErrorMessage = errorData.message;
                let serverValidationError = Helper.errorObjectFormater(errorData);
                if (errorData.code === "USER003" || errorData.code === "USER005") {
                    serverErrorMessage = ""
                }
                if (!Helper.isEmpty(serverValidationError)) {
                    let errorObject = {
                        serverError: true,
                        success: false,
                        validationErrors: serverValidationError,
                        serverErrorMessage: serverErrorMessage
                    };
                    this.showErrorMessage(errorObject)
                }

            }
        }

    };

    verifyAccount = async () => {
        this.setState({showErrors: true});
        this.validateState();
        if (!Helper.isEmpty(this.state.validationErrors)) return null;
        const {verifyForm, signUpForm, fromLogin, loginForm} = this.state;
        const requestBody = {
            email: fromLogin ? loginForm.email : signUpForm.email,
            code: verifyForm.code
        };
        this.setState({verifyLoading: true});
        const response = await UserApi.verifyEmail(requestBody);
        this.setState({verifyLoading: false});

        if (response.code === "SUCCESS003") {
            this.props.notify(false, response.message);
            this.onSignIn()
        } else {
            let errorData = response.data;
            if (!Helper.isEmpty(errorData)) {

                let serverValidationError = Helper.errorObjectFormater(errorData);
                if (!Helper.isEmpty(serverValidationError)) {
                    let errorObject = {
                        serverError: true,
                        success: false,
                        validationErrors: serverValidationError,
                        serverErrorMessage: errorData.message
                    };
                    this.showErrorMessage(errorObject)
                }

            }
        }
    };

    goHome = () => {
        this.props.history.push('/');
        window.location.reload(true)
    };

    signIn = async () => {
        this.setState({showErrors: true});
        this.validateState();
        if (!Helper.isEmpty(this.state.validationErrors)) return null;
        const {email, password} = this.state.loginForm;
        const requestBody = {
            email,
            password
        };
        this.setState({loginLoading: true});
        const response = await UserApi.login(requestBody);
        this.setState({loginLoading: false});

        if (response.code === "SUCCESS000") {
            localStorage.setItem('accessToken', response.data.token);
            localStorage.setItem('identifier', response.data.name);
            localStorage.setItem('id', response.data.id);
            localStorage.setItem('email', email);
            localStorage.setItem('unreadNotificationsCount', response.data.unreadNotificationsCount);
            this.props.history.push('/');
            window.location.reload(true)
        } else {
            let errorData = response.data;

            if (!Helper.isEmpty(errorData)) {
                if (errorData.code === "USER008") {
                    this.setState({
                        showVerifyForm: true,
                        activeForm: 'verify',
                        verifyForm: {
                            email: email,
                            code: ""
                        },
                        serverError: false,
                        fromLogin: true,
                    }, () => {
                        this.clearData()
                        this.container.current.classList.add("right-panel-active")
                    })
                } else {
                    let serverValidationError = Helper.errorObjectFormater(errorData);
                    if (!Helper.isEmpty(serverValidationError)) {
                        let errorObject = {
                            serverError: true,
                            validationErrors: serverValidationError,
                            serverErrorMessage: errorData.message
                        };
                        this.showErrorMessage(errorObject)
                    }
                }
            }
        }
    };

    requestCode = async () => {
        let {verifyForm} = this.state;
        let requestBody = {
            email: verifyForm.email,
            category: 1
        };
        const response = await UserApi.requestVerificationCode(requestBody);
        if (response.code === "SUCCESS000") {
            this.props.notify(false, response.message)
        } else {
            this.props.notify(true, response.message)
        }
    };

    showResetForm = () => {
        this.setState({
            showReset: true,
            activeForm: 'reset'
        }, () => {
            this.props.history.push('/reset-password')
            this.clearData()
        })
    };

    sendCode = async () => {
        this.setState({showErrors: true});
        this.validateState();
        if (!Helper.isEmpty(this.state.validationErrors)) return null;
        let {resetForm} = this.state;
        let body = {
            email: resetForm.email,
            category: 2
        };

        this.setState({sendCodeLoading: true});
        const response = await UserApi.requestVerificationCode(body);
        this.setState({sendCodeLoading: false});
        if (response.code === "SUCCESS001") {
            this.setState({
                showResetCode: true
            }, () => {
                this.resetFieldValidations.push(
                    ruleRunner("code", 'code', required),
                    ruleRunner("newPassword", 'newPassword', required),
                    ruleRunner("repeatPassword", 'repeatPassword', required, match('newPassword', 'repeatPassword')),
                );
                this.props.notify(false, response.message);
                this.validateState()
            })
        } else {
            let errorData = response.data;
            if (!Helper.isEmpty(errorData)) {

                let serverValidationError = Helper.errorObjectFormater(errorData);
                if (!Helper.isEmpty(serverValidationError)) {
                    let errorObject = {
                        serverError: true,
                        success: false,
                        validationErrors: serverValidationError,
                        serverErrorMessage: errorData.message
                    };
                    this.showErrorMessage(errorObject)
                }

            }
        }
    };

    resetPassword = async () => {
        this.validateState();
        this.setState({showErrors: true});
        if (!Helper.isEmpty(this.state.validationErrors)) return null;
        let {resetForm} = this.state;
        let body = {
            email: resetForm.email,
            code: resetForm.code,
            newPassword: resetForm.newPassword
        };

        this.setState({resetLoading: true});
        const response = await UserApi.resetPassword(body);
        this.setState({resetLoading: false});
        if (response.code === "SUCCESS004") {
            this.props.notify(false, response.message);
            this.onSignIn()
        } else {
            let errorData = response.data;
            if (!Helper.isEmpty(errorData)) {

                let serverValidationError = Helper.errorObjectFormater(errorData);
                if (!Helper.isEmpty(serverValidationError)) {
                    let errorObject = {
                        serverError: true,
                        success: false,
                        validationErrors: serverValidationError,
                        serverErrorMessage: errorData.message
                    };
                    this.showErrorMessage(errorObject)
                }
            }
        }
    };

    show = (showPassword) => {
        let showPasswordValue = this.state[showPassword];
        this.setState({
            [showPassword]: !showPasswordValue
        })
    };

    render() {
        let {
            loginForm, signUpForm, signUpLoading, loginLoading, serverError,
            serverErrorMessage, sendCodeLoading, resetLoading,
            success, successMessage, showVerifyForm, verifyForm, verifyLoading,
            showLoginPassword, showNewPassword, showRepeatPassword, showResetNewPassword, showResetRepeatPassword,
            showReset, resetForm, showResetCode, signUpActive
        } = this.state;
        const loginPasswordError = this.context.errorFor(this.state, 'password', null, true);
        const emailError = this.context.errorFor(this.state, 'email', null, true);
        const signUpPasswordError = this.context.errorFor(this.state, 'password', null, true);
        const newPasswordError = this.context.errorFor(this.state, 'newPassword', null, true);
        const repeatPasswordError = this.context.errorFor(this.state, 'repeatPassword', null, true);
        const codeError = this.context.errorFor(this.state, 'code', null, true);

        return (
            <div className={"login-container"}>
                <div className="container" id="container" ref={this.container}>
                    <div className="form-container sign-up-container">
                        {
                            showVerifyForm ?
                                <Form onSubmit={this.verifyAccount}>
                                    <h1>Verify Account</h1>
                                    <Form.Field>
                                        <Input type="text" placeholder="Email"
                                               disabled
                                               value={verifyForm.email}
                                               onChange={this.handleFieldChanged("verifyForm", "email")}
                                        />
                                    </Form.Field>

                                    <Form.Field
                                        required
                                        error={!!codeError}>
                                        <Input type="text" placeholder="code"
                                               value={verifyForm.code}
                                               onChange={this.handleFieldChanged("verifyForm", "code")}
                                        />
                                        {codeError}
                                    </Form.Field>
                                    <Button loading={verifyLoading}>Verify</Button>
                                    <span onClick={() => this.requestCode()}>
                                        <Icon name={'refresh'} color={'grey'}/>
                                        Resend code
                                    </span>
                                    {
                                        serverError && serverErrorMessage ?
                                            <Message
                                                visible
                                                error
                                                header="Error"
                                                content={serverErrorMessage}
                                                style={{width: "100%", minHeight: 'auto'}}
                                            />
                                            : null
                                    }
                                </Form>
                                :
                                <Form onSubmit={this.signUp}>
                                    <h1>Create Account</h1>
                                    <Form.Field
                                        required
                                        error={!!emailError}>
                                        <Input type="email" placeholder="Email"
                                               value={signUpForm.email}
                                               onChange={this.handleFieldChanged("signUpForm", "email")}
                                        />
                                        {emailError}
                                    </Form.Field>
                                    <Form.Field
                                        required
                                        error={!!signUpPasswordError}
                                        className={'password-with-tip'}>
                                        {signUpForm.password && (
                                            <PasswordStrengthIndicator
                                                validity={this.state.passwordValidity}
                                            />
                                        )}
                                        <div className={'flex-section'}>
                                            <Input type={showNewPassword ? 'text' : 'password'}
                                                   placeholder="Password"
                                                   value={signUpForm.password}
                                                   onChange={this.handleFieldChanged("signUpForm", "password")}
                                            />
                                            <Icon name={showNewPassword ? 'eye' : 'eye slash outline'}
                                                  onClick={() => this.show('showNewPassword')}/>

                                            <Popup
                                                trigger={<Icon circular name='info' size={'small'}/>}
                                                content='The password must be between 8 and 32 characters and contain 1 upper case letter, 1 small case l latter, 1 number and 1 special character at least! (Special characters are !@#%^&*-)!'
                                            />
                                        </div>
                                        {signUpPasswordError}
                                    </Form.Field>
                                    <Form.Field
                                        required
                                        error={!!repeatPasswordError}>
                                        <Input type={showRepeatPassword ? 'text' : 'password'}
                                               placeholder="Repeat password"
                                               value={signUpForm.repeatPassword}
                                               onChange={this.handleFieldChanged("signUpForm", "repeatPassword")}/>
                                        <Icon name={showRepeatPassword ? 'eye' : 'eye slash outline'}
                                              onClick={() => this.show('showRepeatPassword')}/>
                                        {repeatPasswordError}
                                    </Form.Field>
                                    <Button loading={signUpLoading}>Sign Up</Button>
                                    {
                                        serverError && serverErrorMessage ?
                                            <Message
                                                visible
                                                error
                                                header="Error"
                                                content={serverErrorMessage}
                                                style={{width: "100%", minHeight: 'auto'}}
                                            />
                                            : null
                                    }
                                    {
                                        success && successMessage ?
                                            <Message
                                                visible
                                                success
                                                header="Success"
                                                content={successMessage}
                                                style={{width: "100%", minHeight: 'auto'}}
                                            />
                                            : null
                                    }
                                </Form>
                        }

                    </div>
                    <div className="form-container sign-in-container">
                        <Icon name={'home'} size={'large'} onClick={() => this.goHome()}/>
                        {
                            showReset ?
                                <Form onSubmit={showResetCode ? this.resetPassword : this.sendCode}>
                                    <h1>Reset password</h1>
                                    <Form.Field
                                        required
                                        error={!!emailError}>
                                        <Input type="text" placeholder="Email"
                                               value={resetForm.email}
                                               onChange={this.handleFieldChanged("resetForm", "email")}
                                        />
                                        {emailError}
                                    </Form.Field>
                                    {
                                        showResetCode ?
                                            <>
                                                <Form.Field
                                                    required
                                                    error={!!codeError}>
                                                    <Input type="text" placeholder="code"
                                                           value={resetForm.code}
                                                           onChange={this.handleFieldChanged("resetForm", "code")}
                                                    />
                                                    {codeError}
                                                </Form.Field>
                                                <Form.Field
                                                    required
                                                    error={!!newPasswordError}
                                                    className={'password-with-tip'}>
                                                    {resetForm.newPassword && (
                                                        <PasswordStrengthIndicator
                                                            validity={this.state.passwordValidity}
                                                        />
                                                    )}
                                                    <div className={'flex-section'}>
                                                        <Input type={showResetNewPassword ? 'text' : 'password'}
                                                               placeholder="New password"
                                                               value={resetForm.newPassword}
                                                               onChange={this.handleFieldChanged("resetForm", "newPassword")}
                                                        />
                                                        <Icon name={showResetNewPassword ? 'eye' : 'eye slash outline'}
                                                              onClick={() => this.show('showResetNewPassword')}/>
                                                        <Popup
                                                            trigger={<Icon circular name='info' size={'small'}/>}
                                                            content='The password must be between 8 and 32 characters and contain 1 upper case letter, 1 small case l latter, 1 number and 1 special character at least! (Special characters are !@#%^&*-)!'
                                                        />
                                                    </div>
                                                    {newPasswordError}
                                                </Form.Field>
                                                <Form.Field
                                                    required
                                                    error={!!repeatPasswordError}>
                                                    <Input type={showResetRepeatPassword ? 'text' : 'password'}
                                                           placeholder="Repeat password"
                                                           value={resetForm.repeatPassword}
                                                           onChange={this.handleFieldChanged("resetForm", "repeatPassword")}/>
                                                    <Icon name={showResetRepeatPassword ? 'eye' : 'eye slash outline'}
                                                          onClick={() => this.show('showResetRepeatPassword')}/>
                                                    {repeatPasswordError}
                                                </Form.Field>
                                            </>
                                            : null
                                    }
                                    {
                                        serverError && serverErrorMessage ?
                                            <Message
                                                visible
                                                error
                                                header="Error"
                                                content={serverErrorMessage}
                                                style={{width: "100%", minHeight: 'auto'}}
                                            />
                                            : null
                                    }
                                    <Button
                                        loading={showReset ? sendCodeLoading : showResetCode ? resetLoading : loginLoading}>
                                        {showResetCode ? 'Reset password' : showReset ? 'Send code' : 'Sign In'}</Button>
                                </Form>
                                :
                                <Form onSubmit={this.signIn}>
                                    <h1>Sign in</h1>
                                    <Form.Field
                                        required
                                        error={!!emailError}>
                                        <Input placeholder="Email"
                                               value={loginForm.email}
                                               onChange={this.handleFieldChanged("loginForm", "email")}/>
                                        {emailError}
                                    </Form.Field>
                                    <Form.Field
                                        required
                                        error={!!loginPasswordError}>
                                        <Input type={showLoginPassword ? 'text' : 'password'} placeholder="Password"
                                               value={loginForm.password}
                                               onChange={this.handleFieldChanged("loginForm", "password")}/>
                                        <Icon name={showLoginPassword ? 'eye' : 'eye slash outline'}
                                              onClick={() => this.show('showLoginPassword')}/>
                                        {loginPasswordError}
                                    </Form.Field>
                                    <Button loading={loginLoading}>Sign In</Button>
                                    <span onClick={() => this.showResetForm()}>
                                        Reset password
                                    </span>
                                    {
                                        serverError ?
                                            <Message
                                                visible
                                                error
                                                header="Error"
                                                content={serverErrorMessage}
                                                style={{width: "100%", minHeight: 'auto'}}
                                            />
                                            : null
                                    }
                                </Form>
                        }

                    </div>
                    <div className={`overlay-container ${signUpActive ? 'signUp' : 'signIn'}`}>
                        <div className="overlay">
                            <div className="overlay-panel overlay-left">
                                <h1>Welcome Back!</h1>
                                <p>To keep connected with us please login with your personal info</p>
                                <Button className="ghost" id="signIn" onClick={this.onSignIn}>Sign In</Button>
                            </div>
                            <div className="overlay-panel overlay-right">
                                <h1>Hello, Friend!</h1>
                                <p>Enter your personal details and start journey with us</p>
                                <Button className="ghost" id="signUp" onClick={this.onSignUp}>Sign Up</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default LoginForm