import React, {Component} from "react"
import MainLayout from './components/main-layout'
import LandingPage from './components/landing-page/landing-page'
import About from './components/static-components/about'
import Process from './components/static-components/process'
import FAQs from './components/static-components/faqs'
import Disclaimer from './components/static-components/disclaimer'
import AdviseTabs from './components/static-components/adivise-tabs'
import LoginForm from "./components/login/src/login"
import Logout from "./components/login/src/logout"
import Trips from "./components/home/src/trips"
import AddTrip from "./components/home/src/add-trip/add-trip"
import NotFound from "./components/not-found/not-fount"
import Requests from "./components/requests/src/requests"
import ProfileTabs from "./components/profile/src/profile-tabs"
import AddRequestTabs from "./components/requests/src/add-request-tabs"
import ItemDetails from "./components/table/item-details"
import Tickets from "./components/ticket/src/tickets"
import AddTicket from "./components/ticket/src/add-ticket"
import TicketDetails from "./components/ticket/src/ticket-details"
import Notifications from "./components/notification/src/notifications"
import HealthChecker from "./components/health-cheacker/health-checker"
import {Label} from "semantic-ui-react";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import AppContext from './context/app-context';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import Helper from "./components/utils/helper"
import './css/datepicker-react.css';
import {css} from 'glamor';
import {toast, ToastContainer} from 'react-toastify';
import {Icon} from 'semantic-ui-react'


export default class App extends Component {
    constructor(props) {
        super(props)
        this.pathName = window.location.pathname
        this.state = {
            errorFor: this.errorFor,
            user: localStorage.getItem('accessToken'),
            hideLayout: this.pathName === '/sign-in' ||
                this.pathName === '/sign-up' ||  this.pathName === '/reset-password'
        }
    }

    notify = (error, message, duration = 5000) =>
        (message) ? toast(
            error ?
                <div><Icon size="large" name='warning'/> {message}</div>
                : <div><Icon link name='check'/> {message}</div>,
            {
                autoClose: duration,
                className: css({
                    padding: '10px',
                    color: error ? '#912d2b !important' : 'teal !important',
                }),
                progressClassName: css({
                    background: error ? '#912d2b !important' : 'teal !important',
                }),
                // This position to determine where should the toast appear . (default top right)
                position: toast.POSITION.TOP_RIGHT,
            }) : null;

    errorFor = (state, field, component, direction) => {
        let {isMobile, screenSize} = this.state;
        if (component === 'login' || component === 'register') {
            isMobile = screenSize <= 990;
        }

        let hide = state.validationErrors[field]? state.validationErrors[field].includes('required'): false;
        if (state.validationErrors[field] && state.showErrors) {
            return <div className={`error-section ${state.serverError && !hide ? '' : 'invisible'}`}>
                <Label
                    basic color='red' pointing={direction || isMobile ? `above` : 'right'}>
                    {state.validationErrors[field]}
                </Label>
            </div>
        }
        return null
    }

    render() {
        let {hideLayout} = this.state
        return (
            <BrowserRouter>
                <AppContext.Provider value={this.state}>
                    {
                        hideLayout ?
                            <>
                                <Switch>
                                    <Route exact path={'/sign-in'}
                                           render={(props) => <LoginForm notify={this.notify} {...props}/>}/>

                                    <Route exact path={'/sign-up'}
                                           render={(props) => <LoginForm notify={this.notify} {...props}/>}/>

                                    <Route exact path={'/reset-password'}
                                           render={(props) => <LoginForm notify={this.notify} {...props}/>}/>

                                    {/*<Route exact path={'/status'}
                                           render={(props) => <HealthChecker notify={this.notify} {...props}/>}/>*/}

                                </Switch>
                                <ToastContainer autoClose={5000}/>
                            </>
                            :
                            <MainLayout props={this}>
                                <Switch>
                                   {/* <Route exact path={'/status'}
                                           render={(props) => <HealthChecker notify={this.notify} {...props}/>}/>*/}
                                    <Route exact path='/requests/view/:id'
                                           render={(props) => {
                                               return <ItemDetails notify={this.notify} fromUrl={true} name={'request'} {...props}/>
                                           }}/>

                                    <Route exact path='/trips/view/:id'
                                           render={(props) =>
                                               <ItemDetails notify={this.notify} fromUrl={true} name={'trip'} {...props}/>
                                           }
                                    />
                                    <Route exact path={'/trips/add'}
                                           render={(props) => <AddTrip notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/trips/:pageNumber'}
                                           render={(props) => <Trips notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/trips/update/:id'}
                                           render={(props) => <AddTrip notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/sign-in'} render={(props) => {
                                        return null
                                    }}/>
                                    <Route exact path={'/sign-up'} render={(props) => {
                                        return null
                                    }}/>
                                    <Route exact path={'/reset-password'} render={(props) => {
                                        return null
                                    }}/>
                                    {/* {window.history.pushState('', 'Home', '/')}
                                </Route>*/}
                                    <Route exact path={'/requests/add'} render={(props) => {
                                        if (Helper.emptyString(localStorage.getItem('accessToken'))) {
                                            return <AddRequestTabs notify={this.notify} {...props}/>
                                        } else {
                                            return <AddRequestTabs notify={this.notify} {...props}/>
                                        }
                                    }}/>
                                    <Route exact path={'/requests/:pageNumber'}
                                           render={(props) => <Requests notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/requests/update/:id'}
                                           render={(props) => <AddRequestTabs notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/notifications/:pageNumber'}
                                           render={(props) => <Notifications notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/profile'}
                                           render={(props) => <ProfileTabs notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/logout'}
                                           render={(props) => <Logout notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/'}
                                           render={(props) => <LandingPage notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/about'}
                                           render={(props) => <About notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/process'}
                                           render={(props) => <Process notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/faqs'}
                                           render={(props) => <FAQs notify={this.notify} {...props}/>}/>

                                    <Route exact path={'/disclaimer'}
                                           render={(props) => <Disclaimer notify={this.notify} {...props}/>}/>

                                    <Route exact path={'/advises'}
                                           render={(props) => <AdviseTabs notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/support/add'}
                                           render={(props) => <AddTicket notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/support/:pageNumber'}
                                           render={(props) => <Tickets notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/support/view/:id'}
                                           render={(props) => <TicketDetails notify={this.notify} {...props}/>}/>
                                    <Route exact path={'/status'}
                                           render={(props) => <HealthChecker notify={this.notify} {...props}/>}/>
                                    <Route exact render={(props) => <NotFound {...props}/>}/>
                                </Switch>
                                <ToastContainer autoClose={5000}/>
                            </MainLayout>
                    }
                </AppContext.Provider>
            </BrowserRouter>
        )
        //}
    }

}

