import {Modal, Label, Grid, List, Segment, Button, Input, Header, Form, Divider, Icon} from "semantic-ui-react";
import TripApi from "../../components/home/api/trip-api"
import RequestApi from "../../components/requests/api/request-api"
import React, {Component} from "react";
import Helper from "../utils/helper";
import {
    deliverTypes,
    transportationTypes,
    weightUnits,
    dimensionUnit,
    paymentMethods,
    tripStatuses,
    requestStatuses,
    disabilitiesTypes,
    serviceTypes
} from "../utils/static-data";
import update from "immutability-helper";
import CustomTable from "./custom-table"
import AppContext from "../../context/app-context";
import {ruleRunner, run} from "../utils/ruleRunner";
import {required} from "../utils/rules";
import GetRequestAvailableTrips from '../requests/src/get-reuest-available-trips'
import PhotoModal from './photo-modal';
import QuoteModal from './quote-modal'
import TimezonePicker from 'react-timezone';
import {timezones} from 'react-timezone';
import DatePicker from "react-datepicker";
import moment from "moment";


export default class ItemDetails extends Component {
    static contextType = AppContext;
    fieldValidations = [
        ruleRunner("quote", 'quote', required),
        ruleRunner("deliveryDate", 'deliveryDate', required),
    ];

    constructor(props) {
        super(props);

        this.requestApi = new RequestApi(this);
        this.tripApi = new TripApi(this);
        this.quoteModalRef = React.createRef();
        let selectedTimezone = timezones.find(a => a.name === 'UTC');
        let initialTimezone = 'UTC';
        let timezone = selectedTimezone.offset;
        this.state = {
            fromDetails: true,
            validationErrors: {},
            showErrors: false,
            open: false,
            name: '',
            quoteForm: {
                quote: null,
                deliveryDate: "",
                time: "00:00:00",
                timezone: timezone,
                deliveryDateTimezone: initialTimezone,
            },
            status: null,
            tripData: [
                {
                    segmentTitle: "Service provided",
                    index: 1,
                    fields: [
                        {
                            fieldName: 'Delivery type',
                            fieldKey: 'deliverableType',
                            fromList: deliverTypes,
                        },
                        {
                            fieldName: 'Service type',
                            fieldKey: 'serviceType',
                            fromList: serviceTypes,
                        },
                    ],
                },
                {
                    segmentTitle: "Travel details",
                    index: 3,
                    fields: [{
                        fieldName: 'From',
                        fieldKey: 'from',
                        fromList: null,
                    },
                        {
                            fieldName: 'To',
                            fieldKey: 'to',
                            fromList: null,
                        },
                        {
                            fieldName: 'Transportation',
                            fieldKey: 'transportation',
                            fromList: transportationTypes,
                        },
                        {
                            fieldName: 'Date',
                            fieldKey: 'date',
                            fromList: null,
                        }
                    ]
                },
                {
                    segmentTitle: "Carriage details",
                    index: 2,
                    fields: []
                },
            ],
            requestData: [
                {
                    segmentTitle: "Service provided",
                    index: 1,
                    fields: [
                        {
                            fieldName: 'Delivery type',
                            fieldKey: 'deliverableType',
                            fromList: deliverTypes,
                        },
                        {
                            fieldName: 'Service type',
                            fieldKey: 'serviceType',
                            fromList: serviceTypes,
                        },
                    ]
                },
                {
                    segmentTitle: "Deliverable details",
                    index: 3,
                    fields: []
                },
                {
                    segmentTitle: "Delivery details",
                    index: 2,
                    fields: [{
                        fieldName: 'From',
                        fieldKey: 'from',
                        fromList: null,
                    },
                        {
                            fieldName: 'To',
                            fieldKey: 'to',
                            fromList: null,
                        },
                        {
                            fieldName: 'Pick up address',
                            fieldKey: 'pickUpAddress',
                            fromList: null,
                        },
                        {
                            fieldName: 'Deliver to address',
                            fieldKey: 'deliverToAddress',
                            fromList: null,
                        },
                        {
                            fieldName: 'Delivered before date',
                            fieldKey: 'deliveredBeforeDate',
                            fromList: null,
                        },
                        {
                            fieldName: 'Can be collected from any location in the city',
                            fieldKey: 'collectedFromAnyLocation',
                            fromList: null,
                            customStyle: true
                        },
                    ]
                },
                {
                    segmentTitle: "Payment",
                    index: 1,
                    fields: [{
                        fieldName: 'Payment method',
                        fieldKey: 'paymentMethod',
                        fromList: paymentMethods,
                    },
                        {
                            fieldName: 'Estimated delivery charge',
                            fieldKey: 'estimatedDeliveryCharge',
                            fromList: null,
                            additionValue: '$'
                        },
                    ]
                }
            ],
            refreshKey: Helper.unique()
        };
        this.photoModalRef = React.createRef();
        if (props.fromUrl) {
            this.show(props.match.params.id, props.name)
        }
    }

    show = async (id, name, notification = {}) => {
        let {tripData, requestData} = this.state
        let model = name === 'request' ? this.requestApi : this.tripApi;
        let action, requestId, tripId, quoteId = null;
        if (!Helper.isEmpty(notification)) {
            action = notification.action;
            requestId = notification.requestId;
            tripId = notification.tripId;
            quoteId = notification.quoteId
        }
        const response = await model.getOne({id});
        if (response.code === "SUCCESS000") {
            let status = response.data.status;
            let quoteObject;
            if (!Helper.isEmpty(notification)) {
                if (notification.action === 1 && notification.type === 2) {
                    quoteObject = response.data.quotes ? response.data.quotes.find(a => a.id === quoteId) : {}
                } else if (notification.action === 2 || notification.action === 4) {
                    quoteObject = response.data.requests ? response.data.requests.find(a => a.requestId === requestId) : {}
                }
            }

            if (name === 'request') {
                if (response.data.serviceType === 3 || response.data.serviceType === 4) {
                    if (response.data.serviceType === 3) {
                        requestData[1].fields = [
                            {
                                fieldName: 'Age',
                                fieldKey: 'age',
                                fromList: null,
                            },
                            {
                                fieldName: 'Nationality',
                                fieldKey: 'nationality',
                                fromList: null,
                            },
                            {
                                fieldName: 'Languages',
                                fieldKey: 'languages',
                                fromList: null,
                            },
                            {
                                fieldName: 'Medical status',
                                fieldKey: 'medicalStatus',
                                fromList: null,
                            },
                            {
                                fieldName: 'Disabilities',
                                fieldKey: 'disabilities',
                                fromList: disabilitiesTypes,
                            },
                            {
                                fieldName: 'Afraid of flying/travelling',
                                fieldKey: 'travelingAloneFear',
                                fromList: null,
                            },
                            {
                                fieldName: 'Diet',
                                fieldKey: 'diet',
                                fromList: null,
                            },
                            {
                                fieldName: 'Needs',
                                fieldKey: 'needs',
                                fromList: null,
                            },
                            {
                                fieldName: 'Airport(From)',
                                fieldKey: 'airportFrom',
                                fromList: null,
                            },
                            {
                                fieldName: 'Airport(To)',
                                fieldKey: 'airportTo',
                                fromList: null,
                            },
                            {
                                fieldName: 'Flight number',
                                fieldKey: 'flightNumber',
                                fromList: null,
                            },
                        ];
                    } else if (response.data.serviceType === 4) {
                        requestData[1].fields = [
                            {
                                fieldName: 'Species',
                                fieldKey: 'species',
                                fromList: null,
                            },
                            {
                                fieldName: 'Medical certificate',
                                fieldKey: 'medicalCertificate',
                                fromList: null,
                            },
                            {
                                fieldName: 'Transport certificate',
                                fieldKey: 'transportCertificate',
                                fromList: null,
                            },
                            {
                                fieldName: 'Additional accessories',
                                fieldKey: 'additionalAccessories',
                                fromList: null,
                            },
                            {
                                fieldName: 'Food',
                                fieldKey: 'food',
                                fromList: null,
                            },
                        ];
                    }
                } else {

                    switch (response.data.deliverableType) {
                        case 2:
                            requestData[1].fields = [{
                                fieldName: 'Quantity',
                                fieldKey: 'quantity',
                                fromList: null,
                            }];
                            break;
                        case 1:
                            requestData[1].fields = [
                                {
                                    fieldName: 'Name',
                                    fieldKey: 'name',
                                    fromList: null,
                                },
                                {
                                    fieldName: 'Length',
                                    fieldKey: 'length',
                                    fromList: null,
                                },
                                {
                                    fieldName: 'Width',
                                    fieldKey: 'width',
                                    fromList: null,
                                },
                                {
                                    fieldName: 'Height',
                                    fieldKey: 'height',
                                    fromList: null,
                                },
                                {
                                    fieldName: 'Weight',
                                    fieldKey: 'weight',
                                    fromList: null,
                                },
                                {
                                    fieldName: 'Description',
                                    fieldKey: 'description',
                                    fromList: null,
                                },
                                {
                                    fieldName: 'Photo',
                                    fieldKey: 'photoUrl',
                                    fromList: null,
                                },
                                {
                                    fieldName: 'Url',
                                    fieldKey: 'url',
                                    fromList: null,
                                },
                                {
                                    fieldName: 'Quantity',
                                    fieldKey: 'quantity',
                                    fromList: null,
                                },
                            ];
                            break;
                    }
                }

                /* if (response.data.status !== 0) {
                     if (!requestData[0].fields.find(a => a.fieldKey === 'status')) {
                         requestData[0].fields.splice(0, 0, {
                             fieldName: 'Status',
                             fieldKey: 'status',
                             fromList: null,
                         });
                     }
                 }
                 if (response.data.tripStatus !== 0) {
                     if (!requestData[0].fields.find(a => a.fieldKey === 'tripStatus')) {
                         requestData[0].fields.splice(1, 0, {
                             fieldName: 'Trip status',
                             fieldKey: 'tripStatus',
                             fromList: null,
                         });
                     }
                 }*/

            } else if (name === 'trip') {
                switch (response.data.serviceType) {
                    case 3:
                        tripData[1].fields.push(
                            {
                                fieldName: 'Airport(From)',
                                fieldKey: 'airportFrom',
                                fromList: null,
                            },
                            {
                                fieldName: 'Airport(To)',
                                fieldKey: 'airportTo',
                                fromList: null,
                            },
                            {
                                fieldName: 'Flight number',
                                fieldKey: 'flightNumber',
                                fromList: null,
                            },
                        )
                        tripData[2].segmentTitle = "Service details";
                        tripData[2].fields = [
                            {
                                fieldName: 'Price',
                                fieldKey: 'price',
                                fromList: null,
                                additionValue: '$'
                            },
                            {
                                fieldName: 'Accept kids escorting',
                                fieldKey: 'kidsAccepted',
                                fromList: null,
                            },
                            {
                                fieldName: 'Accept elderly escorting',
                                fieldKey: 'elderlyAccepted',
                                fromList: null,
                            },
                            {
                                fieldName: 'Can be collected from any location in the city',
                                fieldKey: 'collectedFromAnyLocation',
                                fromList: null,
                                customStyle: true
                            },
                            {
                                fieldName: 'Can be delivered to any location in the destination city',
                                fieldKey: 'deliveredToAnyLocation',
                                fromList: null,
                                customStyle: true
                            },
                        ]
                        break;
                    case 4:
                        tripData[2].segmentTitle = "Service details";
                        tripData[2].fields = [
                            {
                                fieldName: 'Price',
                                fieldKey: 'price',
                                fromList: null,
                                additionValue: '$'
                            },
                            {
                                fieldName: 'Can be collected from any location in the city',
                                fieldKey: 'collectedFromAnyLocation',
                                fromList: null,
                                customStyle: true
                            },
                            {
                                fieldName: 'Can be delivered to any location in the destination city',
                                fieldKey: 'deliveredToAnyLocation',
                                fromList: null,
                                customStyle: true
                            },
                        ]
                        break;
                    default:
                        if(response.data.deliverableType === 2){
                            tripData[2].fields = [
                                {
                                    fieldName: 'Weight',
                                    fieldKey: 'weight',
                                    fromList: null,
                                },
                                {
                                    fieldName: 'Document price',
                                    fieldKey: 'documentPrice',
                                    fromList: null,
                                    additionValue: '$'
                                },
                                {
                                    fieldName: 'Can be collected from any location in the city',
                                    fieldKey: 'collectedFromAnyLocation',
                                    fromList: null,
                                    customStyle: true
                                },
                                {
                                    fieldName: 'Can be delivered to any location in the destination city',
                                    fieldKey: 'deliveredToAnyLocation',
                                    fromList: null,
                                    customStyle: true
                                },
                            ]
                        } else if(response.data.deliverableType === 1){
                            tripData[2].fields = [
                                {
                                    fieldName: 'Weight',
                                    fieldKey: 'weight',
                                    fromList: null,
                                },
                                {
                                    fieldName: 'Volumetric price',
                                    fieldKey: 'volumetricPrice',
                                    fromList: null,
                                    additionValue: '$'
                                },
                                {
                                    fieldName: 'Can be collected from any location in the city',
                                    fieldKey: 'collectedFromAnyLocation',
                                    fromList: null,
                                    customStyle: true
                                },
                                {
                                    fieldName: 'Can be delivered to any location in the destination city',
                                    fieldKey: 'deliveredToAnyLocation',
                                    fromList: null,
                                    customStyle: true
                                },
                            ]
                        }

                }
            } else {
                if (response.data.status !== 0) {
                    /*if (!tripData[0].fields.find(a => a.fieldKey === 'status')) {
                        tripData[0].fields.splice(0, 0, {
                            fieldName: 'Status',
                            fieldKey: 'status',
                            fromList: null,
                        });
                    }*/
                }
                if (response.data.deliverableType === 2) {
                    /* if (tripData[2].fields.find(a => a.fieldKey === 'volumetricPrice')) {
                         tripData[2].fields.splice(1, 1);
                     }*/
                    if (!tripData[2].fields.find(a => a.fieldKey === 'documentPrice')) {
                        tripData[2].fields.splice(1, 1, {
                            fieldName: 'Document price',
                            fieldKey: 'documentPrice',
                            fromList: null,
                            additionValue: '$'
                        });
                    }

                }
                if (response.data.deliverableType === 1) {
                    if (!tripData[2].fields.find(a => a.fieldKey === 'volumetricPrice')) {
                        tripData[2].fields.splice(1, 1, {
                            fieldName: 'Volumetric price',
                            fieldKey: 'volumetricPrice',
                            fromList: null,
                            additionValue: '$'
                        });
                    }
                }
                if (response.data.serviceType === 3 || response.data.serviceType === 4) {
                    if (!tripData[2].fields.find(a => a.fieldKey === 'servicePrice')) {
                        tripData[2].fields.splice(1, 1, {
                            fieldName: 'Service price',
                            fieldKey: 'servicePrice',
                            fromList: null,
                            additionValue: '$'
                        });
                    }
                }
            }
            let itemData = null
            //if (name === 'request') {
                itemData = response.data.deliverableInfo;
                //delete response.data.deliverableInfo;
                itemData = {...itemData, ...response.data};
           /* } else {
                itemData = response.data
            }*/

            this.setState({
                open: true,
                name: name,
                [name]: itemData,
                quoteObject: quoteObject,
                requestData,
                action: action,
                notification: notification,
                tripId: tripId,
                requestId: requestId,
                tripData,
                refreshKey: Helper.unique(),
                status: status
            })
        }
        if (notification) {
            if (notification.type === 1 && notification.action === 1) {
                this.validateState()
            }
        }
    };

    showPhotoModal = (value) => {
        this.photoModalRef.current.showModal(value);
    };

    calculateValue = (field, item) => {
        let value = '';
        switch (field.fieldKey) {
            case 'name':
                value = item.name ? item.name : 'Else';
                break;
            case 'deliveredBeforeDate':
                value = item.deliveredBeforeDate ?
                    item.deliveredBeforeDate.replace('Z', ' ').replace('T', ' ') : '';
                break;
            case 'date':
                value = item.date ?
                    item.date.replace('Z', ' ').replace('T', ' ') : '';
                break;
            case 'weight':
                let unit = weightUnits.find(a => a.value === item.weightUnit) ? weightUnits.find(a => a.value === item.weightUnit).text : '';
                value = `${item.weight} ${unit}`;
                break;
            case 'length':
            case 'width':
            case 'height':
                let dimenUnit = dimensionUnit.find(a => a.value === item.itemDimensionUnit) ?
                    dimensionUnit.find(a => a.value === item.itemDimensionUnit).text : '';
                value = `${item[field.fieldKey]} ${dimenUnit}`;
                break;
            case 'itemWeight':
                let weightUnit = weightUnits.find(a => a.value === item.itemWeightUnit) ?
                    weightUnits.find(a => a.value === item.itemWeightUnit).text : '';
                value = `${item.itemWeight} ${weightUnit}`;
                break;
            case 'collectedFromAnyLocation':
                value = item.collectedFromAnyLocation ? 'Yes' : 'No';
                break;
            case 'deliveredToAnyLocation':
                value = item.deliveredToAnyLocation ? 'Yes' : 'No';
                break;
            case 'from':
                value = `${item.from.countryName}, ${item.from.stateName}, ${item.from.cityName}`;
                break;
            case 'to':
                value = `${item.to.countryName}, ${item.to.stateName}, ${item.to.cityName}`;
                break;
            case 'status':
                if (this.state.name === 'trip') {
                    value = `${tripStatuses.find(a => a.value === item.status) ?
                        tripStatuses.find(a => a.value === item.status).text : ''}`;
                } else {
                    value = `${requestStatuses.find(a => a.value === item.status) ?
                        requestStatuses.find(a => a.value === item.status).text : ''}`;
                }
                break;
            case 'tripStatus':
                value = `${tripStatuses.find(a => a.value === item.tripStatus) ?
                    tripStatuses.find(a => a.value === item.tripStatus).text : ''}`;
                break;
            case 'disabilities':
                value = item.disabilities ?
                    item.disabilities.map((disability, index) => {
                        let comma = index !== item.disabilities.length - 1 ? ' ' : ''
                        return ` ${field.fromList ? `${field.fromList.find(a => a.value === disability).text}`
                            : item[field.fieldKey]}`
                    }) : ''
                break;
            case 'travelingAloneFear':
                value = item.travelingAloneFear ? 'Yes' : 'No';
                break;
            case 'nationality':
                value = item.nationality ? item.nationality.name : '';
                break;
            default :
                value = field.fromList && !Helper.emptyString(item[field.fieldKey]) ? field.fromList.find(a => a.value === item[field.fieldKey]).text
                    : item[field.fieldKey];
                break
        }

        return value
    };

    quoteOperation = async () => {
        let {action, notification, quoteForm} = this.state;
        this.setState({showErrors: true});
        if (!Helper.isEmpty(this.state.validationErrors)) return null;


        switch (action) {
            case 1:
                if (notification.type === 1) {
                    let deliveredBeforeDate = Helper.toIsoString(quoteForm.deliveryDate, quoteForm.timezone, false);
                    deliveredBeforeDate = `${deliveredBeforeDate.substring(0, 10)}T${quoteForm.time}${deliveredBeforeDate.substring(10)}`;
                    let requestBody = {
                        requestId: notification.requestId,
                        price: quoteForm.quote,
                        deliveryDate: deliveredBeforeDate,
                        deliveryDateTimezone: quoteForm.deliveryDateTimezone,
                        existingTrip: {
                            id: notification.tripId
                        }
                    }
                    const response = await this.requestApi.createQuote(requestBody);
                    if (response.code === "SUCCESS000") {
                        this.setState(
                            {open: false}
                            , () => {
                                this.props.props.props.props.notify(false, 'Operation completed successfully')
                            })
                    } else {
                        this.props.props.props.props.notify(true, response.data ? response.data.message : 'Error! please try again')
                    }
                } else if (notification.type === 2) {
                    const response = await this.requestApi.quoteOperations({
                        quoteId: notification.quoteId,
                        action: 1
                    });
                    if (response.code === "SUCCESS000") {

                        this.setState(
                            {open: false}
                            , () => {
                                this.props.props.props.props.notify(false, 'Operation completed successfully')
                            })
                    } else {
                        this.props.props.props.props.notify(true, response.data ? response.data.message : 'Error! please try again')
                    }
                }
                break;
            case 2:
            case 4:
                const response = await this.requestApi.quoteOperations({
                    quoteId: notification.quoteId,
                    action: 2
                });
                if (response.code === "SUCCESS000") {
                    this.setState(
                        {open: false}
                        , () => {
                            this.props.props.props.props.notify(false, 'Operation completed successfully')
                        })
                } else {
                    this.props.props.props.props.notify(true, response.data ? response.data.message : 'Error! please try again')
                }
                break;
            default:
                break
        }
    };

    validateState = () => {
        this.setState({validationErrors: run(this.state.quoteForm, this.fieldValidations)});
    };

    handleFieldChanged = (event, data) => {
        let value = data.value ? parseFloat(data.value) : 0;
        this.setState({
            quoteForm: update(this.state.quoteForm, {
                quote: {
                    $set: value
                }
            })
        }, this.validateState)
    };

    sendRequest = () => {
        let item = this.state[this.state.name];
        if (this.state.name === 'trip') {
            let history = this.props.history ? this.props.history : this.props.props.props.history ?
                this.props.props.props.history : this.props.props.props.props.history ?
                    this.props.props.props.props.history : this.props.props.props.props.props.history;

            history.push({
                pathname: '/requests/add',
                state: {trip: item}
            })

        } else if (this.state.name === 'request') {
            this.quoteModalRef.current.show(item.id);
        }
    }

    renderDetails = () => {
        let list = [];
        let item = this.state[this.state.name];
        let {name, status, notification} = this.state;
        let itemData = name === 'trip' ? this.state.tripData : this.state.requestData;

        if (item) {
            list.push(
                <>
                    <Grid.Column>
                        <Segment>
                            <Label>{itemData[0].segmentTitle}</Label>
                            <List className={"details-list"} vertical="true">
                                {
                                    itemData[0].fields.map((field, index) => {
                                        let value = this.calculateValue(field, item);

                                        return <List.Item
                                            key={index}
                                            className={`${field.customStyle ? 'custom-item' : ''} list-item`}>
                                            {
                                                Helper.emptyString(field.fieldName) ?
                                                    <h4
                                                        className={`text-value`}>{value}</h4>
                                                    :
                                                    <>
                                                        <h4>{field.fieldName}</h4>
                                                        {field.fieldKey === 'status' || field.fieldKey === 'tripStatus' ?

                                                            <Label
                                                                className={`${value ? Helper.statusColorMapping(value) : 'empty-value'} status-label`}>{`${value ? field.additionValue ? field.additionValue : '' : '(None)'}${!Helper.emptyString(value) ? value : ''}`}</Label>

                                                            :
                                                            <span
                                                                className={`${value ? '' : 'empty-value'}`}>{`${value ? field.additionValue ? field.additionValue : '' : '(None)'}${!Helper.emptyString(value) ? value : ''}`}</span>}

                                                    </>
                                            }

                                        </List.Item>
                                    })
                                }
                            </List>
                        </Segment>
                        <Segment>
                            <Label>{itemData[1].segmentTitle}</Label>
                            <List className={"details-list"} vertical="true">
                                {
                                    itemData[1].fields.map((field) => {
                                        let value = this.calculateValue(field, item);

                                        return <List.Item
                                            key={Helper.unique()}
                                            className={`${field.customStyle ? 'custom-item' : ''} list-item`}>
                                            {
                                                Helper.emptyString(field.fieldName) ?
                                                    <h4
                                                        className={`text-value`}>{value}</h4>
                                                    :
                                                    <>
                                                        <h4>{field.fieldName}</h4>
                                                        {
                                                            (field.fieldKey === "itemPhotoUrl" || field.fieldKey === "itemUrl") && !Helper.emptyString(value) ?
                                                                value ?
                                                                    <div className={'item-photo-section'}
                                                                         onClick={() => field.fieldKey === "itemPhotoUrl" ? this.showPhotoModal(value) : null}>
                                                                        <a target={`${field.fieldKey === "itemPhotoUrl" ? '' : '_blank'}`}
                                                                           href={`${field.fieldKey === "itemPhotoUrl" ? '#' : value}`}>
                                                                            {`${field.fieldKey === "itemPhotoUrl" ? 'Open item photo' : 'Open item url'}`}</a>
                                                                    </div>
                                                                    : <span
                                                                        className={`${value ? '' : 'empty-value'}`}>{`(None)`}</span>
                                                                :
                                                                <span
                                                                    className={`${value ? '' : 'empty-value'}`}>{`${value ? field.additionValue ? field.additionValue : '' : '(None)'}${!Helper.emptyString(value) ? value : ''}`}</span>
                                                        }

                                                    </>
                                            }

                                        </List.Item>
                                    })
                                }
                            </List>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                            <Label>{itemData[2].segmentTitle}</Label>
                            <List className={"details-list"} vertical="true">
                                {
                                    itemData[2].fields.map((field) => {
                                        let value = this.calculateValue(field, item);
                                        return <List.Item
                                            key={Helper.unique()}
                                            className={`${field.customStyle ? 'custom-item' : ''} list-item`}>
                                            {
                                                Helper.emptyString(field.fieldName) ?
                                                    <h4
                                                        className={`text-value`}>{value}</h4>
                                                    :
                                                    <>
                                                        <h4>{field.fieldName}</h4>

                                                        <span
                                                            className={`${value ? '' : 'empty-value'}`}>{`${value ? field.additionValue ? field.additionValue : '' : '(None)'}${!Helper.emptyString(value) ? value : ''}`}</span>
                                                    </>
                                            }

                                        </List.Item>
                                    })
                                }
                            </List>
                        </Segment>
                        {
                            itemData.length === 4 ?
                                <Segment>
                                    <Label>{itemData[3].segmentTitle}</Label>
                                    <List className={"details-list"} vertical="true">
                                        {
                                            itemData[3].fields.map((field) => {
                                                let value = this.calculateValue(field, item);


                                                return <List.Item
                                                    key={Helper.unique()}
                                                    className={`${field.customStyle ? 'custom-item' : ''} list-item`}>
                                                    {
                                                        Helper.emptyString(field.fieldName) ?
                                                            <h4
                                                                className={`text-value`}>{value}</h4>
                                                            :
                                                            <>
                                                                <h4>{field.fieldName}</h4>

                                                                <span
                                                                    className={`${value ? '' : 'empty-value'}`}>{`${value ? field.additionValue ? field.additionValue : '' : '(None)'}${!Helper.emptyString(value) ? value : ''}`}</span>
                                                            </>
                                                    }

                                                </List.Item>
                                            })
                                        }
                                    </List>
                                </Segment>
                                : null

                        }
                    </Grid.Column>
                </>
            )

        }


        let headers = name === 'trip' ? [
                {title: "Requester", width: 4},
                {title: "Price", width: 4},
                {title: "Current status", width: 5},
            ] :
            [
                {title: "Traveller", width: 4},
                {title: "Price", width: 4},
                {title: "Delivery date", width: 6}
            ];
        if (name === 'trip') {
            headers.push()
        }
        return (
            <Grid columns={2} stackable textAlign='left' className={'add-form-section'}>
                <Grid.Row>
                    {list}
                </Grid.Row>
                {
                    item ?
                        Helper.isEmpty(notification) ?
                            localStorage.getItem('id') ?
                                localStorage.getItem('id') !== item.owner.id ?
                                    <Grid.Row className={'send-btn'}>
                                        <Button floated='right' onClick={this.sendRequest}>
                                            {name === 'trip' ? 'Send a new request' : 'Send a new quote'}
                                        </Button>
                                    </Grid.Row>
                                    : null
                                :
                                <Grid.Row className={'send-btn'}>
                                    <Button floated='right' onClick={this.sendRequest}>
                                        {name === 'trip' ? 'Send a new request' : 'Send a new quote'}
                                    </Button>
                                </Grid.Row>
                            : null
                        : null
                }
                {
                    !Helper.isEmpty(item)
                        ?
                        item.requests || item.quotes ?
                            <Grid.Row className={'extra-details'}>
                                <Segment>
                                    <Label>{name === 'trip' ? 'Requests' : 'Quotes'}</Label>
                                    <CustomTable isEditableTable={true}
                                                 model={{
                                                     title: '',
                                                     name: name === 'trip' ? 'tripRequest' : 'quote',
                                                     actions: {
                                                         view: 'getUsers',
                                                         update: 'updateUser',
                                                         delete: 'deleteUser'
                                                     },
                                                     id: item.id,
                                                     headers: headers,
                                                     schema: name === 'trip' ? 'tripRequests' : 'quotes',
                                                 }}
                                                 items={name === 'trip' ? item.requests : item.quotes}
                                                 rowAction={name === 'trip' ? 'tripRequest' : 'quote'}
                                                 status={status}
                                                 {...this.props}
                                    />
                                </Segment>
                            </Grid.Row>
                            : null
                        : null
                }
            </Grid>
        )
    };

    hide = () => this.setState({open: false}, () => {
        if (this.props.fromUrl) {
            this.props.history.push(`/${this.state.name}s`)
        } else {
            if (this.state.requestData[1].fields.length === 2) {
                this.state.requestData[1].fields.splice(1, 1,
                    {
                        fieldName: 'Length',
                        fieldKey: 'itemLength',
                        fromList: null,
                    },
                    {
                        fieldName: 'Width',
                        fieldKey: 'itemWidth',
                        fromList: null,
                    },
                    {
                        fieldName: 'Height',
                        fieldKey: 'itemHeight',
                        fromList: null,
                    },
                    {
                        fieldName: 'Weight',
                        fieldKey: 'itemWeight',
                        fromList: null,
                    },
                    {
                        fieldName: 'Description',
                        fieldKey: 'itemDescription',
                        fromList: null,
                    },
                    {
                        fieldName: 'Photo',
                        fieldKey: 'itemPhotoUrl',
                        fromList: null,
                    },
                    {
                        fieldName: 'Url',
                        fieldKey: 'itemUrl',
                        fromList: null,
                    },
                    {
                        fieldName: 'Quantity',
                        fieldKey: 'itemQuantity',
                        fromList: null,
                    })
            }
            if (this.state.tripData[1].fields.length > 4) {
                this.state.tripData[1].fields.splice(4, 3)
            }
        }

    });

    updateRequestStatus = async (requestBody) => {
        const response = await this.requestApi.updateRequestStatus(requestBody);
        if (response.code === "SUCCESS000") {
            this.props.props.props.props.notify(false, 'Operation completed successfully')
        } else {
            this.props.props.props.props.notify(true, response.data ? response.data.message : 'Error! please try again')
        }
    };

    renderRequestStatus = () => {
        let {quoteObject, trip} = this.state;
        let btnText = '';
        let requestBody = {};
        let callOperation = true;
        let disabled = false;
        if (quoteObject) {
            if (!quoteObject.quote.confirmed) {
                btnText = 'Confirm'
            } else {
                switch (quoteObject.status) {
                    case 2:
                    case 3:
                        if (trip.status === 1) {
                            disabled = true;
                            btnText = 'Confirmed'
                        } else {
                            btnText = `Change to ${quoteObject.status === 2 ? 'being delivered' : 'delivered'}`;
                            requestBody = {
                                requestId: quoteObject.requestId,
                                status: quoteObject.status + 1
                            }
                        }

                        break;
                    case 4:
                        disabled = true;
                        btnText = 'Delivered';
                        break;
                    default:
                        break
                }
                callOperation = false

            }
            return <Button floated='right'
                           disabled={disabled}
                           onClick={() => {
                               callOperation ? this.quoteOperation() : this.updateRequestStatus(requestBody)
                           }}>
                {btnText}
            </Button>

        } else {
            return null
        }

    };

    handleDateChanged = (event, data, field) => {
        let newEvent = field === 'time' ? Helper.timeStampToDateTimeAsString(event, true, true)
            : Helper.timeStampToDateTimeAsString(event, false);

        /* this.setState({
             trip: update(this.state.trip, {
                 [field]: {
                     $set: field === 'time'? newEvent: event
                 }
             })
         }, this.validateState)*/
        this.setState({
            quoteForm: update(this.state.quoteForm, {
                [field]: {
                    $set: field === 'time' ? newEvent : event
                }
            })
        }, this.validateState)
    };

    changeTimezone = (timezone) => {
        let selectedTimezone = timezones.find(a => a.name === timezone)
        this.setState({
            quoteForm: update(this.state.quoteForm, {
                timezone: {
                    $set: selectedTimezone.offset
                }, deliveryDateTimezone: {
                    $set: timezone
                }
            })
            , timezone: timezone
        }, this.validateState)
    }

    render() {
        let item = this.state[this.state.name];
        let {action, quoteForm, notification, refreshKey, name, request, showTime} = this.state;
        const quoteError = this.context.errorFor(this.state, 'quote', null, true);
        const deliveryDateError = this.context.errorFor(this.state, 'deliveryDate', null, true);
        let isRequestOwner = request && localStorage.getItem("accessToken") ?
            request.owner.id === JSON.parse(atob(localStorage.getItem("accessToken").split('.')[1])).subId : null;
        let actionText = action ? action === 1 && notification.type === 1 ?
            'Create a quote' : action === 1 && notification.type === 2 ?
                'Accept' : action === 2 || action === 4 ? 'Confirm' : '' : '';
        let status = item ? name === 'trip' ? tripStatuses.find(a => a.value === item.status) ?
            tripStatuses.find(a => a.value === item.status).text : '' :
            requestStatuses.find(a => a.value === item.status) ?
                requestStatuses.find(a => a.value === item.status).text : '' : ''
        return (

            <Modal
                //key={Helper.unique()}
                className={`item-details-modal`} size={'large'} open={this.state.open}
                onClose={() => this.hide()}
                closeIcon
            >
                <Modal.Header className={'item-details-header'}>
                    <Header as={'h2'} className={'modal-header-text'}>{`${this.state.name} details`}</Header> {
                    item ?
                        item.status ?
                            <div className={'status-section'}>
                                <Label
                                    className={`${item.status ? Helper.statusColorMapping(status) : 'empty-value'} status-label`}>
                                    {status}</Label>
                            </div>
                            : null
                        : null
                }
                </Modal.Header>
                <Modal.Content>

                    {this.renderDetails()}
                </Modal.Content>
                <Divider horizental/>
                <div className={'item-details-actions'}>
                    {
                        !Helper.emptyString(actionText) ?
                            <Grid.Row className={'actions-row'}>
                                {
                                    notification.action === 1 && notification.type === 1 ?
                                        <Grid.Column computer={16}>
                                            <Segment>
                                                <Form className={'quote-section'}>
                                                    <Form.Group widths={'equal'}>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!quoteError}
                                                        >
                                                            <label>Please enter a quote</label>
                                                            <Input placeholder="Quote"
                                                                   className={'quote-input'}
                                                                   type={'number'}
                                                                   value={quoteForm.quote}
                                                                   onChange={(event, data) => this.handleFieldChanged(event, data)}/>
                                                            <Header as={'h4'}>$</Header>
                                                            {quoteError}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!deliveryDateError}
                                                                    className={'date-input'}
                                                        >
                                                            <label>Delivered before date</label>
                                                            <div className={'date-section'}>
                                                                <DatePicker
                                                                    selectsStart
                                                                    showPreviousMonths={false}
                                                                    placeholder={'Select a date'}
                                                                    dateFormat="Pp"
                                                                    // timeFormat="HH:mm:ss"
                                                                    //showTimeSelect
                                                                    //timeIntervals={60}
                                                                    minDate={moment().toDate()}
                                                                    selected={quoteForm.deliveryDate ? new Date(quoteForm.deliveryDate) : ""}
                                                                    onChange={(event, data) => this.handleDateChanged(event, data, "deliveryDate")}
                                                                    isClearable={false}
                                                                    useWeekdaysShort={true}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    //showTimeInput
                                                                    dropdownMode="select"
                                                                    //timeCaption={'time'}
                                                                    value={quoteForm.deliveryDate ? Helper.timeStampToDateTimeAsString(quoteForm.deliveryDate.toString(), false) : ''}
                                                                    className={'datepicker-text'}
                                                                />
                                                            </div>
                                                            {deliveryDateError}
                                                        </Form.Field>
                                                        <Form.Field inline className={'show-time-field'}>
                                                            {
                                                                showTime ?
                                                                    <label
                                                                        onClick={() => this.setState({showTime: false})}>
                                                                        Hide time <Icon name={'clock outline'}
                                                                                        size={'large'}/>
                                                                    </label>
                                                                    :
                                                                    <label
                                                                        onClick={() => this.setState({showTime: true})}>
                                                                        Show time <Icon name={'clock outline'}
                                                                                        size={'large'}/>
                                                                    </label>
                                                            }

                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    className={'time-input'}>
                                                            {
                                                                showTime ?
                                                                    <>
                                                                        <label>Time</label>
                                                                        <div className={'time-section'}>
                                                                            <DatePicker
                                                                                selectsStart
                                                                                /*showPreviousMonths={false}
                                                                                placeholder={'Select a date'}
                                                                                dateFormat="Pp"*/
                                                                                timeFormat="HH:mm:ss"
                                                                                showTimeSelect={true}
                                                                                showTimeSelectOnly={true}
                                                                                timeIntervals={60}
                                                                                //minDate={moment().toDate()}
                                                                                //selected={trip.time ? trip.time : ""}
                                                                                onChange={(event, data) => this.handleDateChanged(event, data, "time")}
                                                                                isClearable={false}
                                                                                /* useWeekdaysShort={true}
                                                                                 showMonthDropdown
                                                                                 showYearDropdown*/
                                                                                //showTimeInput
                                                                                dropdownMode="select"
                                                                                //timeCaption={'time'}
                                                                                value={quoteForm.time ? quoteForm.time : '00:00:00'}
                                                                                className={'datepicker-text'}
                                                                            />
                                                                            <TimezonePicker
                                                                                value={quoteForm.deliveryDateTimezone}
                                                                                onChange={(timezone) => {
                                                                                    this.changeTimezone(timezone)
                                                                                }}
                                                                                inputProps={{
                                                                                    placeholder: 'Select Timezone...',
                                                                                    name: 'timezone'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }

                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Button floated='right' onClick={this.quoteOperation}>
                                                                {actionText}
                                                            </Button>
                                                        </Form.Field>
                                                    </Form.Group>
                                                </Form>
                                            </Segment>
                                        </Grid.Column>
                                        /* :
                                         notification.action === 1 && notification.type === 2 ||(notification.action === 3)?
                                             <Grid.Column computer={16}>
                                                 <Segment>
                                                     <Label>Quote</Label>
                                                     {
                                                         !Helper.isEmpty(quoteObject) ?
                                                             <List horizontal>
                                                                 <List.Item>
                                                                     <List.Content><h4>Price</h4>
                                                                         <span>{`$${notification.action=== 2? quoteObject.quote.price: quoteObject.price}`}</span></List.Content>
                                                                     <List.Content><h4>Trip owner</h4>
                                                                         <span>{quoteObject.owner.name}</span></List.Content>
                                                                     <List.Content>
                                                                         <Button floated='right'
                                                                                 disabled={quoteObject.accepted}
                                                                                 onClick={this.quoteOperation}>
                                                                             {`${quoteObject.accepted ? 'Accepted' : 'Accept'}`}
                                                                         </Button>
                                                                     </List.Content>
                                                                 </List.Item>
                                                             </List>
                                                             : null

                                                     }
                                                 </Segment>
                                             </Grid.Column>
                                             :
                                             notification.action === 2 || notification.action === 4?
                                                 <Grid.Column computer={16}>
                                                     <Segment>
                                                         <Label>Request</Label>
                                                         {
                                                             !Helper.isEmpty(quoteObject) ?
                                                                 <List horizontal>
                                                                     <List.Item>
                                                                         <List.Content><h4>Price</h4>
                                                                             <span>{`$${notification.action=== 2? quoteObject.quote.price: quoteObject.price}`}</span></List.Content>
                                                                         <List.Content><h4>Trip owner</h4>
                                                                             <span>{quoteObject.owner.name}</span></List.Content>
                                                                         <List.Content>
                                                                             {this.renderRequestStatus()}
                                                                         </List.Content>
                                                                     </List.Item>
                                                                 </List>
                                                                 : null

                                                         }
                                                     </Segment>
                                                 </Grid.Column>*/
                                        : null
                                }
                            </Grid.Row>
                            : null
                    }
                    {
                        name === 'request' && isRequestOwner ?
                            <Grid.Row className={'actions-row'}>
                                <Grid.Column computer={16}>
                                    <Segment>
                                        <Label>Available trips</Label>
                                        <GetRequestAvailableTrips props={this}/>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            : null
                    }
                </div>
                <PhotoModal ref={this.photoModalRef} props={this}/>
                <QuoteModal ref={this.quoteModalRef} props={this}/>
            </Modal>
        );
    }
}