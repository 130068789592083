import React, {Component} from 'react';
import {Header, List} from 'semantic-ui-react'

export default class Disclaimer extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        return (
            <div className={'static-section disclaimer'}>
                <div className={'images-section'}>
                    <img alt={'img'} src={'disclaimer.png'}/>
                </div>
                <div className={'text-section'}>
                    <Header as={'h2'}>Each user confirms that provided information are true and correct, and is
                        accountable for own data; while <h2 className={'app-name'}>ShipAIK </h2> has no control on
                        accuracy of such info,
                        <h2 className={'app-name'}> ShipAIK </h2>holds no responsibilities what so ever accordingly.
                        [General Notice]
                    </Header>

                    <Header as={'h2'}><h2 data-heading="ShipAIK" className={'app-name'}>ShipAIK </h2>requests users to
                        report any misuse. [General Notice]</Header>
                    <List bulleted>
                        <List.Item>
                            <Header as={'h2'}>
                                <h2 className={'app-name'}>ShipAIK </h2>holds limited responsibility to <strong
                                style={{fontWeight: 'bold'}}>activities</strong> in control, while holds no
                                responsibility for any out-of-control activities.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                <h2 className={'app-name'}>ShipAIK </h2> holds no responsibility for <strong
                                style={{fontWeight: 'bold'}}>illegality</strong> of delivered item, it is the traveler’s
                                responsibility to <strong style={{fontWeight: 'bold'}}>detect</strong> the legality of
                                it. Traveler has the right to refuse picking up illegal items, terminate the deal and
                                report the abuse.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Quotations are not an <strong style={{fontWeight: 'bold'}}>agreement</strong> until
                                confirmed by traveler, nor an <strong
                                style={{fontWeight: 'bold'}}>invoice</strong> until requested service completion.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Quotation <strong style={{fontWeight: 'bold'}}>Validity</strong> is related to flight
                                date (depends on service type: 72hours-12hours before flight), while in relation of
                                Request Close Date, the Validity would be reduced or extended automatically with any
                                flight time update.
                            </Header>
                        </List.Item>

                        <List.Item>
                            <Header as={'h2'}>
                                Requester should provide accurate information on service required, or any related info
                                such as size, weight, destination, location of collection and date required to be
                                delivered on or before ...etc. to avoid a dispute. If any of the input is incorrect,
                                it’s Traveler’s right to reject proceeding with the deal.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Requester should account in budget for any Taxes, Customs, Fees or any additional Costs
                                (i.e., Suitable packaging if required by traveler) for transporting the parcel from
                                dispatch State / City to destination State / City, and reasonable last mile charges to
                                assigned address of delivery, all alongside the requested items price where applicable.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Requester (or the receiver if not the same person) should communicate and agree suitable
                                timing to receive the parcel, be available on time; and accept traveler’s reasonable
                                additional charges if arrived late by exceeding allowance to receiving location, or
                                changed receiving location or time.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Requester should save/keep “Delivery Confirmation Code” email for use upon delivery, this code may be shared with concerned person only (i.e., Receiver)
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Upon delivery, requester (or receiver, if not the same person) has the right to open the package and check the item before handing the traveler the delivery code.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Traveler should account in quotation for any Taxes, Customs, fees or any estimated encountered costs (i.e., Suitable packaging if requested) for transporting the parcel from dispatch State / City to destination State / City, and reasonable last mile costs to assigned address of delivery, all alongside the requested items price where applicable.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Traveler should allow in his quotation for acceptable late arrival of receiver (15min in Default), and save receiver contact for communication and ease handing over the delivered items.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Traveler should verify the legality of all items in delivered parcel in both dispatching and destination countries.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Traveler shall verify the provided parcel weight and size, including the packaging, is in line with agreed request, and should not accept parcel in case of discrepancy; and in case the traveler accepted the parcel, the delivery shall persist.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Traveler should be aware of allowed luggage weight, and offered weight of parcels. If extra-weight is encountered for either mis-calculation or non-verification of actual allowed weight by traveler, traveler shall be responsible for any extra-weight charges and absorb the same and to deliver the requests as first priority.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Traveler may request to open the package to check and verify the content type and status, prior accepting parcel receipt.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                For electronic items, it’s traveler’s right to verify the item is operating properly prior acceptance, otherwise if not accepted to verify; it will be sender’s responsibility.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Traveler accepts safety of parcel including its contents from receiving until successful delivery.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Traveler accepts handling escorted person needs until arrival to another companion.
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Shipaik takes no liability for the packaging and labeling advise provided herein, sender holds responsibility to ensure adequate packaging; while traveler holds the responsibility if decided to change the packaging.
                            </Header>
                        </List.Item>
                    </List>
                </div>
            </div>

        );
    }
}
