import React, {Component} from 'react';
import {Header, Icon, Button} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import Helper from "../utils/helper";

export default class NotFound extends Component {

    constructor(props) {
        super(props)
        let user = localStorage.getItem('accessToken')
        /*Redirect in case user is not logged in*/
        if(Helper.emptyString(user)){
            this.props.history.push('/sign-in');
        }
    }

    render() {
        let URL = '/';

        return (
            <div className="ui container" style={{paddingTop: '10%'}}>
                <div className="ui grid center aligned">
                    <div className="row">
                        <div className="sixteen wide tablet six wide computer column">
                            <div className="ui left aligned segment">
                                <Header as='h2' color="grey"
                                        textAlign="center">{'pageNotFound'}</Header>
                                <br/>
                                <Button as={Link} to={URL} icon fluid
                                        labelPosition='left' color="teal" size="large">
                                    {'backToHome'}
                                    <Icon name='home'/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
