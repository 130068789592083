import React, {Component} from "react"
import {
    Segment,
    Form,
    Label,
    Grid,
    Input,
    Search,
    Dropdown,
    Checkbox,
    Button,
    Icon,
    Header,
    Message
} from "semantic-ui-react"
import {deliverTypes, serviceTypes, disabilitiesTypes} from "../../utils/static-data"
import update from "immutability-helper";
import Helper from "../../utils/helper";
import RequestApi from "../api/request-api";
import {ruleRunner, run} from "../../utils/ruleRunner";
import {required} from "../../utils/rules";
import AppContext from "../../../context/app-context";
import AddEmail from '../../login/src/add-email';
import TimezonePicker from 'react-timezone';
import {timezones} from 'react-timezone';
import DatePicker from "react-datepicker";
import moment from "moment";
import _ from 'lodash';
import ItemDetails from "../../table/item-details";


export default class AddRequest extends Component {
    static contextType = AppContext;

    fieldValidations = [
        //ruleRunner("deliverableType", 'deliverableType', required),
        ruleRunner("estimatedDeliveryCharge", 'estimatedDeliveryCharge', required),
        ruleRunner("deliveredBeforeDate2", 'deliveredBeforeDate', required),
        ruleRunner("paymentMethod", 'paymentMethod', required),
        ruleRunner("fromCountryId", 'fromCountryId', required),
        ruleRunner("fromStateId", 'fromStateId', required),
        ruleRunner("fromCityId", 'fromCityId', required),
        ruleRunner("toCountryId", 'toCountryId', required),
        ruleRunner("toStateId", 'toStateId', required),
        ruleRunner("toCityId", 'toCityId', required),
        /*ruleRunner("quantity", 'quantity', required),
        ruleRunner("name", 'name', required),
        ruleRunner("description", 'description', required),
        ruleRunner("length", 'length', required),
        ruleRunner("width", 'width', required),
        ruleRunner("height", 'height', required),
        ruleRunner("weight", 'weight', required),*/
    ];


    constructor(props) {
        super(props);
        let isUpdate = false;
        let isLoading = false;
        this.user = localStorage.getItem('accessToken')
        this.requestProps = props.match ? props : props.props.props
        if (this.requestProps.match.params.id) {
            isUpdate = true;
            isLoading = true;
        }
        this.trip = props.props.props.location.state ? props.props.props.location.state.trip : ""
        let selectedTimezone = timezones.find(a => a.name === 'UTC');
        let initialTimezone = 'UTC';
        let timezone = selectedTimezone.offset;
        this.state = {
            validationErrors: {},
            showErrors: false,
            isUpdate: isUpdate,
            isLoading: isLoading,
            showForm: this.user,
            loader: true,
            showTime: false,
            request: {
                id: isUpdate ? this.requestProps.match.params.id : "",
                serviceType: 1,
                deliverableType: 1, //
                name: "",
                url: "",
                length: "",
                width: "",
                height: "",
                dimensionUnit: 2,
                weight: "",
                weightUnit: 2,
                description: "",
                estimatedDeliveryCharge: "",
                pickUpAddress: "",
                deliverToAddress: "",
                collectedFromAnyLocation: true,
                deliveredBeforeDate: null,
                deliveredBeforeDate2: null,
                paymentMethod: 1,
                photoUrl: "",
                quantity: "",
                fromCountryId: "",
                fromStateId: "",
                fromCityId: "",
                toCountryId: "",
                toStateId: "",
                toCityId: "",
                email: "",
                verificationCode: "",
                time: "00:00:00",
                timezone: timezone,
                deliveredBeforeDateTimezone: initialTimezone,
                //Escorting
                age: '',
                nationalityId: '',
                languages: '',
                medicalStatus: '',
                disabilities: [],
                travelingAloneFear: 0,
                diet: '',
                needs: '',
                airportFrom: '',
                airportTo: '',
                flightNumber: '',
                //Animals
                species: '',
                medicalCertificate: '',
                transportCertificate: '',
                additionalAccessories: '',
                food: ''

            },
            availableTrips: [],
            requestId: "",
            totalAvailableTripsPages: null,
            showAvailableTrips: false,
            commission: '',
            disableValidation: false,
            hideTripValues: !this.trip
        }
        this.requestApi = new RequestApi(this);
        this.addEmailRef = React.createRef();
        this.detailsModalRef = React.createRef();
    }

    changeFieldValidations = (serviceType, deliverableType) => {
        let request = this.state.request;
        this.fieldValidations.splice(9);
        if (serviceType === 3 || serviceType === 4) {
            if (serviceType === 3) {
                this.fieldValidations.push(
                    ruleRunner("age", 'age', required),
                    ruleRunner("nationalityId", 'nationalityId', required),
                    ruleRunner("languages", 'languages', required),
                    ruleRunner("medicalStatus", 'medicalStatus', required),
                    ruleRunner("disabilities", 'disabilities', required),
                    //ruleRunner("travelingAloneFear", 'travelingAloneFear', required),
                    ruleRunner("diet", 'diet', required),
                    ruleRunner("needs", 'needs', required),
                    ruleRunner("airportFrom", 'airportFrom', required),
                    ruleRunner("airportTo", 'airportTo', required),
                    ruleRunner("flightNumber", 'flightNumber', required)
                );
            } else {
                this.fieldValidations.push(
                    ruleRunner("species", 'species', required),
                    ruleRunner("medicalCertificate", 'medicalCertificate', required),
                    ruleRunner("transportCertificate", 'transportCertificate', required),
                    ruleRunner("additionalAccessories", 'additionalAccessories', required),
                    ruleRunner("food", 'food', required),
                    ruleRunner("pickUpAddress", 'pickUpAddress', required),
                    ruleRunner("deliverToAddress", 'deliverToAddress', required),
                );
            }
        } else {
            let deliverType = deliverableType ? deliverableType : request.deliverableType;
            switch (deliverType) {
                case 1:
                    this.fieldValidations.push(
                        ruleRunner("quantity", 'quantity', required),
                        ruleRunner("name", 'name', required),
                        ruleRunner("description", 'description', required),
                        ruleRunner("length", 'length', required),
                        ruleRunner("width", 'width', required),
                        ruleRunner("height", 'height', required),
                        ruleRunner("weight", 'weight', required),
                        ruleRunner("pickUpAddress", 'pickUpAddress', required),
                        ruleRunner("deliverToAddress", 'deliverToAddress', required),
                    );
                    break;
                case 2:
                    this.fieldValidations.push(
                        ruleRunner("quantity", 'quantity', required),
                        ruleRunner("pickUpAddress", 'pickUpAddress', required),
                        ruleRunner("deliverToAddress", 'deliverToAddress', required),
                    );
                    break;
                default:
                    break;
            }
        }
    }

    async componentDidMount() {
        let {isUpdate, request, disableValidation} = this.state;
        let requestId = this.props.requestId
        if (isUpdate || !Helper.emptyString(requestId)) {
            let id = !Helper.emptyString(requestId) ? requestId : request.id;
            const response = await this.requestApi.getOne({id: id});
            if (response.code === 'SUCCESS000') {

                let requestObject = response.data.deliverableInfo;
                //delete response.data.deliverableInfo;
                requestObject = {...requestObject, ...response.data};
                requestObject.travelingAloneFear = requestObject.travelingAloneFear ? 1 : 0;
                this.changeFieldValidations(requestObject.serviceType)
                /* if (requestObject.deliverableType === 2) {
                     disableValidation = true
                     this.fieldValidations.splice(13, 6);
                 }*/
                requestObject.deliveredBeforeDate2 = response.data.deliveredBeforeDate ? response.data.deliveredBeforeDate.split('T')[0] : '';
                requestObject.time = response.data.deliveredBeforeDate ? response.data.deliveredBeforeDate.substring(11, 19) : '';
                let selectedTimezone = timezones.find(a => a.name === requestObject.deliveredBeforeDateTimezone);
                let timezone = selectedTimezone.offset;
                requestObject.timezone = timezone;
                let commission = requestObject ? requestObject.estimatedDeliveryCharge ?
                    (10 * requestObject.estimatedDeliveryCharge) / 100 : '' : ''
                requestObject.fromCountryId = requestObject.from ? requestObject.from.countryId : '';
                requestObject.fromStateId = requestObject.from ? requestObject.from.stateId : '';
                requestObject.fromCityId = requestObject.from ? requestObject.from.cityId : '';
                requestObject.toCountryId = requestObject.to ? requestObject.to.countryId : '';
                requestObject.toStateId = requestObject.to ? requestObject.to.stateId : '';
                requestObject.toCityId = requestObject.to ? requestObject.to.cityId : '';
                let nationalityName = ''
                let nationalityOptions = []
                if (requestObject.nationality) {
                    requestObject.nationalityId = requestObject.nationality.id
                    nationalityName = requestObject.nationality.name
                    const countryResponse = await this.requestApi.getCountries({filter: nationalityName});
                    if (countryResponse.data) {
                        nationalityOptions = countryResponse.data;

                    } else {
                        nationalityOptions = [];
                    }
                }
                let fromStateOptions = []
                let fromCityOptions = []
                let toStateOptions = []
                let toCityOptions = []
                let fromObject = response.data.from;
                let toObject = response.data.to;
                const fromStateResponse = await this.requestApi.getStates({countryId: fromObject.countryId});
                this.props.props.updateLoader();
                if (fromStateResponse.code === 'SUCCESS000') {
                    fromStateOptions = fromStateResponse.data
                    const fromCityResponse = await this.requestApi.getCities({stateId: fromObject.stateId});
                    if (fromCityResponse.code === 'SUCCESS000') {
                        fromCityOptions = fromCityResponse.data
                        const toStateResponse = await this.requestApi.getStates({countryId: toObject.countryId});
                        if (toStateResponse.code === 'SUCCESS000') {
                            toStateOptions = toStateResponse.data
                            const toCityResponse = await this.requestApi.getCities({stateId: toObject.stateId});
                            if (toCityResponse.code === 'SUCCESS000') {
                                toCityOptions = toCityResponse.data
                                this.setState({
                                    request: requestObject,
                                    fromCountryId: fromObject.countryName,
                                    fromStateId: fromObject.stateName,
                                    fromCityId: fromObject.cityName,
                                    toCountryId: toObject.countryName,
                                    toStateId: toObject.stateName,
                                    toCityId: toObject.cityName,
                                    fromStateOptions: fromStateOptions,
                                    fromCityOptions: fromCityOptions,
                                    toStateOptions: toStateOptions,
                                    toCityOptions: toCityOptions,
                                    nationalityId: nationalityName,
                                    nationalityOptions: nationalityOptions,
                                    loader: false,
                                    commission: commission,
                                    disableValidation: disableValidation,
                                    requestId: requestId
                                })
                            } else {
                                this.setState({loader: false})
                            }
                        } else {
                            this.setState({loader: false})
                        }

                    } else {
                        this.setState({loader: false})
                    }

                } else {
                    this.setState({loader: false})
                }
            }
        } else {
            if (this.trip) {
                let request = this.state.request;
                request.deliverableType = this.trip.deliverableType;
                this.changeFieldValidations(this.trip.serviceType, this.trip.deliverableType);
                /* if (this.trip.deliverableType === 2) {
                     disableValidation = true
                     this.fieldValidations.splice(13, 6);
                 }*/
                let fromObject = this.trip.from;
                let toObject = this.trip.to;
                request.fromCountryId = fromObject.countryId;
                request.fromStateId = fromObject.stateId;
                request.fromCityId = fromObject.cityId;
                request.toCountryId = toObject.countryId;
                request.toStateId = toObject.stateId;
                request.toCityId = toObject.cityId;
                request.serviceType = this.trip.serviceType

                this.setState({
                    fromCountryId: fromObject.countryName,
                    fromStateId: fromObject.stateName,
                    fromCityId: fromObject.cityName,
                    toCountryId: toObject.countryName,
                    toStateId: toObject.stateName,
                    toCityId: toObject.cityName,
                    request: request,
                    hideTripValues: false,
                    disableValidation: disableValidation
                })
            } else {
                this.changeFieldValidations(1)
            }
        }
        this.validateState()
    }

    validateState = () => {
        this.setState({validationErrors: run(this.state.request, this.fieldValidations)});
    }


    handleFieldChanged = (field, type = "") => {
        return (e, data) => {

            let value = type === "number" ? parseFloat(data.value) :
                type === "check" ? data.checked : data.value
            if (type === "number") {
                if (value <= 0) {
                    value = 1
                }
            }
            if (field === 'disabilities') {
                let lastValue = value[value.length - 1];
                if (lastValue === 1 && value.length > 1) {
                    value = value.filter(a => a === 1)
                } else {
                    value = value.filter(a => a !== 1)
                }
            }
            let commission = field === 'estimatedDeliveryCharge' ? (10 * value) / 100 : ''
            let request = this.state.request
            let disableValidation = this.state.disableValidation
            if (field === 'serviceType') {
                this.changeFieldValidations(value, request.deliverableType)
                /*if (value === 2) {
                    disableValidation = true
                    this.fieldValidations.splice(13, 6);
                } else {
                    if (disableValidation) {
                        this.fieldValidations.push(
                            ruleRunner("name", 'name', required),
                            ruleRunner("description", 'description', required),
                            ruleRunner("length", 'length', required),
                            ruleRunner("width", 'width', required),
                            ruleRunner("height", 'height', required),
                            ruleRunner("weight", 'weight', required),
                        )
                    }
                    disableValidation = false
                }*/
            }
            if (field === 'deliverableType') {
                this.changeFieldValidations(request.serviceType, value)
            }
            this.setState({
                request: update(this.state.request, {
                    [field]: {
                        $set: value
                    }
                })
                , commission: commission,
                disableValidation: disableValidation
            }, this.validateState)
        }

    }

    handleDateChanged = (event, data, field) => {
        let newEvent = field === 'time' ? Helper.timeStampToDateTimeAsString(event, true, true)
            : Helper.timeStampToDateTimeAsString(event, false);

        /* this.setState({
             trip: update(this.state.trip, {
                 [field]: {
                     $set: field === 'time'? newEvent: event
                 }
             })
         }, this.validateState)*/
        this.setState({
            request: update(this.state.request, {
                [field]: {
                    $set: field === 'time' ? newEvent : event
                }
            })
        }, this.validateState)
    };

    showErrorMessage = (errorObject = null) => {
        if (Helper.isEmpty(errorObject)) {
            this.setState({
                serverError: true,
                loaded: false,
                serverErrorMessage: 'errorPleaseTryAgain'
            })
        } else {
            this.setState({
                serverError: true,
                loaded: false,
                serverErrorMessage: errorObject.serverErrorMessage,
                validationErrors: errorObject.validationErrors
            })
        }
    }

    handleAction = async () => {
        this.setState({showErrors: true})
        if (this.addEmailRef.current) {
            this.addEmailRef.current.validateState("", true)
        }

        if (!Helper.isEmpty(this.state.validationErrors)) return null;
        const {request, isUpdate} = this.state;
        request.tripId = this.trip ? this.trip.id : "";
        const requestBody = request;
        this.setState({loading: true});
        const response = isUpdate ? await this.requestApi.update(requestBody) : await this.requestApi.add(requestBody);

        if (response.code === 'SUCCESS000') {
            this.requestProps.notify(false, isUpdate ? 'Updated successfully' : 'Added successfully')

            this.setState({
                availableTrips: response.data ? response.data.availableTrips.list : [],
                totalAvailableTripsPages: response.data ? response.data.availableTrips.totalPages : "",
                requestId: response.data ? response.data.requestId : request.id,
                showAvailableTrips: true,
            }, () => {
                if (!isUpdate) {
                    this.props.enableAvailableTrips(response.data);

                }
            })
        } else {
            let errorData = response.data
            if (!Helper.isEmpty(errorData)) {
                if (Helper.isArray(errorData)) {
                    let errorObject = {
                        serverError: true,
                        validationErrors: {},
                    };
                    errorData.map((error) => {
                        let serverValidationError = Helper.errorObjectFormater(error);
                        if (!Helper.isEmpty(serverValidationError)) {
                            errorObject = {
                                serverError: true,
                                validationErrors: errorObject.validationErrors ? {...errorObject.validationErrors, ...serverValidationError} : {}
                            };
                        }
                    });
                    this.showErrorMessage(errorObject)
                } else {
                    if (errorData.code === "USER009") {
                        if (this.addEmailRef.current) {
                            this.addEmailRef.current.validateState(errorData.message, true)
                        }
                    } else {
                        let serverValidationError = Helper.errorObjectFormater(errorData)

                        if (!Helper.isEmpty(serverValidationError)) {
                            let errorObject = {
                                serverError: true,
                                validationErrors: serverValidationError,
                            }
                            this.showErrorMessage(errorObject)
                        }
                    }
                }

            } else {
                this.requestProps.notify(true, 'Error, please try again!')
            }
        }

        this.setState({loading: false});
    }

    handleImageChanged = (event, data, field) => {

        let file = event.target.files[0];

        this.setState({
            request: update(this.state.request, {
                [field]: {
                    $set: file
                }
            }),
            fileName: file.name
        }, () => {
            this.validateState();
        });
    }

    handleStateChanged = async (e, {value}, field, options, loader, type) => {
        let {fromStateId, fromCityId, toStateId, toCityId} = this.state;
        this.setState(
            {[field]: value, [loader]: true}
            , async () => {
                let stateOptions = [];
                switch (type) {
                    case 'country':
                        const response = await this.requestApi.getCountries({filter: value});
                        if (response.data) {
                            stateOptions = response.data;
                            this.setState({
                                [loader]: false,
                                [options]: stateOptions,
                                fromStateId: field === 'fromCountryId' ? '' : fromStateId,
                                fromCityId: field === 'fromCountryId' ? '' : fromCityId,
                                toStateId: field === 'toCountryId' ? '' : toStateId,
                                toCityId: field === 'toCountryId' ? '' : toCityId,
                            }, () => {
                                this.validateState()
                            })
                        } else {
                            this.setState({
                                [loader]: false,
                                [options]: []
                            }, this.validateState)
                        }
                        break;
                    case 'state':
                        const stateExp = new RegExp(value, 'i');
                        const isMatchStateName = result => {
                            let searchRes = false
                            if (!searchRes) {
                                if (!searchRes && result.name) {
                                    searchRes = stateExp.test(result.name)
                                }
                            }
                            return searchRes
                        }

                        let fullStateList = field === 'fromStateId' ? this.state.fromStateFullOptions : this.state.toStateFullOptions
                        let stateResults = _.filter(fullStateList, isMatchStateName);
                        this.setState({
                            [options]: stateResults,
                            [loader]: false,
                            fromCityId: field === 'fromStateId' ? '' : fromCityId,
                            toCityId: field === 'toStateId' ? '' : toCityId,
                        }, this.validateState)
                        break;
                    case 'city':
                        const cityExp = new RegExp(value, 'i');
                        const isMatchName = result => {
                            let searchRes = false
                            if (!searchRes) {
                                if (!searchRes && result.name) {
                                    searchRes = cityExp.test(result.name)
                                }
                            }
                            return searchRes
                        }

                        let fullCityList = field === 'fromCityId' ? this.state.fromCityFullOptions : this.state.toCityFullOptions
                        let cityResults = _.filter(fullCityList, isMatchName);
                        this.setState({
                            [options]: cityResults,
                            [loader]: false
                        }, this.validateState)
                        break;
                }

            })
    };

    renderSearchResult = (result) => {
        return (result.name)
    };

    handleResultSelect = async (e, {result}, field, type) => {
        let stateOptions = [];
        let {
            fromStateOptions, fromCityOptions, fromStateFullOptions, toStateFullOptions,
            fromCityFullOptions, toCityFullOptions, toStateOptions, toCityOptions
        } = this.state
        switch (type) {
            case 'country':
                const response = await this.requestApi.getStates({countryId: result.id});

                if (response.data) {
                    stateOptions = response.data;
                    this.setState({
                        request: update(this.state.request, {
                            [field]: {
                                $set: result.id
                            }
                        }),
                        [field]: result.name,
                        fromStateFullOptions: field === 'fromCountryId' ? stateOptions : fromStateFullOptions,
                        toStateFullOptions: field === 'toCountryId' ? stateOptions : toStateFullOptions,
                        fromStateOptions: field === 'fromCountryId' ? stateOptions : fromStateOptions,
                        toStateOptions: field === 'toCountryId' ? stateOptions : toStateOptions,
                    }, () => {
                        this.validateState()
                    })
                } else {
                    this.setState({
                        fromStateFullOptions: field === 'fromCountryId' ? [] : fromStateFullOptions,
                        toStateFullOptions: field === 'fromCountryId' ? [] : fromStateOptions,
                        fromStateOptions: field === 'fromCountryId' ? [] : fromStateOptions,
                        toStateOptions: field === 'toCountryId' ? [] : toStateOptions,
                    }, this.validateState)
                }
                break;
            case 'state':
                const citiesResponse = await this.requestApi.getCities({stateId: result.id});
                if (citiesResponse.data) {
                    stateOptions = citiesResponse.data;
                    this.setState({
                        request: update(this.state.request, {
                            [field]: {
                                $set: result.id
                            }
                        }),
                        [field]: result.name,
                        fromCityFullOptions: field === 'fromStateId' ? stateOptions : fromCityFullOptions,
                        toCityFullOptions: field === 'toStateId' ? stateOptions : toCityFullOptions,
                        fromCityOptions: field === 'fromStateId' ? stateOptions : fromCityOptions,
                        toCityOptions: field === 'toStateId' ? stateOptions : toCityOptions,
                    }, () => {
                        this.validateState()
                    })
                } else {
                    this.setState({
                        fromCityFullOptions: field === 'fromStateId' ? [] : fromCityFullOptions,
                        toCityFullOptions: field === 'toStateId' ? [] : toCityFullOptions,
                        fromCityOptions: field === 'fromStateId' ? [] : fromCityOptions,
                        toCityOptions: field === 'toCityId' ? [] : toCityOptions,
                    }, this.validateState)
                }
                break;
            case 'city':
                this.setState({
                    request: update(this.state.request, {
                        [field]: {
                            $set: result.id
                        }
                    }),
                    [field]: result.name,
                }, this.validateState);
                break;
        }

    };

    showForm = () => {
        let {showForm} = this.state
        this.setState({
            showForm: !showForm
        })
    }

    updateEmailForm = (field, value) => {
        this.setState({
            request: update(this.state.request, {
                [field]: {
                    $set: value
                }
            })
        }, this.validateState)
    }

    changeTimezone = (timezone) => {
        let selectedTimezone = timezones.find(a => a.name === timezone)
        this.setState({
            request: update(this.state.request, {
                timezone: {
                    $set: selectedTimezone.offset
                }, deliveredBeforeDateTimezone: {
                    $set: timezone
                }
            })
            , timezone: timezone
        }, this.validateState)
    }

    renderItemDetails = () => {
        this.detailsModalRef.current.show(this.trip.id, 'trip');
    };

    emptyForm = () => {
        let request = this.state.request
        request.deliverableType = "";
        this.trip = {};
        this.setState({
            fromCountryId: "",
            fromStateId: "",
            fromCityId: "",
            toCountryId: "",
            toStateId: "",
            toCityId: "",
            request: request,
            hideTripValues: true
        })
    };

    handleBack = () => {
        if (this.props) {
            if (this.props.props) {
                if (this.props.props.props) {
                    this.props.props.props.history.goBack();
                }
            }
        }
    };

    render() {
        let {
            request, loading, isUpdate, commission, showForm, timezone, disableValidation, requestId,
            fromCountryOptions, fromStateOptions, fromCityOptions, fromCountryLoader, fromStateLoader, fromCityLoader,
            toCountryOptions, toStateOptions, toCityOptions, toCountryLoader, toStateLoader, toCityLoader,
            fromCountryId, fromStateId, fromCityId, toCountryId, toStateId, toCityId, hideTripValues, showTime, loader, nationalityLoader,
            nationalityOptions, nationalityId
        } = this.state;
        const deliverableType = this.context.errorFor(this.state, 'deliverableType', null, true);
        const name = this.context.errorFor(this.state, 'name', null, true);
        const length = this.context.errorFor(this.state, 'length', null, true);
        const width = this.context.errorFor(this.state, 'width', null, true);
        const height = this.context.errorFor(this.state, 'height', null, true);
        const weight = this.context.errorFor(this.state, 'weight', null, true);
        const description = this.context.errorFor(this.state, 'description', null, true);
        const estimatedDeliveryCharge = this.context.errorFor(this.state, 'estimatedDeliveryCharge', null, true);
        const pickUpAddress = this.context.errorFor(this.state, 'pickUpAddress', null, true);
        const deliverToAddress = this.context.errorFor(this.state, 'deliverToAddress', null, true);
        const deliveredBeforeDate = this.context.errorFor(this.state, 'deliveredBeforeDate2', null, true);
        const paymentMethod = this.context.errorFor(this.state, 'paymentMethod', null, true);
        const quantity = this.context.errorFor(this.state, 'quantity', null, true);
        const fromCountryError = this.context.errorFor(this.state, 'fromCountryId', null, true);
        const fromStateError = this.context.errorFor(this.state, 'fromStateId', null, true);
        const fromCityError = this.context.errorFor(this.state, 'fromCityId', null, true);
        const toCountryError = this.context.errorFor(this.state, 'toCountryId', null, true);
        const toStateError = this.context.errorFor(this.state, 'toStateId', null, true);
        const toCityError = this.context.errorFor(this.state, 'toCityId', null, true);

        //Escorting
        const ageError = this.context.errorFor(this.state, 'age', null, true);
        const nationalityError = this.context.errorFor(this.state, 'nationalityId', null, true);
        const languagesError = this.context.errorFor(this.state, 'languages', null, true);
        const medicalStatusError = this.context.errorFor(this.state, 'medicalStatus', null, true);
        const disabilitiesError = this.context.errorFor(this.state, 'disabilities', null, true);
        //const travelingAloneFearError = this.context.errorFor(this.state, 'travelingAloneFear', null, true);
        const dietError = this.context.errorFor(this.state, 'diet', null, true);
        const needsError = this.context.errorFor(this.state, 'needs', null, true);
        const airportFromError = this.context.errorFor(this.state, 'airportFrom', null, true);
        const airportToError = this.context.errorFor(this.state, 'airportTo', null, true);
        const flightNumberError = this.context.errorFor(this.state, 'flightNumber', null, true);

        //Animals
        const speciesError = this.context.errorFor(this.state, 'species', null, true);
        const medicalCertificateError = this.context.errorFor(this.state, 'medicalCertificate', null, true);
        const transportCertificateError = this.context.errorFor(this.state, 'transportCertificate', null, true);
        const additionalAccessoriesError = this.context.errorFor(this.state, 'additionalAccessories', null, true);
        const foodError = this.context.errorFor(this.state, 'food', null, true);

        return (
            <Form className={`add-form ${!this.user ? 'no-margin' : ''}`}
            >
                <div className={`add-form-section request-form`}>
                    {
                        Helper.emptyString(this.user) ?
                            <AddEmail ref={this.addEmailRef} props={this}/>
                            : null
                    }
                    {
                        showForm ?
                            <Grid columns={2} stackable textAlign='left'>
                                <Grid.Row>
                                    {
                                        !Helper.isEmpty(this.trip) ?
                                            <Message
                                                visible
                                                warning
                                                header={<><Icon name={'warning sign'}/> Attention</>}
                                                content={<div className={'trip-section-content'}>This request will be
                                                    added to
                                                    <a onClick={() => this.renderItemDetails()}> this trip</a>
                                                    <Button onClick={() => this.emptyForm()}>Cancel</Button></div>}
                                                style={{width: "100%", marginBottom: '30px'}}
                                            />
                                            : null
                                    }
                                    <Grid.Column>
                                        <Segment className={'service-section'}>
                                            <Label>Service requested</Label>
                                            <Form.Field inline>
                                                <label>Service type</label>
                                                <Dropdown
                                                    search
                                                    fluid
                                                    selection
                                                    options={serviceTypes}
                                                    selectOnBlur={false}
                                                    value={request.serviceType}
                                                    placeholder={'Select'}
                                                    onChange={this.handleFieldChanged('serviceType')}
                                                />
                                            </Form.Field>
                                            {
                                                request.serviceType === 3 || request.serviceType === 4 ?
                                                    null :
                                                    <Form.Field inline
                                                                required
                                                                error={!!deliverableType}
                                                    >
                                                        <label>Delivery type</label>
                                                        <Dropdown
                                                            //disabled={!hideTripValues}
                                                            search
                                                            fluid
                                                            selection
                                                            options={deliverTypes}
                                                            selectOnBlur={false}
                                                            value={request.deliverableType}
                                                            placeholder={'Select'}
                                                            onChange={this.handleFieldChanged('deliverableType')}
                                                        />
                                                        {deliverableType}
                                                    </Form.Field>
                                            }
                                        </Segment>
                                        <Segment className={'travel-details-section'}>
                                            <Label>
                                                {`${request.serviceType === 3 ? "Escorted persons' details" : 'Deliverable details'} `}
                                            </Label>
                                            {
                                                request.serviceType === 3 ?
                                                    <>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!ageError}
                                                        >
                                                            <label>Age</label>
                                                            <Input placeholder="Age"
                                                                   type={'number'}
                                                                   value={request.age}
                                                                   onChange={this.handleFieldChanged("age")}/>

                                                            {ageError}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!nationalityError}
                                                        >
                                                            <label>Nationality</label>
                                                            <Search
                                                                //className={`${!fromCountryError ? 'normal' : ''} ${!hideTripValues ? 'disabled' : ''}`}
                                                                loading={nationalityLoader}
                                                                placeholder={'Search for country'}
                                                                onResultSelect={(event, data) => this.handleResultSelect(event, data, 'nationalityId', 'country')}
                                                                onSearchChange={(event, data) => {
                                                                    this.handleStateChanged(event, data, 'nationalityId',
                                                                        'nationalityOptions', 'nationalityLoader', 'country')
                                                                }}
                                                                results={nationalityOptions}
                                                                value={nationalityId}
                                                                resultRenderer={(result) => this.renderSearchResult(result)}
                                                                noResultsMessage={'No result found'}
                                                            />
                                                            {nationalityError}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!languagesError}
                                                        >
                                                            <label>Languages</label>
                                                            <Input placeholder="Languages"
                                                                   value={request.languages}
                                                                   onChange={this.handleFieldChanged("languages")}/>
                                                            {languagesError}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!medicalStatusError}
                                                        >
                                                            <label>Medical Status</label>
                                                            <Input placeholder="Medical Status"
                                                                   value={request.medicalStatus}
                                                                   onChange={this.handleFieldChanged("medicalStatus")}/>
                                                            {medicalStatusError}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!disabilitiesError}
                                                        >
                                                            <label>Disablilities</label>
                                                            <Dropdown
                                                                search
                                                                fluid
                                                                selection
                                                                multiple
                                                                options={disabilitiesTypes}
                                                                selectOnBlur={false}
                                                                value={request.disabilities}
                                                                placeholder={'Select'}
                                                                onChange={this.handleFieldChanged('disabilities')}
                                                            />
                                                            {disabilitiesError}
                                                        </Form.Field>
                                                        <Form.Field inline>
                                                            <label>Afraid of flying/travelling</label>
                                                            <div className={'unit-section'}>
                                                                <Checkbox
                                                                    radio
                                                                    label='Yes'
                                                                    name='checkboxRadioGroup'
                                                                    value={1}
                                                                    checked={request.travelingAloneFear === 1}
                                                                    onChange={this.handleFieldChanged("travelingAloneFear")}
                                                                />
                                                                <Checkbox
                                                                    radio
                                                                    label='No'
                                                                    name='checkboxRadioGroup'
                                                                    value={0}
                                                                    checked={request.travelingAloneFear === 0}
                                                                    onChange={this.handleFieldChanged("travelingAloneFear")}
                                                                />
                                                            </div>
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!dietError}
                                                        >
                                                            <label>Diet</label>
                                                            <Input placeholder="Diet"
                                                                   value={request.diet}
                                                                   onChange={this.handleFieldChanged("diet")}/>
                                                            {dietError}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!needsError}
                                                        >
                                                            <label>Needs</label>
                                                            <Input placeholder="Needs"
                                                                   value={request.needs}
                                                                   onChange={this.handleFieldChanged("needs")}/>
                                                            {needsError}
                                                        </Form.Field>
                                                    </>
                                                    : request.serviceType === 4 ?
                                                    <>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!speciesError}
                                                        >
                                                            <label>Species</label>
                                                            <Input placeholder="Species"
                                                                   value={request.species}
                                                                   onChange={this.handleFieldChanged("species")}/>

                                                            {speciesError}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!medicalCertificateError}
                                                        >
                                                            <label>Medical Certificate</label>
                                                            <Input placeholder="Medical CertificateError"
                                                                   value={request.medicalCertificate}
                                                                   onChange={this.handleFieldChanged("medicalCertificate")}/>

                                                            {medicalCertificateError}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!transportCertificateError}
                                                        >
                                                            <label>Transport Certificate</label>
                                                            <Input placeholder="Transport Certificate"
                                                                   value={request.transportCertificate}
                                                                   onChange={this.handleFieldChanged("transportCertificate")}/>

                                                            {transportCertificateError}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!additionalAccessoriesError}
                                                        >
                                                            <label>Additional Accessories</label>
                                                            <Input placeholder="Additional Accessories"
                                                                   value={request.additionalAccessories}
                                                                   onChange={this.handleFieldChanged("additionalAccessories")}/>

                                                            {additionalAccessoriesError}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!foodError}
                                                        >
                                                            <label>Food</label>
                                                            <Input placeholder="Food"
                                                                   value={request.food}
                                                                   onChange={this.handleFieldChanged("food")}/>

                                                            {foodError}
                                                        </Form.Field>
                                                    </>
                                                    :
                                                    request.deliverableType === 2 ?
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!quantity}
                                                        >
                                                            <label>Quantity</label>
                                                            <Input placeholder="Quantity"
                                                                   type={'number'}
                                                                   value={request.quantity}
                                                                   onChange={this.handleFieldChanged("quantity", 'number')}/>
                                                            {quantity}
                                                        </Form.Field>
                                                        :
                                                        request.deliverableType === 1 ?
                                                            <>
                                                                <Form.Field inline
                                                                            required
                                                                            error={!!name}
                                                                >
                                                                    <label>Name</label>
                                                                    <Input placeholder="Name"
                                                                           value={request.name}
                                                                           onChange={this.handleFieldChanged("name")}/>

                                                                    {name}
                                                                </Form.Field>
                                                                <Form.Field inline>
                                                                    <label>URL</label>
                                                                    <Input placeholder="Url"
                                                                           value={request.url}
                                                                           onChange={this.handleFieldChanged("url")}/>
                                                                </Form.Field>
                                                                <Form.Field inline>
                                                                    <label>Weight unit</label>
                                                                    <div className={'unit-section'}>
                                                                        <Checkbox
                                                                            defaultChecked={true}
                                                                            radio
                                                                            label='Kg'
                                                                            name='checkboxRadioGroup'
                                                                            value={1}
                                                                            checked={request.weightUnit === 1}
                                                                            onChange={this.handleFieldChanged("weightUnit")}
                                                                        />
                                                                        <Checkbox
                                                                            radio
                                                                            label='Lbs'
                                                                            name='checkboxRadioGroup'
                                                                            value={2}
                                                                            checked={request.weightUnit === 2}
                                                                            onChange={this.handleFieldChanged("weightUnit")}
                                                                        />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field inline
                                                                            required={!disableValidation}
                                                                            error={!!weight}
                                                                >
                                                                    <label>Weight</label>
                                                                    <Input placeholder="Weight"
                                                                           type={'number'}
                                                                           value={request.weight}
                                                                           onChange={this.handleFieldChanged("weight", 'number')}/>
                                                                </Form.Field>
                                                                <Form.Field inline>
                                                                    <label>Dimension unit</label>
                                                                    <div className={'unit-section'}>
                                                                        <Checkbox
                                                                            radio
                                                                            label='Cm'
                                                                            name='checkboxRadioGroup'
                                                                            value={2}
                                                                            checked={request.dimensionUnit === 2}
                                                                            onChange={this.handleFieldChanged("dimensionUnit")}
                                                                        />
                                                                        <Checkbox
                                                                            radio
                                                                            label='In'
                                                                            name='checkboxRadioGroup'
                                                                            value={1}
                                                                            checked={request.dimensionUnit === 1}
                                                                            onChange={this.handleFieldChanged("dimensionUnit")}
                                                                        />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field inline
                                                                            required={!disableValidation}
                                                                            error={!!length}
                                                                >
                                                                    <label>Length</label>
                                                                    <Input placeholder="Length"
                                                                           type={'number'}
                                                                           value={request.length}
                                                                           onChange={this.handleFieldChanged("length", 'number')}/>
                                                                    {disableValidation ? null : length}
                                                                </Form.Field>
                                                                <Form.Field inline
                                                                            required={!disableValidation}
                                                                            error={!!width}
                                                                >
                                                                    <label>Width</label>
                                                                    <Input placeholder="Width"
                                                                           type={'number'}
                                                                           value={request.width}
                                                                           onChange={this.handleFieldChanged("width", 'number')}/>
                                                                    {disableValidation ? null : width}
                                                                </Form.Field>
                                                                <Form.Field inline
                                                                            required={!disableValidation}
                                                                            error={!!height}
                                                                >
                                                                    <label>Height</label>
                                                                    <Input placeholder="Height"
                                                                           type={'number'}
                                                                           value={request.height}
                                                                           onChange={this.handleFieldChanged("height", 'number')}/>

                                                                    {disableValidation ? null : height}
                                                                </Form.Field>
                                                                <Form.Field inline
                                                                            required
                                                                            error={!!description}
                                                                >
                                                                    <label>Description</label>
                                                                    <Input placeholder="Description"
                                                                           value={request.description}
                                                                           onChange={this.handleFieldChanged("description")}/>
                                                                    {description}
                                                                </Form.Field>
                                                                <Form.Field inline
                                                                            required
                                                                            error={!!quantity}
                                                                >
                                                                    <label>Quantity</label>
                                                                    <Input placeholder="Quantity"
                                                                           type={'number'}
                                                                           value={request.quantity}
                                                                           onChange={this.handleFieldChanged("quantity", 'number')}/>
                                                                    {quantity}
                                                                </Form.Field>
                                                                <Form.Field className={'photo-field'} inline>
                                                                    <label>Photo</label>
                                                                    <div className='wrapping-input'>
                                                                        <button className='upload-btn'
                                                                                onClick={(event) => {
                                                                                    event.preventDefault();
                                                                                    this.inputRef.inputRef.current.click();
                                                                                }}>Choose a file
                                                                        </button>
                                                                        <span
                                                                            className='input-filename'>{this.state.fileName ? this.state.fileName : 'No file chosen'}</span>
                                                                        <Icon name={'upload'}/>
                                                                    </div>
                                                                    <Input className='inputfile display-none'
                                                                           type="file"
                                                                           ref={(ref) => this.inputRef = ref}
                                                                           multiple
                                                                           accept="image/x-png,image/gif,image/jpeg"
                                                                           onChange={(event, data) => this.handleImageChanged(event, data, 'photoUrl')}>
                                                                    </Input>
                                                                </Form.Field>
                                                            </>
                                                            : null
                                            }
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Segment className={'carriage-details-section'}>
                                            <Label>
                                                {request.serviceType === 3 ? 'Flight details' : 'Delivery details'}
                                            </Label>
                                            <Form.Field className={'location-section'}
                                                        inline
                                                        required
                                                        error={!!fromStateError || !!fromCountryError || !!fromCityError}
                                            >

                                                <label>From</label>
                                                <Search
                                                    className={`${!fromCountryError ? 'normal' : ''} ${!hideTripValues ? 'disabled' : ''}`}
                                                    loading={fromCountryLoader}
                                                    placeholder={'Search for country'}
                                                    onResultSelect={(event, data) => this.handleResultSelect(event, data, 'fromCountryId', 'country')}
                                                    onSearchChange={(event, data) => {
                                                        this.handleStateChanged(event, data, 'fromCountryId',
                                                            'fromCountryOptions', 'fromCountryLoader', 'country')
                                                    }}
                                                    results={fromCountryOptions}
                                                    value={fromCountryId}
                                                    resultRenderer={(result) => this.renderSearchResult(result)}
                                                    noResultsMessage={'No result found'}
                                                />
                                                {fromCountryError}
                                                <span/>
                                                <Search
                                                    className={`${Helper.emptyString(fromCountryId) || (!hideTripValues) ? 'disabled' : ''} ${!fromStateError ? 'normal' : ''}`}
                                                    loading={fromStateLoader}
                                                    placeholder={'Search for state'}
                                                    onResultSelect={(event, data) => this.handleResultSelect(event, data, 'fromStateId', 'state')}
                                                    onSearchChange={(event, data) => {
                                                        this.handleStateChanged(event, data, 'fromStateId',
                                                            'fromStateOptions', 'fromStateLoader', 'state')
                                                    }}
                                                    results={fromStateOptions}
                                                    value={fromStateId}
                                                    resultRenderer={(result) => this.renderSearchResult(result)}
                                                    noResultsMessage={'No result found'}
                                                />
                                                {fromStateError}
                                                <span/>
                                                <Search
                                                    className={`${Helper.emptyString(fromStateId) || (!hideTripValues) ? 'disabled' : ''} ${!fromCityError ? 'normal' : ''}`}
                                                    loading={fromCityLoader}
                                                    placeholder={'Search for city'}
                                                    onResultSelect={(event, data) => this.handleResultSelect(event, data, 'fromCityId', 'city')}
                                                    onSearchChange={(event, data) => {
                                                        this.handleStateChanged(event, data, 'fromCityId',
                                                            'fromCityOptions', 'fromCityLoader', 'city')
                                                    }}
                                                    results={fromCityOptions}
                                                    value={fromCityId}
                                                    resultRenderer={(result) => this.renderSearchResult(result)}
                                                    noResultsMessage={'No result found'}
                                                />
                                                {fromCityError}
                                            </Form.Field>

                                            {
                                                request.serviceType === 3 ?
                                                    <Form.Field inline
                                                                required
                                                                error={!!airportFromError}
                                                    >
                                                        <label>Airport(From)</label>
                                                        <Input placeholder="Form"
                                                               value={request.airportFrom}
                                                               onChange={this.handleFieldChanged("airportFrom")}/>
                                                        {airportFromError}
                                                    </Form.Field>
                                                    : null
                                            }

                                            <Form.Field className={'location-section'}
                                                        inline
                                                        required
                                                        error={!!toCountryError || !!toStateError || !!toCityError}
                                            >
                                                <label>To</label>

                                                <Search
                                                    className={`${!toCountryError ? 'normal' : ''} ${!hideTripValues ? 'disabled' : ''}`}
                                                    loading={toCountryLoader}
                                                    placeholder={'Search for country'}
                                                    onResultSelect={(event, data) => this.handleResultSelect(event, data, 'toCountryId', 'country')}
                                                    onSearchChange={(event, data) => {
                                                        this.handleStateChanged(event, data, 'toCountryId',
                                                            'toCountryOptions', 'toCountryLoader', 'country')
                                                    }}
                                                    results={toCountryOptions}
                                                    value={toCountryId}
                                                    resultRenderer={(result) => this.renderSearchResult(result)}
                                                    noResultsMessage={'No result found'}
                                                />
                                                {toCountryError}
                                                <span/>
                                                <Search
                                                    className={`${Helper.emptyString(toCountryId) || (!hideTripValues) ? 'disabled' : ''} ${!toStateError ? 'normal' : ''}`}
                                                    loading={toStateLoader}
                                                    placeholder={'Search for state'}
                                                    onResultSelect={(event, data) => this.handleResultSelect(event, data, 'toStateId', 'state')}
                                                    onSearchChange={(event, data) => {
                                                        this.handleStateChanged(event, data, 'toStateId',
                                                            'toStateOptions', 'toStateLoader', 'state')
                                                    }}
                                                    results={toStateOptions}
                                                    value={toStateId}
                                                    resultRenderer={(result) => this.renderSearchResult(result)}
                                                    noResultsMessage={'No result found'}
                                                />
                                                {toStateError}
                                                <span/>
                                                <Search
                                                    className={`${Helper.emptyString(toStateId) || (!hideTripValues) ? 'disabled' : ''} ${!toCityError ? 'normal' : ''}`}
                                                    loading={toCityLoader}
                                                    placeholder={'Search for city'}
                                                    onResultSelect={(event, data) => this.handleResultSelect(event, data, 'toCityId', 'city')}
                                                    onSearchChange={(event, data) => {
                                                        this.handleStateChanged(event, data, 'toCityId',
                                                            'toCityOptions', 'toCityLoader', 'city')
                                                    }}
                                                    results={toCityOptions}
                                                    value={toCityId}
                                                    resultRenderer={(result) => this.renderSearchResult(result)}
                                                    noResultsMessage={'No result found'}
                                                />
                                                {toCityError}
                                            </Form.Field>

                                            {
                                                request.serviceType === 3 ?
                                                    <>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!airportToError}
                                                        >
                                                            <label>Airport(To)</label>
                                                            <Input placeholder="To"
                                                                   value={request.airportTo}
                                                                   onChange={this.handleFieldChanged("airportTo")}/>
                                                            {airportToError}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!flightNumberError}
                                                        >
                                                            <label>Flight Number</label>
                                                            <Input placeholder="Flight Number"
                                                                   value={request.flightNumber}
                                                                   onChange={this.handleFieldChanged("flightNumber")}/>
                                                            {flightNumberError}
                                                        </Form.Field>
                                                    </>
                                                    : null
                                            }

                                            {
                                                request.serviceType === 3?
                                                    null:
                                                    <>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!pickUpAddress}
                                                        >
                                                            <label>Pick up address</label>
                                                            <Input placeholder="Pick up address"
                                                                   value={request.pickUpAddress}
                                                                   onChange={this.handleFieldChanged("pickUpAddress")}/>
                                                            {pickUpAddress}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!deliverToAddress}
                                                        >
                                                            <label>Deliver to address</label>
                                                            <Input placeholder="Deliver to address"
                                                                   value={request.deliverToAddress}
                                                                   onChange={this.handleFieldChanged("deliverToAddress")}/>
                                                            {deliverToAddress}
                                                        </Form.Field>
                                                    </>
                                            }

                                            <Form.Field inline
                                                        required
                                                        error={!!deliveredBeforeDate}
                                                        className={'date-input'}
                                            >
                                                <label>Date</label>
                                                <div className={'date-section'}>
                                                    <DatePicker
                                                        selectsStart
                                                        showPreviousMonths={false}
                                                        placeholder={'Select a date'}
                                                        dateFormat="Pp"
                                                        // timeFormat="HH:mm:ss"
                                                        //showTimeSelect
                                                        //timeIntervals={60}
                                                        minDate={moment().toDate()}
                                                        selected={request.deliveredBeforeDate2 ? new Date(request.deliveredBeforeDate2) : ""}
                                                        onChange={(event, data) => this.handleDateChanged(event, data, "deliveredBeforeDate2")}
                                                        isClearable={false}
                                                        useWeekdaysShort={true}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        //showTimeInput
                                                        dropdownMode="select"
                                                        //timeCaption={'time'}
                                                        value={request.deliveredBeforeDate2 ? Helper.timeStampToDateTimeAsString(request.deliveredBeforeDate2.toString(), false) : ''}
                                                        className={'datepicker-text'}
                                                    />
                                                </div>
                                                {deliveredBeforeDate}
                                            </Form.Field>
                                            <Form.Field inline className={'show-time-field'}>
                                                {
                                                    showTime ?
                                                        <label onClick={() => this.setState({showTime: false})}>
                                                            Hide time <Icon name={'clock outline'} size={'large'}/>
                                                        </label>
                                                        :
                                                        <label onClick={() => this.setState({showTime: true})}>
                                                            Show time <Icon name={'clock outline'} size={'large'}/>
                                                        </label>
                                                }

                                            </Form.Field>
                                            <Form.Field inline
                                                        className={'time-input'}>
                                                {
                                                    showTime ?
                                                        <>
                                                            <label>Time</label>
                                                            <div className={'time-section'}>
                                                                <DatePicker
                                                                    selectsStart
                                                                    /*showPreviousMonths={false}
                                                                    placeholder={'Select a date'}
                                                                    dateFormat="Pp"*/
                                                                    timeFormat="HH:mm:ss"
                                                                    showTimeSelect={true}
                                                                    showTimeSelectOnly={true}
                                                                    timeIntervals={60}
                                                                    //minDate={moment().toDate()}
                                                                    //selected={trip.time ? trip.time : ""}
                                                                    onChange={(event, data) => this.handleDateChanged(event, data, "time")}
                                                                    isClearable={false}
                                                                    /* useWeekdaysShort={true}
                                                                     showMonthDropdown
                                                                     showYearDropdown*/
                                                                    //showTimeInput
                                                                    dropdownMode="select"
                                                                    //timeCaption={'time'}
                                                                    value={request.time ? request.time : '00:00:00'}
                                                                    className={'datepicker-text'}
                                                                />
                                                                <TimezonePicker
                                                                    value={request.deliveredBeforeDateTimezone}
                                                                    onChange={(timezone) => {
                                                                        this.changeTimezone(timezone)
                                                                    }}
                                                                    inputProps={{
                                                                        placeholder: 'Select Timezone...',
                                                                        name: 'timezone'
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </Form.Field>
                                            {
                                                request.serviceType === 3 ?
                                                    null
                                                    :
                                                    <Form.Field inline>
                                                        <Checkbox
                                                            label='Can be collected from any location in the city'
                                                            onChange={this.handleFieldChanged('collectedFromAnyLocation', 'check')}
                                                            checked={request.collectedFromAnyLocation}
                                                        />
                                                    </Form.Field>
                                            }

                                        </Segment>
                                        <Segment className={'service-section'}>
                                            <Label>Payment</Label>
                                            <Form.Field inline
                                                        required
                                                        error={!!paymentMethod}
                                            >
                                                <label>Payment method</label>
                                                <Checkbox
                                                    radio
                                                    label='Cash'
                                                    value={1}
                                                    checked={request.paymentMethod === 1}
                                                    onChange={this.handleFieldChanged("paymentMethod")}
                                                />
                                                {paymentMethod}
                                            </Form.Field>
                                            <Form.Field inline
                                                        required
                                                        error={!!estimatedDeliveryCharge}
                                                        className={'price-field'}
                                            >
                                                <label>
                                                    {request.serviceType === 3 ? 'Estimated service charge' : 'Estimated delivery charge'}
                                                </label>
                                                <Input placeholder="Estimated delivery charge"
                                                       type={'number'}
                                                       value={request.estimatedDeliveryCharge}
                                                       onChange={this.handleFieldChanged("estimatedDeliveryCharge", 'number')}/>
                                                <Header as={'h4'}>$</Header>
                                                {estimatedDeliveryCharge}
                                            </Form.Field>
                                            <Form.Field inline>
                                                <label>Shipaik commission</label>
                                                <Input placeholder="Item estimated delivery charge"
                                                       disabled
                                                       value={`${commission ? '$' + commission : '10% of delivery charge'}`}
                                                />
                                            </Form.Field>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column computer={16}>
                                        <Form.Field inline className={'btn-field'}>
                                            <Button floated='right' className={'secondary-btn'}
                                                    onClick={this.handleBack}>
                                                {"Back"}
                                            </Button>
                                            <Button floated='right' loading={loading} onClick={this.handleAction}>
                                                {isUpdate || !Helper.emptyString(requestId) ? "Update" : "Add"}
                                            </Button>
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            : null
                    }

                    <ItemDetails ref={this.detailsModalRef} props={this}/>
                </div>
            </Form>
        );
    }
}