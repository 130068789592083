import React, {Component} from 'react';

class Logout extends Component {

    componentDidMount(){
        localStorage.clear()
        this.props.history.push('/');
        window.location.reload(true)
    }
    render() {
        return (
            <div>

            </div>
        );
    }
}

export default Logout;