import React, {Component} from "react"
import CustomTable from "../../table/custom-table"

export default class Notifications extends Component{
    constructor(props){
        super(props);
        this.state = {
            items: []
        }
    }

    render() {
        return (
            <div className={"notifications-container"}>
                <CustomTable isEditableTable={true}
                             model={{
                                 title: 'Notifications',
                                 name: 'notification',
                                 actions: {
                                     view: 'getUsers',
                                     update: 'updateUser',
                                     delete: 'deleteUser'
                                 },
                                 headers: [
                                     {title: "Sender", width: 5},
                                     {title: "Trigger", width: 5},
                                     {title: "Created time", width: 6},
                                 ],
                                 schema: 'notifications',
                             }}
                             items={this.state.items}
                             {... this.props}
                />
            </div>
        );
    }
}