import AxiosApi from '../../api/axios-api';
import Helper from "../../utils/helper";

export default class RequestApi {
    constructor(props) {
        this.body = props;
    }

    initializeRequest = (body) => {
        let bodyFormData = new FormData()
        let deliveredBeforeDate = Helper.toIsoString(body.deliveredBeforeDate2, body.timezone, false);
        deliveredBeforeDate = `${deliveredBeforeDate.substring(0, 10)}T${body.time}${deliveredBeforeDate.substring(10)}`;
        bodyFormData.append('ServiceType', body.serviceType)
        bodyFormData.append('DeliverableType', body.deliverableType)
        bodyFormData.append('Name', body.itemName)
        bodyFormData.append('Url', body.url)
        bodyFormData.append('Length', body.length)
        bodyFormData.append('Width', body.width)
        bodyFormData.append('Height', body.height)
        bodyFormData.append('DimensionUnit', body.dimensionUnit)
        bodyFormData.append('Weight', body.weight)
        bodyFormData.append('WeightUnit', body.weightUnit)
        bodyFormData.append('Description', body.description)
        bodyFormData.append('EstimatedDeliveryCharge', body.estimatedDeliveryCharge)
        bodyFormData.append('EstimatedInsurance', body.estimatedInsurance)
        bodyFormData.append('PickUpAddress', body.pickUpAddress)
        bodyFormData.append('DeliverToAddress', body.deliverToAddress)
        bodyFormData.append('DeliveredBeforeDate', deliveredBeforeDate)
        bodyFormData.append('PaymentMethod', body.paymentMethod)
        bodyFormData.append('Photo', body.photoUrl)
        bodyFormData.append('Quantity', body.quantity)
        bodyFormData.append('FromCountryId', body.fromCountryId)
        bodyFormData.append('FromStateId', body.fromStateId)
        bodyFormData.append('FromCityId', body.fromCityId)
        bodyFormData.append('ToCountryId', body.toCountryId)
        bodyFormData.append('ToStateId', body.toStateId)
        bodyFormData.append('ToCityId', body.toCityId)
        bodyFormData.append('Email', body.email)
        bodyFormData.append('VerificationCode', body.verificationCode)
        bodyFormData.append('TripId', !Helper.emptyString(body.tripId)? body.tripId: "")
        bodyFormData.append('DeliveredBeforeDateTimezone', body.deliveredBeforeDateTimezone)
        bodyFormData.append('Age', body.age)
        bodyFormData.append('Nationality', body.nationalityId)
        bodyFormData.append('Languages', body.languages)
        bodyFormData.append('MedicalStatus', body.medicalStatus)
        bodyFormData.append('TravelingAloneFear', body.travelingAloneFear)
        bodyFormData.append('Diet', body.diet)
        bodyFormData.append('Needs', body.needs)
        bodyFormData.append('AirportFrom', body.airportFrom)
        bodyFormData.append('AirportTo', body.airportTo)
        bodyFormData.append('FlightNumber', body.flightNumber)
        bodyFormData.append('Species', body.species)
        bodyFormData.append('MedicalCertificate', body.medicalCertificate)
        bodyFormData.append('TransportCertificate', body.transportCertificate)
        bodyFormData.append('AdditionalAccessories', body.additionalAccessories)
        bodyFormData.append('Food', body.food)
        if(body.disabilities){
            body.disabilities.map((disability) => {
                bodyFormData.append('Disabilities', disability)
            })
        }

        return bodyFormData
}

    add = async (body) => {
        let bodyFormData = this.initializeRequest(body)


        return await AxiosApi.call(bodyFormData, '/request', 'post', true);
    }

    update = async (body) => {
        let bodyFormData = this.initializeRequest(body)
        return await AxiosApi.call(bodyFormData, `/request/${body.id}`, 'put');
    }

    getOne = async (body) => {

        return await AxiosApi.call(body, `/request/${body.id}`, 'get');
    }

    getAll = async (body) => {
        return await AxiosApi.call(body, `/request/${body.pageNumber}?fromCountryId=${body.fromCountryId}&fromStateId=${body.fromStateId}&fromCityId=${body.fromCityId}&toCountryId=${body.toCountryId}&toStateId=${body.toStateId}&toCityId=${body.toCityId}&sortByDeliveryBeforeDate=${body.sort}`, 'get');
    }

    getMine = async (body) => {
        return await AxiosApi.call(body, `/request/mine/${body.pageNumber}?fromCountryId=${body.fromCountryId}&fromStateId=${body.fromStateId}&fromCityId=${body.fromCityId}&toCountryId=${body.toCountryId}&toStateId=${body.toStateId}&toCityId=${body.toCityId}&sortByDeliveryBeforeDate=${body.sort}`, 'get');
    }

    deleteRequest = async (body) => {

        return await AxiosApi.call(body, `/request/${body.id}`, 'delete');
    }

    createQuote = async (body) => {
        return await AxiosApi.call(body, `/quote`, 'post');
    }

    quoteOperations = async (body) =>{
        return await AxiosApi.call(body, `/quote/${body.quoteId}`, 'patch');
    }

    getCountries = async (body) => {
        return await AxiosApi.call(body, `/country?filter=${body.filter}`, 'get')
    }

    getStates = async (body) => {
        return await AxiosApi.call(body, `/country/${body.countryId}/states`, 'get')
    }

    getCities = async (body) => {
        return await AxiosApi.call(body, `/state/${body.stateId}/cities`, 'get')
    }

    updateRequestStatus = async (body) => {
        return await AxiosApi.call(body, `/request/${body.requestId}`, 'patch');
    }
}