import React, {Component} from 'react';
import {Segment, Form, Input, Button, Label, Message} from "semantic-ui-react"
import {ruleRunner, run} from "../../utils/ruleRunner";
import {required} from "../../utils/rules";
import update from "immutability-helper";
import AppContext from "../../../context/app-context";
import UserApi from "../api/user-api";
import Helper from "../../utils/helper";

class AddEmail extends Component {
    static contextType = AppContext;
    fieldValidations = [
        ruleRunner("email", 'email', required),
    ];

    constructor(props) {
        super(props)
        this.state = {
            validationErrors: {},
            showErrors: false,
            showServerError: false,
            form: {
                email: "",
                verificationCode: ""
            },
            loading: false,
            codeRequested: false,
            verified: props.verified,
            btnText: 'Request code'
        }
    }

    componentDidMount() {
        this.validateState()
    }

    validateState = (serverErrorMessage = "", codeError = false) => {
        if(codeError){
            if(this.fieldValidations.length === 1){
                this.fieldValidations.push(ruleRunner("verificationCode", 'verificationCode', required));
            }
        } else {
            if(this.fieldValidations.length === 2){
                this.fieldValidations.splice(1, 1);
            }
        }
        if (serverErrorMessage) {
            this.setState({
                showServerError: true,
                success: false,
                serverMessage: serverErrorMessage
            })
        } else {
            this.setState({validationErrors: run(this.state.form, this.fieldValidations)});
        }
    };

    handleFieldChanged = (field) => {
        return (e, data) => {

            this.setState({
                form: update(this.state.form, {
                    [field]: {
                        $set: data.value
                    }
                })
            }, () => {
                if(field === 'verificationCode') {
                }
                this.validateState()
                this.props.props.updateEmailForm(field, data.value)
            })
        }
    };

    addEmail = async () => {
        this.validateState("", false)
        this.setState({showErrors: true});
        if (!Helper.isEmpty(this.state.validationErrors)) return null;
        let {form, btnText} = this.state;
        let body = {
            email: form.email,
            category: 1
        };
        let newBtnText = btnText === 'request code' ? 'Resend code' : 'Resend code';
        this.setState({loading: true});
        const response = await UserApi.requestVerificationCode(body);

        this.setState({loading: false});

        if (response.code === "SUCCESS001") {
            this.setState({
                codeRequested: true,
                success: true,
                serverMessage: response.message,
                btnText: newBtnText
            }, () => {
                //this.fieldValidations.push(ruleRunner("verificationCode", 'verificationCode', required))
                if (!this.props.props.state.showForm) {
                    this.props.props.showForm()
                }
            });
        } else {
            this.setState({
                showServerError: true,
                success: false,
                serverMessage: response.data ? response.data.message : 'Error! Please try again'
            })
        }
    };

    render() {
        let {form, loading, codeRequested, success, serverMessage, showServerError, btnText} = this.state
        const emailError = this.context.errorFor(this.state, 'email', null, true);
        const codeError = this.context.errorFor(this.state, 'verificationCode', null, true);
        return (
            <Segment className={'add-email-segment'}>
                <Label>Please add your email to continue</Label>
                <Form className={'add-email-form'}>
                    <Form.Group widths={'equal'}>
                        <Form.Field
                            required
                            error={!!emailError}>
                            <Input type="email" placeholder="Please enter you email"
                                   value={form.email}
                                   onChange={this.handleFieldChanged("email")}
                            />

                        </Form.Field>
                        {
                            codeRequested ?
                                <Form.Field
                                    required
                                    error={!!codeError}>
                                    <Input type="text" placeholder="Please enter the verification code"
                                           value={form.verificationCode}
                                           onChange={this.handleFieldChanged("verificationCode")}
                                    />

                                </Form.Field>
                                : null
                        }
                        <Form.Field>
                            {/* {
                                !codeRequested ?*/}
                            <Button
                                onClick={this.addEmail}
                                loading={loading}
                            >
                                {btnText}
                            </Button>
                            {/* : null
                            }*/}
                        </Form.Field>
                    </Form.Group>
                    <div className={'server-message'}>
                        {
                            success ?
                                <Message
                                    visible
                                    success
                                    header="Success"
                                    content={serverMessage}
                                    style={{width: "100%"}}
                                />
                                :
                                showServerError ?
                                    <Message
                                        visible
                                        error
                                        header="Error"
                                        content={serverMessage}
                                        style={{width: "50%"}}
                                    />
                                    : null
                        }
                    </div>
                </Form>
            </Segment>
        );
    }
}

export default AddEmail
