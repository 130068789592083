import React, {Component} from 'react';
import {Header, Tab, Menu} from 'semantic-ui-react'
import Trips from '../home/src/trips'
import Requests from '../requests/src/requests'

export default class LandingPage extends Component {

    constructor(props) {
        super(props);
        this.state ={
            defaultActiveIndex: 0,
        }
    }

    changeTab = (index) => {
        this.setState({
            defaultActiveIndex: index
        })
    };

    render() {
        let width = `${100 / 2}%`;
        let {defaultActiveIndex} = this.state;
        let panes = [{
            menuItem: (
                <Menu.Item onClick= {() => this.changeTab(0)} key='request_details'
                           style={{width: width, textAlign: "center", display: "block",
                               'background': defaultActiveIndex === 0? '#4d4d4d': '',
                               'borderColor': defaultActiveIndex === 0? '#4d4d4d': '',
                               'color': defaultActiveIndex === 0? 'white': ''}}>
                    <h4 className={'tab-header'}>Trips</h4>
                </Menu.Item>
            ),
            render: () => <Tab.Pane style={{border: 'none', padding: '0 0 20px 0', background: 'transparent'}}><Trips props={this} fromLanding={true} hideAction={true}/></Tab.Pane>,
        },
            {
                menuItem: (
                    <Menu.Item onClick= {() => this.changeTab(1)} key='available_trips'
                               style={{width: width, textAlign: "center", display: "block",
                                   'background': defaultActiveIndex === 1? '#4d4d4d': '',
                                   'borderColor': defaultActiveIndex === 1? '#4d4d4d': '',
                                   'color': defaultActiveIndex === 1? 'white': ''}} >
                        <h4 className={'tab-header'}>Requests</h4>
                    </Menu.Item>
                ),
                render: () => <Tab.Pane style={{border: 'none', padding: '0 0 20px 0', background: 'transparent'}}><Requests props={this} hideAction={true}/></Tab.Pane>,
            }
        ];
        return (
            <div>
                <div className={'landing-page'}>
                    <div className={'text-section'}>
                        <Header as={'h2'}>Do you have a Request and looking for delivery shorter duration, or less
                            cost?</Header>
                        <Header as={'h2'}>or with more efficient or flexible conditions, or to unsupported destination,
                            or alltogether?</Header>
                        <Header as={'h2'}>Are you Traveling and having unused luggage weight, or looking to help
                            other?</Header>
                        <Header as={'h2'}>or to make income or cover expenses while traveling, or all together?</Header>
                        <div className={'animation-section'}>
                            <Header as={'h2'}><h2 className={'app-name'}>ShipAIK</h2> is here to help!</Header>
                        </div>

                    </div>
                </div>
                <Tab menu={{secondary: true, pointing: true}} panes={panes}/>
            </div>

        );
    }
}
