import AxiosApi from '../../api/axios-api';
import Helper from "../../utils/helper";


export default class TicketsApi {
    constructor(props){
        this.body = props;
    }

    getAll = async (body) => {
        return await AxiosApi.call(body,
            `/ticket/${body.pageNumber}?category=${body.category}&status=${body.status}&priority=${body.priority}`,
            'get', false, true);
    };

    add = async (body) => {
        let bodyFormData = new FormData()
        bodyFormData.append('Category', body.category);
        bodyFormData.append('Priority', body.priority);
        bodyFormData.append('Subject', body.subject);
        bodyFormData.append('Text', body.text);
        if(!Helper.emptyString(body.attachments)){
            body.attachments.map((attachment) =>{
                bodyFormData.append('Attachment', attachment);
            })
        }

        return await AxiosApi.call(bodyFormData,
            `/ticket`, 'post', false, true);
    }

    getTicketInfo = async (body) => {
        return await AxiosApi.call(body,
            `/ticket/${body.id}`, 'get', false, true);
    }

    replyToTicket = async (body, id) => {
        return await AxiosApi.call(body,
            `/ticket/${id}/reply`, 'patch', false, true);
    }

    closeTicket = async (body, id) => {
        return await AxiosApi.call(body,
            `/ticket/${id}/status`, 'patch', false, true);
    }
 }