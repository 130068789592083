import React, {Component} from "react"
import CustomTable from "../../table/custom-table"

export default class Trips extends Component{
    constructor(props){
        super(props);
        this.headers = props.fromLanding?
            [
                {title: "Traveller", width: 2},
                {title: "Service type", width: 2},
                {title: "Delivery type", width: 2},
                {title: "From", width: 4},
                {title: "To", width: 4},
                {title: "Date", width: 2}
            ]
                :
            [
                {title: "Traveller", width: 2},
                {title: "Service type", width: 2},
                {title: "Delivery type", width: 2},
                {title: "From", width: 3},
                {title: "To", width: 3},
                {title:"Transportation", width:2},
                {title: "Date", width: 3}
            ];
        this.state = {
            items: []
        }
    }

    render() {
        return (
            <div className={"trips-container"}>
                <CustomTable isEditableTable={true}
                             model={{
                                 title: 'Trips',
                                 name: 'trip',
                                 actions: {
                                     view: 'getUsers',
                                     update: 'updateUser',
                                     delete: 'deleteUser'
                                 },
                                 headers: this.headers,
                                 schema: 'trips',
                                 updateRoute: (id) => `update/${id}`,
                             }}
                             showGridActions= {this.props.hideAction? false: true}
                             fromLanding={this.props.fromLanding? true: false}
                             items={this.state.items}
                             {... this.props}
                />
            </div>
        );
    }
}