import React, {Component} from "react"
import {
    Segment,
    Form,
    Label,
    Grid,
    Input,
    Dropdown,
    Checkbox,
    Button,
    Search,
    Header,
    Loader,
    Dimmer,
    Message,
    Icon
} from "semantic-ui-react"
import {deliverTypes, transportationTypes, serviceTypes} from "../../../utils/static-data"
import update from "immutability-helper";
import DatePicker from "react-datepicker";
import Helper from "../../../utils/helper";
import TripApi from "../../api/trip-api";
import {ruleRunner, run} from "../../../utils/ruleRunner";
import {required} from "../../../utils/rules";
import AppContext from "../../../../context/app-context";
import AddEmail from "../../../login/src/add-email";
import TimezonePicker from 'react-timezone';
import {timezones} from 'react-timezone';
import _ from 'lodash';
import moment from "moment"


export default class AddTrip extends Component {
    static contextType = AppContext;
    fieldValidations = [
        ruleRunner("deliverableType", 'deliverableType', required),
        ruleRunner("fromCountryId", 'fromCountryId', required),
        ruleRunner("fromStateId", 'fromStateId', required),
        ruleRunner("fromCityId", 'fromCityId', required),
        ruleRunner("toCountryId", 'toCountryId', required),
        ruleRunner("toStateId", 'toStateId', required),
        ruleRunner("toCityId", 'toCityId', required),
        ruleRunner("transportation", 'transportation', required),
        ruleRunner("date2", 'date', required),
        //ruleRunner("volumetricPrice", 'volumetricPrice', required),
        ruleRunner("weight", 'weight', required),
        ruleRunner("documentPrice", 'documentPrice', required),
    ];

    constructor(props) {
        super(props);
        this.tripApi = new TripApi(this);
        let isUpdate = false;
        let isLoading = false;
        let selectedTimezone = timezones.find(a => a.name === 'UTC');
        let initialTimezone = 'UTC';
        let timezone = selectedTimezone.offset;
        this.user = localStorage.getItem('accessToken');
        if (props.match.params.id) {
            isUpdate = true;
            isLoading = true;
        }

        this.state = {
            validationErrors: {},
            showErrors: false,
            isUpdate: isUpdate,
            isLoading: isLoading,
            showForm: this.user,
            loader: false,
            documentType: true,
            showTime: false,
            trip: {
                id: isUpdate ? props.match.params.id : "",
                serviceType: 1,
                deliverableType: 2, // From enum  RFC3339
                fromCountryId: "",
                fromStateId: "",
                fromCityId: "",
                toCountryId: "",
                toStateId: "",
                toCityId: "",
                transportation: 0, // From enum
                weight: 0,
                weightUnit: 2,
                weightUnitPrice: 1, // From enum
                volumetricPrice: 0,
                documentPrice: 0,
                servicePrice: 0,
                notAcceptedAnimals: '',
                date: "",
                date2: "",
                time: "00:00:00",
                collectedFromAnyLocation: true,
                deliveredToAnyLocation: true,
                email: "",
                verificationCode: "",
                timezone: timezone,
                dateTimezone: initialTimezone,
                kidsAccepted: true,
                elderlyAccepted: true,
                airportFrom: "",
                airportTo: "",
                flightNumber: ""
            }
        };
        this.addEmailRef = React.createRef();
    }

    async componentDidMount() {
        let {isUpdate, trip, documentType} = this.state;
        if (isUpdate) {
            this.setState({loader: true});
            const response = await this.tripApi.getOne({id: trip.id});

            if (response.code === 'SUCCESS000') {
                let tripObject = response.data.deliverableInfo;
                //delete response.data.deliverableInfo;
                tripObject = {...tripObject, ...response.data};
                if (tripObject.deliverableType === 1) {
                    this.fieldValidations.splice(10, 1);
                    this.fieldValidations.push(
                        ruleRunner("volumetricPrice", 'volumetricPrice', required));
                    documentType = false
                }
                if (tripObject.serviceType === 3 || tripObject.serviceType === 4) {
                    this.fieldValidations.splice(10, 1);
                    this.fieldValidations.push(
                        ruleRunner("servicePrice", 'servicePrice', required));
                }
                tripObject.fromCountryId = tripObject.from ? tripObject.from.countryId : '';
                tripObject.fromStateId = tripObject.from ? tripObject.from.stateId : '';
                tripObject.fromCityId = tripObject.from ? tripObject.from.cityId : '';
                tripObject.toCountryId = tripObject.to ? tripObject.to.countryId : '';
                tripObject.toStateId = tripObject.to ? tripObject.to.stateId : '';
                tripObject.toCityId = tripObject.to ? tripObject.to.cityId : '';
                let fromStateOptions = [];
                let fromCityOptions = [];
                let toStateOptions = [];
                let toCityOptions = [];
                tripObject.date2 = response.data.date ? response.data.date.split('T')[0] : '';
                tripObject.time = response.data.date ? response.data.date.substring(11, 19) : '';
                let selectedTimezone = timezones.find(a => a.name === tripObject.dateTimezone);
                let timezone = selectedTimezone.offset;
                tripObject.timezone = timezone;
                let fromObject = response.data.from;
                let toObject = response.data.to;
                const fromStateResponse = await this.tripApi.getStates({countryId: fromObject.countryId});
                if (fromStateResponse.code === 'SUCCESS000') {
                    fromStateOptions = fromStateResponse.data
                    const fromCityResponse = await this.tripApi.getCities({stateId: fromObject.stateId});
                    if (fromCityResponse.code === 'SUCCESS000') {
                        fromCityOptions = fromCityResponse.data
                        const toStateResponse = await this.tripApi.getStates({countryId: toObject.countryId});
                        if (toStateResponse.code === 'SUCCESS000') {
                            toStateOptions = toStateResponse.data
                            const toCityResponse = await this.tripApi.getCities({stateId: toObject.stateId});
                            if (toCityResponse.code === 'SUCCESS000') {
                                toCityOptions = toCityResponse.data
                                this.setState({
                                    trip: tripObject,
                                    fromCountryId: fromObject.countryName,
                                    fromStateId: fromObject.stateName,
                                    fromCityId: fromObject.cityName,
                                    toCountryId: toObject.countryName,
                                    toStateId: toObject.stateName,
                                    toCityId: toObject.cityName,
                                    fromStateOptions: fromStateOptions,
                                    fromCityOptions: fromCityOptions,
                                    toStateOptions: toStateOptions,
                                    toCityOptions: toCityOptions,
                                    loader: false,
                                    documentType: documentType
                                })
                            } else {
                                this.setState({loader: false})
                            }
                        } else {
                            this.setState({loader: false})
                        }

                    } else {
                        this.setState({loader: false})
                    }

                } else {
                    this.setState({loader: false})
                }


            }
        }
        this.validateState()
    }

    validateState = () => {
        this.setState({validationErrors: run(this.state.trip, this.fieldValidations)});
    };


    handleFieldChanged = (field, type = "") => {
        return (e, data) => {
            let value = type === "number" ? parseFloat(data.value) :
                type === "check" ? data.checked : data.value;
            if (type === "number") {
                if (value <= 0) {
                    value = 1
                }
            }
            let documentType = this.state.documentType
            if (field === 'deliverableType') {
                this.fieldValidations.splice(9, 4);

                switch (value) {
                    case 2 :
                        this.fieldValidations.push(
                            ruleRunner("weight", 'weight', required),
                            ruleRunner("documentPrice", 'documentPrice', required)
                        )
                        break;
                    case 1:
                        this.fieldValidations.push(
                            ruleRunner("weight", 'weight', required),
                            ruleRunner("volumetricPrice", 'volumetricPrice', required))
                        break;
                }
            }
            if (field === 'serviceType') {
                this.fieldValidations.splice(9, 4);

                switch (value) {
                    case 1:
                        if (this.state.trip.deliverableType === 1) {
                            this.fieldValidations.push(
                                ruleRunner("weight", 'weight', required),
                                ruleRunner("volumetricPrice", 'volumetricPrice', required))
                        } else {
                            this.fieldValidations.push(
                                ruleRunner("weight", 'weight', required),
                                ruleRunner("documentPrice", 'documentPrice', required))

                        }
                        break;
                    case 3:
                        this.fieldValidations.push(
                            ruleRunner("airportFrom", 'airportFrom', required),
                            ruleRunner("airportTo", 'airportTo', required),
                            ruleRunner("flightNumber", 'flightNumber', required),
                            ruleRunner("servicePrice", 'servicePrice', required))
                        break;
                    case 4:

                        this.fieldValidations.push(
                            //ruleRunner("notAcceptedAnimals", 'notAcceptedAnimals', required),
                            ruleRunner("servicePrice", 'servicePrice', required))
                        break;
                }
            }

            this.setState({
                trip: update(this.state.trip, {
                    [field]: {
                        $set: value
                    }
                }), documentType: documentType
            }, this.validateState)
        }

    };

    handleDateChanged = (event, data, field) => {
        let newEvent = field === 'time' ? Helper.timeStampToDateTimeAsString(event, true, true)
            : Helper.timeStampToDateTimeAsString(event, false);

        this.setState({
            trip: update(this.state.trip, {
                [field]: {
                    $set: field === 'time' ? newEvent : event
                }
            })
        }, this.validateState)
    };

    showErrorMessage = (errorObject = null) => {
        if (Helper.isEmpty(errorObject)) {
            this.setState({
                serverError: true,
                loaded: false,
                serverErrorMessage: 'errorPleaseTryAgain'
            })
        } else {
            this.setState({
                serverError: true,
                loaded: false,
                serverErrorMessage: errorObject.serverErrorMessage,
                validationErrors: errorObject.validationErrors
            })
        }
    };

    showForm = () => {
        let {showForm} = this.state;
        this.setState({
            showForm: !showForm
        })
    };

    updateEmailForm = (field, value) => {
        this.setState({
            trip: update(this.state.trip, {
                [field]: {
                    $set: value
                }
            })
        }, this.validateState)
    };

    handleAction = async () => {
        this.setState({showErrors: true});
        if (this.addEmailRef.current) {
            this.addEmailRef.current.validateState("", true)
        }

        if (!Helper.isEmpty(this.state.validationErrors)) return null;

        const {trip, isUpdate} = this.state;
        const requestBody = trip;
        this.setState({loading: true});
                const response = isUpdate ? await this.tripApi.update(requestBody) : await this.tripApi.add(requestBody);
        if (response.code === 'SUCCESS000') {
            this.props.notify(false, isUpdate ? 'Updated successfully' : 'Added successfully');
            this.props.history.push(`/trips/${this.props.location.state ?
                this.props.location.state.pageNumber.pageNumber : 1}`);
        } else {
            let errorData = response.data;
            if (!Helper.isEmpty(errorData)) {
                if (Helper.isArray(errorData)) {
                    let errorObject = {
                        serverError: true,
                        validationErrors: {},
                    };
                    errorData.map((error) => {
                        let serverValidationError = Helper.errorObjectFormater(error);
                        if (!Helper.isEmpty(serverValidationError)) {
                            errorObject = {
                                serverError: true,
                                validationErrors: errorObject.validationErrors ? {...errorObject.validationErrors, ...serverValidationError} : {}
                            };
                        }
                    });
                    this.showErrorMessage(errorObject)
                } else {
                    if (errorData.code === "USER009") {
                        this.addEmailRef.current.validateState(errorData.message, true)
                    } else {
                        let serverValidationError = Helper.errorObjectFormater(errorData);
                        if (!Helper.isEmpty(serverValidationError)) {
                            let errorObject = {
                                serverError: true,
                                validationErrors: serverValidationError,
                            };
                            this.showErrorMessage(errorObject)
                        }
                    }
                }

            } else {
                this.props.notify(true, 'Error, please try again!')
            }
        }
        this.setState({loading: false});
    };

    handleStateChanged = async (e, {value}, field, options, loader, type) => {
        let {fromStateId, fromCityId, toStateId, toCityId} = this.state;
        this.setState(
            {[field]: value, [loader]: true}
            , async () => {
                let stateOptions = [];
                switch (type) {
                    case 'country':
                        const response = await this.tripApi.getCountries({filter: value});
                        if (response.data) {
                            stateOptions = response.data;
                            this.setState({
                                [loader]: false,
                                [options]: stateOptions,
                                fromStateId: field === 'fromCountryId' ? '' : fromStateId,
                                fromCityId: field === 'fromCountryId' ? '' : fromCityId,
                                toStateId: field === 'toCountryId' ? '' : toStateId,
                                toCityId: field === 'toCountryId' ? '' : toCityId,
                            }, this.validateState)
                        } else {
                            this.setState({
                                [loader]: false,
                                [options]: []
                            }, this.validateState)
                        }
                        break;
                    case 'state':
                        const stateExp = new RegExp(value, 'i');
                        const isMatchStateName = result => {
                            let searchRes = false
                            if (!searchRes) {
                                if (!searchRes && result.name) {
                                    searchRes = stateExp.test(result.name)
                                }
                            }
                            return searchRes
                        }

                        let fullStateList = field === 'fromStateId' ? this.state.fromStateFullOptions : this.state.toStateFullOptions
                        let stateResults = _.filter(fullStateList, isMatchStateName);
                        this.setState({
                            [options]: stateResults,
                            [loader]: false,
                            fromCityId: field === 'fromStateId' ? '' : fromCityId,
                            toCityId: field === 'toStateId' ? '' : toCityId,
                        }, this.validateState)
                        break;
                    case 'city':
                        const cityExp = new RegExp(value, 'i');
                        const isMatchName = result => {
                            let searchRes = false
                            if (!searchRes) {
                                if (!searchRes && result.name) {
                                    searchRes = cityExp.test(result.name)
                                }
                            }
                            return searchRes
                        }

                        let fullCityList = field === 'fromCityId' ? this.state.fromCityFullOptions : this.state.toCityFullOptions
                        let cityResults = _.filter(fullCityList, isMatchName);
                        this.setState({
                            [options]: cityResults,
                            [loader]: false
                        }, this.validateState)
                        break;
                }

            })
    };

    renderSearchResult = (result) => {
        return (result.name)
    };

    handleResultSelect = async (e, {result}, field, type) => {
        let stateOptions = [];
        let {
            fromStateOptions, fromCityOptions, fromStateFullOptions, toStateFullOptions,
            fromCityFullOptions, toCityFullOptions, toStateOptions, toCityOptions
        } = this.state
        switch (type) {
            case 'country':
                const response = await this.tripApi.getStates({countryId: result.id});
                if (response.data) {
                    stateOptions = response.data;
                    this.setState({
                        trip: update(this.state.trip, {
                            [field]: {
                                $set: result.id
                            }
                        }),
                        [field]: result.name,
                        fromStateFullOptions: field === 'fromCountryId' ? stateOptions : fromStateFullOptions,
                        toStateFullOptions: field === 'toCountryId' ? stateOptions : toStateFullOptions,
                        fromStateOptions: field === 'fromCountryId' ? stateOptions : fromStateOptions,
                        toStateOptions: field === 'toCountryId' ? stateOptions : toStateOptions,
                    }, () => {
                        this.validateState()
                    })
                } else {
                    this.setState({
                        fromStateFullOptions: field === 'fromCountryId' ? [] : fromStateFullOptions,
                        toStateFullOptions: field === 'fromCountryId' ? [] : fromStateOptions,
                        fromStateOptions: field === 'fromCountryId' ? [] : fromStateOptions,
                        toStateOptions: field === 'toCountryId' ? [] : toStateOptions,
                    }, this.validateState)
                }
                break;
            case 'state':
                const citiesResponse = await this.tripApi.getCities({stateId: result.id});
                if (citiesResponse.data) {
                    stateOptions = citiesResponse.data;
                    this.setState({
                        trip: update(this.state.trip, {
                            [field]: {
                                $set: result.id
                            }
                        }),
                        [field]: result.name,
                        fromCityFullOptions: field === 'fromStateId' ? stateOptions : fromCityFullOptions,
                        toCityFullOptions: field === 'toStateId' ? stateOptions : toCityFullOptions,
                        fromCityOptions: field === 'fromStateId' ? stateOptions : fromCityOptions,
                        toCityOptions: field === 'toStateId' ? stateOptions : toCityOptions,
                    }, () => {
                        this.validateState()
                    })
                } else {
                    this.setState({
                        fromCityFullOptions: field === 'fromStateId' ? [] : fromCityFullOptions,
                        toCityFullOptions: field === 'toStateId' ? [] : toCityFullOptions,
                        fromCityOptions: field === 'fromStateId' ? [] : fromCityOptions,
                        toCityOptions: field === 'toCityId' ? [] : toCityOptions,
                    }, this.validateState)
                }
                break;
            case 'city':
                this.setState({
                    trip: update(this.state.trip, {
                        [field]: {
                            $set: result.id
                        }
                    }),
                    [field]: result.name,
                }, this.validateState);
                break;
        }

    };

    changeTimezone = (timezone) => {
        let selectedTimezone = timezones.find(a => a.name === timezone);
        this.setState({
            trip: update(this.state.trip, {
                timezone: {
                    $set: selectedTimezone.offset
                }, dateTimezone: {
                    $set: timezone
                }
            })
            , timezone: timezone
        }, () => {
            this.validateState()
        })
    };

    handleBack = () => {
        if (this.props.history) {
            this.props.history.goBack();
        }
    };

    render() {

        let {
            trip, loading, isUpdate, fromCountryOptions, fromStateOptions, fromCityOptions,
            fromCountryLoader, fromStateLoader, fromCityLoader, loader, showTime,
            toCountryOptions, toStateOptions, toCityOptions, toCountryLoader, toStateLoader, toCityLoader,
            fromCountryId, fromStateId, fromCityId, toCountryId, toStateId, toCityId, showForm, timezone
        } = this.state;

        const deliverableType = this.context.errorFor(this.state, 'deliverableType', null, true);
        const fromCountryError = this.context.errorFor(this.state, 'fromCountryId', null, true);
        const fromStateError = this.context.errorFor(this.state, 'fromStateId', null, true);
        const fromCityError = this.context.errorFor(this.state, 'fromCityId', null, true);
        const toCountryError = this.context.errorFor(this.state, 'toCountryId', null, true);
        const toStateError = this.context.errorFor(this.state, 'toStateId', null, true);
        const toCityError = this.context.errorFor(this.state, 'toCityId', null, true);
        const transportation = this.context.errorFor(this.state, 'transportation', null, true);
        const weight = this.context.errorFor(this.state, 'weight', null, true);
        const volumetricPrice = this.context.errorFor(this.state, 'volumetricPrice', null, true);
        const documentPrice = this.context.errorFor(this.state, 'documentPrice', null, true);
        const servicePrice = this.context.errorFor(this.state, 'servicePrice', null, true);
        const date = this.context.errorFor(this.state, 'date2', null, true);

        const airportFromError = this.context.errorFor(this.state, 'airportFrom', null, true);
        const airportToError = this.context.errorFor(this.state, 'airportTo', null, true);
        const flightNumberError = this.context.errorFor(this.state, 'flightNumber', null, true);

        return (
            <Form className={'add-form'}>
                <Dimmer active={loader}>
                    <Loader active={loader} size={'small'}/>
                </Dimmer>
                <Segment className={"add-form-section trip-form"}>
                    {
                        isUpdate ?
                            <Message
                                visible
                                warning
                                header={<><Icon name={'warning sign'}/> Warning</>}
                                content={'If the from, to, date and delivery type fields has been updated, all your quotes will be revoked!'}
                                style={{width: "100%", marginBottom: '30px'}}
                            />
                            : null
                    }


                    {
                        Helper.emptyString(this.user) ?
                            <AddEmail ref={this.addEmailRef} props={this}/>
                            : null
                    }
                    {
                        showForm ?
                            <Grid columns={2} stackable textAlign='left'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Segment className={'service-section'}>
                                            <Label>Service provided</Label>
                                            <Form.Field inline
                                                        className={'service-type-field'}
                                            >
                                                <label>Service type</label>
                                                <Dropdown
                                                    search
                                                    fluid
                                                    selection
                                                    options={serviceTypes}
                                                    selectOnBlur={false}
                                                    value={trip.serviceType}
                                                    placeholder={'select'}
                                                    onChange={this.handleFieldChanged('serviceType')}
                                                />
                                            </Form.Field>
                                            {
                                                trip.serviceType === 3 || trip.serviceType === 4 ?
                                                    null :
                                                    <Form.Field inline
                                                                required
                                                                error={!!deliverableType}
                                                    >
                                                        <label>Delivery type</label>
                                                        <Dropdown
                                                            search
                                                            fluid
                                                            selection
                                                            options={deliverTypes}
                                                            selectOnBlur={false}
                                                            value={trip.deliverableType}
                                                            placeholder={'Select'}
                                                            onChange={this.handleFieldChanged('deliverableType')}
                                                        />
                                                        {deliverableType}
                                                    </Form.Field>
                                            }
                                        </Segment>
                                        <Segment className={'carriage-details-section'}>
                                            <Label>{`${trip.serviceType === 4 || trip.serviceType === 3? 'Service details' : 'Carriage details'}`}</Label>
                                            {
                                                trip.serviceType === 4 ?
                                                    <Form.Field inline>
                                                        <label>Not accepted animals</label>
                                                        <Input
                                                            type={'text'}
                                                            value={trip.notAcceptedAnimals}
                                                            onChange={this.handleFieldChanged("notAcceptedAnimals")}/>

                                                    </Form.Field>
                                                    :
                                                    trip.serviceType === 3 ?
                                                        <>
                                                            <Form.Field inline>
                                                                <label>Accept escorting kids</label>
                                                                <div className={'unit-section'}>
                                                                    <Checkbox
                                                                        radio
                                                                        label='Yes'
                                                                        name='checkboxRadioGroup'
                                                                        value={true}
                                                                        checked={trip.kidsAccepted === true}
                                                                        onChange={this.handleFieldChanged("kidsAccepted")}
                                                                    />
                                                                    <Checkbox
                                                                        radio
                                                                        label='No'
                                                                        name='checkboxRadioGroup'
                                                                        value={false}
                                                                        checked={trip.kidsAccepted === false}
                                                                        onChange={this.handleFieldChanged("kidsAccepted")}
                                                                    />
                                                                </div>
                                                            </Form.Field>
                                                            <Form.Field inline>
                                                                <label>Accept escorting elderly</label>
                                                                <div className={'unit-section'}>
                                                                    <Checkbox
                                                                        radio
                                                                        label='Yes'
                                                                        name='checkboxRadioGroup2'
                                                                        value={true}
                                                                        checked={trip.elderlyAccepted === true}
                                                                        onChange={this.handleFieldChanged("elderlyAccepted")}
                                                                    />
                                                                    <Checkbox
                                                                        radio
                                                                        label='No'
                                                                        name='checkboxRadioGroup'
                                                                        value={false}
                                                                        checked={trip.elderlyAccepted === false}
                                                                        onChange={this.handleFieldChanged("elderlyAccepted")}
                                                                    />
                                                                </div>
                                                            </Form.Field>
                                                        </>
                                                        :
                                                        <Form.Field inline
                                                                    className={'weight-field'}
                                                                    required
                                                                    error={!!weight}
                                                        >
                                                            <label>Weight</label>
                                                            <Input placeholder="Weight"
                                                                   type={'number'}
                                                                   value={trip.weight}
                                                                   onChange={this.handleFieldChanged("weight", 'number')}/>
                                                            <div className={'unit-section'}>
                                                                <Checkbox
                                                                    radio
                                                                    label='Kg'
                                                                    name='checkboxRadioGroup'
                                                                    value={1}
                                                                    checked={trip.weightUnit === 1}
                                                                    onChange={this.handleFieldChanged("weightUnit")}
                                                                />
                                                                <Checkbox
                                                                    radio
                                                                    label='Lbs'
                                                                    name='checkboxRadioGroup'
                                                                    value={2}
                                                                    checked={trip.weightUnit === 2}
                                                                    onChange={this.handleFieldChanged("weightUnit")}
                                                                />
                                                            </div>
                                                            {weight}
                                                        </Form.Field>
                                            }

                                            {
                                                trip.serviceType === 3 || trip.serviceType === 4 ?
                                                    <Form.Field inline
                                                                required
                                                                error={!!servicePrice}
                                                                className={'price-field'}
                                                    >
                                                        <label>Service price</label>
                                                        <Input placeholder="Service price"
                                                               type={'number'}
                                                               value={trip.servicePrice}
                                                               onChange={this.handleFieldChanged("servicePrice", "number")}/>
                                                        <Header as={'h4'}>$</Header>
                                                        {servicePrice}
                                                    </Form.Field>
                                                    :
                                                    trip.deliverableType === 2 ?
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!documentPrice}
                                                                    className={'price-field'}
                                                        >
                                                            <label>Price for document</label>
                                                            <Input placeholder="Price for document"
                                                                   type={'number'}
                                                                   value={trip.documentPrice}
                                                                   onChange={this.handleFieldChanged("documentPrice", "number")}/>
                                                            <Header as={'h4'}>$</Header>
                                                            {documentPrice}
                                                        </Form.Field>
                                                        : trip.deliverableType === 1 ?
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!volumetricPrice}
                                                                    className={'price-field'}
                                                        >
                                                            <label>Price for volumetric</label>
                                                            <Input placeholder="Price for volumetric"
                                                                   type={'number'}
                                                                   value={trip.volumetricPrice}
                                                                   onChange={this.handleFieldChanged("volumetricPrice", "number")}/>
                                                            <Header as={'h4'}>$</Header>
                                                            {volumetricPrice}
                                                        </Form.Field>
                                                        : null
                                            }
                                            <Form.Field inline>
                                                <Checkbox
                                                    label='Can be collected from any location in the city'
                                                    onChange={this.handleFieldChanged('collectedFromAnyLocation', 'check')}
                                                    checked={trip.collectedFromAnyLocation}
                                                />
                                            </Form.Field>
                                            <Form.Field inline>
                                                <Checkbox
                                                    label='Can be delivered to any location in the destination city'
                                                    onChange={this.handleFieldChanged('deliveredToAnyLocation', 'check')}
                                                    checked={trip.deliveredToAnyLocation}
                                                />
                                            </Form.Field>
                                        </Segment>

                                    </Grid.Column>
                                    <Grid.Column>
                                        <Segment className={'travel-details-section'}>
                                            <Label>Travel details</Label>

                                            <Form.Field className={'location-section'}
                                                        inline
                                                        required
                                                        error={!!fromStateError || !!fromCountryError || !!fromCityError}
                                            >

                                                <label>From</label>
                                                <Search
                                                    className={`${!fromCountryError ? 'normal' : ''}`}
                                                    loading={fromCountryLoader}
                                                    placeholder={'Search for country'}
                                                    onResultSelect={(event, data) => this.handleResultSelect(event, data, 'fromCountryId', 'country')}
                                                    onSearchChange={(event, data) => {
                                                        this.handleStateChanged(event, data, 'fromCountryId',
                                                            'fromCountryOptions', 'fromCountryLoader', 'country')
                                                    }}
                                                    results={fromCountryOptions}
                                                    value={fromCountryId}
                                                    resultRenderer={(result) => this.renderSearchResult(result)}
                                                    noResultsMessage={'No result found'}
                                                />
                                                {fromCountryError}
                                                <span/>
                                                <Search
                                                    className={`${Helper.emptyString(fromCountryId) ? 'disabled' : ''} ${!fromStateError ? 'normal' : ''}`}
                                                    loading={fromStateLoader}
                                                    placeholder={'Search for state'}
                                                    onResultSelect={(event, data) => this.handleResultSelect(event, data, 'fromStateId', 'state')}
                                                    onSearchChange={(event, data) => {
                                                        this.handleStateChanged(event, data, 'fromStateId',
                                                            'fromStateOptions', 'fromStateLoader', 'state')
                                                    }}
                                                    results={fromStateOptions}
                                                    value={fromStateId}
                                                    resultRenderer={(result) => this.renderSearchResult(result)}
                                                    noResultsMessage={'No result found'}
                                                />
                                                {fromStateError}
                                                <span/>
                                                <Search
                                                    className={`${Helper.emptyString(fromStateId) ? 'disabled' : ''} ${!fromCityError ? 'normal' : ''}`}
                                                    loading={fromCityLoader}
                                                    placeholder={'Search for city'}
                                                    onResultSelect={(event, data) => this.handleResultSelect(event, data, 'fromCityId', 'city')}
                                                    onSearchChange={(event, data) => {
                                                        this.handleStateChanged(event, data, 'fromCityId',
                                                            'fromCityOptions', 'fromCityLoader', 'city')
                                                    }}
                                                    results={fromCityOptions}
                                                    value={fromCityId}
                                                    resultRenderer={(result) => this.renderSearchResult(result)}
                                                    noResultsMessage={'No result found'}
                                                />
                                                {fromCityError}
                                            </Form.Field>

                                            {
                                                trip.serviceType === 3 ?
                                                    <Form.Field inline
                                                                required
                                                                error={!!airportFromError}
                                                    >
                                                        <label>Airport(From)</label>
                                                        <Input placeholder="Form"
                                                               value={trip.airportFrom}
                                                               onChange={this.handleFieldChanged("airportFrom")}/>
                                                        {airportFromError}
                                                    </Form.Field>
                                                    : null
                                            }

                                            <Form.Field className={'location-section'}
                                                        inline
                                                        required
                                                        error={!!toCountryError || !!toStateError || !!toCityError}
                                            >
                                                <label>To</label>

                                                <Search
                                                    className={`${!toCountryError ? 'normal' : ''}`}
                                                    loading={toCountryLoader}
                                                    placeholder={'Search for country'}
                                                    onResultSelect={(event, data) => this.handleResultSelect(event, data, 'toCountryId', 'country')}
                                                    onSearchChange={(event, data) => {
                                                        this.handleStateChanged(event, data, 'toCountryId',
                                                            'toCountryOptions', 'toCountryLoader', 'country')
                                                    }}
                                                    results={toCountryOptions}
                                                    value={toCountryId}
                                                    resultRenderer={(result) => this.renderSearchResult(result)}
                                                    noResultsMessage={'No result found'}
                                                />
                                                {toCountryError}
                                                <span/>
                                                <Search
                                                    className={`${Helper.emptyString(toCountryId) ? 'disabled' : ''} ${!toStateError ? 'normal' : ''}`}
                                                    loading={toStateLoader}
                                                    placeholder={'Search for state'}
                                                    onResultSelect={(event, data) => this.handleResultSelect(event, data, 'toStateId', 'state')}
                                                    onSearchChange={(event, data) => {
                                                        this.handleStateChanged(event, data, 'toStateId',
                                                            'toStateOptions', 'toStateLoader', 'state')
                                                    }}
                                                    results={toStateOptions}
                                                    value={toStateId}
                                                    resultRenderer={(result) => this.renderSearchResult(result)}
                                                    noResultsMessage={'No result found'}
                                                />
                                                {toStateError}
                                                <span/>
                                                <Search
                                                    className={`${Helper.emptyString(toStateId) ? 'disabled' : ''} ${!toCityError ? 'normal' : ''}`}
                                                    loading={toCityLoader}
                                                    placeholder={'Search for city'}
                                                    onResultSelect={(event, data) => this.handleResultSelect(event, data, 'toCityId', 'city')}
                                                    onSearchChange={(event, data) => {
                                                        this.handleStateChanged(event, data, 'toCityId',
                                                            'toCityOptions', 'toCityLoader', 'city')
                                                    }}
                                                    results={toCityOptions}
                                                    value={toCityId}
                                                    resultRenderer={(result) => this.renderSearchResult(result)}
                                                    noResultsMessage={'No result found'}
                                                />
                                                {toCityError}
                                            </Form.Field>

                                            {
                                                trip.serviceType === 3 ?
                                                    <>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!airportToError}
                                                        >
                                                            <label>Airport(To)</label>
                                                            <Input placeholder="To"
                                                                   value={trip.airportTo}
                                                                   onChange={this.handleFieldChanged("airportTo")}/>
                                                            {airportToError}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!flightNumberError}
                                                        >
                                                            <label>Flight Number</label>
                                                            <Input placeholder="Flight Number"
                                                                   value={trip.flightNumber}
                                                                   onChange={this.handleFieldChanged("flightNumber")}/>
                                                            {flightNumberError}
                                                        </Form.Field>
                                                    </>
                                                    : null
                                            }

                                            <Form.Field inline
                                                        required
                                                        error={!!transportation}
                                            >
                                                <label>Transportation</label>
                                                <Dropdown
                                                    search
                                                    fluid
                                                    selection
                                                    options={transportationTypes}
                                                    selectOnBlur={false}
                                                    value={trip.transportation}
                                                    placeholder={'Select'}
                                                    onChange={this.handleFieldChanged('transportation')}
                                                />
                                                {transportation}
                                            </Form.Field>
                                            <Form.Field inline
                                                        required
                                                        error={!!date}
                                                        className={'date-input'}
                                            >
                                                <label>Date</label>
                                                <div className={'date-section'}>
                                                    <DatePicker
                                                        selectsStart
                                                        showPreviousMonths={false}
                                                        placeholder={'Select a date'}
                                                        dateFormat="Pp"
                                                        // timeFormat="HH:mm:ss"
                                                        //showTimeSelect
                                                        //timeIntervals={60}
                                                        minDate={moment().toDate()}
                                                        selected={trip.date2 ? new Date(trip.date2) : ""}
                                                        onChange={(event, data) => this.handleDateChanged(event, data, "date2")}
                                                        isClearable={false}
                                                        useWeekdaysShort={true}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        //showTimeInput
                                                        dropdownMode="select"
                                                        //timeCaption={'time'}
                                                        value={trip.date2 ? Helper.timeStampToDateTimeAsString(trip.date2.toString(), false) : ''}
                                                        className={'datepicker-text'}
                                                    />
                                                </div>
                                                {date}
                                            </Form.Field>
                                            <Form.Field inline className={'show-time-field'}>
                                                {
                                                    showTime ?
                                                        <label onClick={() => this.setState({showTime: false})}>
                                                            Hide time <Icon name={'clock outline'} size={'large'}/>
                                                        </label>
                                                        :
                                                        <label onClick={() => this.setState({showTime: true})}>
                                                            Show time <Icon name={'clock outline'} size={'large'}/>
                                                        </label>
                                                }

                                            </Form.Field>
                                            <Form.Field inline
                                                        className={'time-input'}>
                                                {
                                                    showTime ?
                                                        <>
                                                            <label>Time</label>
                                                            <div className={'time-section'}>
                                                                <DatePicker
                                                                    selectsStart
                                                                    /*showPreviousMonths={false}
                                                                    placeholder={'Select a date'}
                                                                    dateFormat="Pp"*/
                                                                    timeFormat="HH:mm:ss"
                                                                    showTimeSelect={true}
                                                                    showTimeSelectOnly={true}
                                                                    timeIntervals={60}
                                                                    //minDate={moment().toDate()}
                                                                    //selected={trip.time ? trip.time : ""}
                                                                    onChange={(event, data) => this.handleDateChanged(event, data, "time")}
                                                                    isClearable={false}
                                                                    /* useWeekdaysShort={true}
                                                                     showMonthDropdown
                                                                     showYearDropdown*/
                                                                    //showTimeInput
                                                                    dropdownMode="select"
                                                                    //timeCaption={'time'}
                                                                    value={trip.time ? trip.time : '00:00:00'}
                                                                    className={'datepicker-text'}
                                                                />
                                                                <TimezonePicker
                                                                    value={trip.dateTimezone}
                                                                    onChange={(timezone) => {
                                                                        this.changeTimezone(timezone)
                                                                    }}
                                                                    inputProps={{
                                                                        placeholder: 'Select Timezone...',
                                                                        name: 'timezone'
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </Form.Field>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column computer={16}>
                                        <Form.Field className={'btn-field'}>
                                            <Button floated='right' className={'secondary-btn'}
                                                    onClick={this.handleBack}>
                                                {"Back"}
                                            </Button>
                                            <Button floated='right' loading={loading} onClick={this.handleAction}>
                                                {isUpdate ? "Update" : "Add"}
                                            </Button>
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            : null
                    }
                </Segment>
            </Form>
        );
    }
}
