import AxiosApi from '../../api/axios-api';


export default class UserApi {

    static login = async (body) => {
        const {email, password} = body;
        const requestBody = {
            email,
            password
        }

        return await AxiosApi.call(requestBody, '/user/token', 'post', false, false, true);
    }

    static signUp = async (body) => {
        const {email, password} = body;
        const requestBody = {
            email,
            password
        }

        return await AxiosApi.call(requestBody, '/user', 'post', false, false, true);
    }

    static requestVerificationCode = async (body) => {
        const {email, category} = body;
        const requestBody = {
            email,
            category
        }

        return await AxiosApi.call(requestBody, '/user/verification-code', 'post', false, false, true);
    }

    static verifyEmail = async (body) => {
        const {email, code} = body;
        const requestBody = {
            email,
            code
        }
        return await AxiosApi.call(requestBody, '/user/verification-code', 'patch', false, false, true);
    }

    static resetPassword = async (body) => {
        const {email, code, newPassword} = body;
        const requestBody = {
            email,
            code,
            newPassword
        }
        return await AxiosApi.call(requestBody, '/user/password', 'patch', false, false, true);
    }
}