import React, {Component} from 'react';
import {
    Button,
    Header,
    Icon,
    Table,
    Popup,
    Loader,
    Pagination,
    Dropdown,
    Modal,
    Radio, Input,
    Form
} from "semantic-ui-react";
import {
    transportationTypes,
    requestStatuses,
    ticketCategories,
    ticketsPriorities,
    ticketStatuses,
    serviceTypes, deliverTypes
} from "../utils/static-data"
import TripApi from "../../components/home/api/trip-api"
import RequestApi from "../../components/requests/api/request-api"
import TicketsApi from "../../components/ticket/api/tickets-api"
import NotificationApi from "../../components/notification/api/notification-api"
import AppContext from "../../context/app-context";
import ItemDetails from './item-details'
import QuoteModal from './quote-modal'
import Helper from "../utils/helper"
import {toast} from 'react-toastify';
import {css} from "glamor";
import {ruleRunner, run} from "../utils/ruleRunner";
import {required} from "../utils/rules";
import update from "immutability-helper";

class CustomTable extends Component {
    static contextType = AppContext;
    fieldValidations = [
        //ruleRunner("quote", 'quote', required)
    ];

    constructor(props) {
        super(props);
        this.requestApi = new RequestApi(this);
        this.tripApi = new TripApi(this);
        this.ticketsApi = new TicketsApi(this);
        this.notificationApi = new NotificationApi(this);
        let pageNumber = props ? props.match ? props.match.params ?
            props.match.params.pageNumber ? props.match.params.pageNumber : 1 : 1 : 1 : 1;

        this.state = {
            items: props.items ? props.items : [],
            tripsItems: props.items ? props.items : [],
            totalDocs: props.totalPages ? props.totalPages : null,
            isEditableTable: props.isEditableTable,
            model: props.model,
            body: props.body ? props.body : {},
            modelApi: props.model.name === 'request' ? this.requestApi :
                props.model.name === 'ticket' ? this.ticketsApi :
                    props.model.name === 'notification' ? this.notificationApi : this.tripApi,
            headers: props.model.headers,
            isLoading: true,
            filterArray: [],
            isLoadingItems: true,
            value: '',
            selectedItem: null,
            isAllChecked: false,
            rowAction: props.rowAction ? props.rowAction : '',
            open: false,
            currentTrip: {},
            refreshKey: Helper.unique(),
            showGridActions: props.showGridActions ? props.showGridActions : false,
            hideAction: props.hideAction ? props.hideAction : false,
            getAll: true,
            selectedTripId: '',
            codeForm: {
                code: ''
            },
            validationErrors: {},
            showErrors: false,
            category: "",
            priority: "",
            status: "",
            activePage: pageNumber ? pageNumber : 1,
            sort: 1,
            sortIcon: 'sort content descending'
        };

        this.detailsModalRef = React.createRef();
        this.quoteModalRef = React.createRef();
    }

    validateState = () => {
        this.setState({validationErrors: run(this.state.codeForm, this.fieldValidations)});
    };

    removeItem = (target) => {
        this.renderDeleteModal(target.id);
        this.setState({selectedItem: null})
    };

    editItem = (target) => {
        const {model} = this.state;
        if (model.name === 'ticket') {
            let history = this.props.history ? this.props.history : this.props.props.props.history
            let activePage = {
                pageNumber: this.state.activePage ? this.state.activePage : 1
            }
            history.push({
                pathname: `/${model.schema}/view/${target.id}`,
                state: {pageNumber: activePage}
            })
        } else {
            this.props.history.push(this.props.model.updateRoute(target.id))
        }
    };

    componentDidMount() {
        this.getItems()
    }

    async getItems(all = null) {
        let {
            model, modelApi, getAll, fromCountryId,
            fromStateId, fromCityId, toCountryId, toStateId, toCityId,
            category, status, priority, activePage, sort, sortIcon
        } = this.state;

        let total;
        let items;
        let totalDocs;
        let result;

        if (this.state.items && (model.name === 'tripRequest' || model.name === 'quote')) {
            total = 1;
            items = this.state.items.map((item) => {
                let objectToRender = {
                    "id": model.name === 'tripRequest' ? item.requestId : item.id,
                    "owner": item.owner.name,
                    "price": `$${item.price}`,
                    "deliveryDate": `${item.deliveryDate ? item.deliveryDate.replace('Z', ' ').replace('T', ' ')  : ''}`,
                    "accepted": item.accepted,
                    "confirmed": item.confirmed,
                };
                if (model.name === 'tripRequest') {
                    objectToRender.id = item.quote ? item.quote.id : '';
                    objectToRender.price = `$${item.quote ? item.quote.price : 0}`;
                    objectToRender.accepted = item.quote ? item.quote.accepted : '';
                    objectToRender.confirmed = item.quote ? item.quote.confirmed : '';
                    objectToRender.currentStatus = requestStatuses.find(a => a.value === item.status) ?
                        requestStatuses.find(a => a.value === item.status).text : '';
                    objectToRender.status = item.status;
                    objectToRender.requestId = item.requestId ? item.requestId : '';

                }
                return objectToRender
            });
            activePage = 1;
            totalDocs = 1;
            this.setState({
                items: items,
                total,
                activePage,
                isLoading: false,
                isLoadingItems: false,
                totalDocs,
                getAll: !Helper.emptyString(all) ? !all : getAll
            });
        } else {
            let body = {pageNumber: this.state.activePage, sort: sort};
            let requestType = !Helper.emptyString(all) ? !all : getAll;
            if (status) {
                body.status = status
            }
            if (category) {
                body.category = category
            }
            if (priority) {
                body.priority = priority
            }
            if (fromCountryId) {
                body.fromCountryId = fromCountryId
            }
            if (fromStateId) {
                body.fromStateId = fromStateId
            }
            if (fromCityId) {
                body.fromCityId = fromCityId
            }
            if (toCountryId) {
                body.toCountryId = toCountryId
            }
            if (toStateId) {
                body.toStateId = toStateId
            }
            if (toCityId) {
                body.toCityId = toCityId
            }
            let schemaResponse = model.name === "availableTrips" ? await modelApi.getAvailableForRequest(this.state.body)
                : requestType ? await modelApi.getAll(body) : model.name === "ticket" ? await modelApi.getAll(body) : await modelApi.getMine(body);

            if (schemaResponse.code === "SUCCESS000") {
                schemaResponse = schemaResponse.data;
                switch (model.name) {
                    case 'trip':
                        result = schemaResponse.list;
                        total = schemaResponse.totalPages;
                        items = schemaResponse.list ? schemaResponse.list.map((item) => {
                            let tripToReturn = {
                                "id": item.id,
                                "traveller": item.owner.name,
                                "serviceType": item.serviceType? serviceTypes.find(a => a.value === item.serviceType)?
                                    serviceTypes.find(a => a.value === item.serviceType).text: "": "",
                                "deliverableType": item.serviceType === 3 || item.serviceType === 4? 'None': item.deliverableType? deliverTypes.find(a => a.value === item.deliverableType)?
                                        deliverTypes.find(a => a.value === item.deliverableType).text: "": "",
                                "from": `${item.from.cityName}, ${item.from.countryName}`,
                                "to": `${item.to.cityName}, ${item.to.countryName}`,
                                "ownerId": item.owner ? item.owner.id : ""
                            };
                            if (!this.props.fromLanding) {
                                tripToReturn['transportation'] = transportationTypes.find(type => type.value === item.transportation) ?
                                    transportationTypes.find(type => type.value === item.transportation).text : "";
                                tripToReturn['data'] = item.date ? item.date.replace('Z', ' ').replace('T', ' ') : '';
                                tripToReturn['status'] = item.status;
                                tripToReturn['hidden'] = item.hidden;
                            } else {
                                tripToReturn['data'] = item.date ? item.date.replace('Z', ' ').replace('T', ' ') : '';
                                tripToReturn['status'] = item.status;
                                tripToReturn['hidden'] = item.hidden;
                            }
                            return tripToReturn
                        }) : [];
                        totalDocs = schemaResponse.totalPages;
                        break;
                    case 'availableTrips':
                        result = schemaResponse.list;
                        total = schemaResponse.totalPages;
                        items = schemaResponse.list ? schemaResponse.list.map((item) => {
                            return {
                                "id": item.id,
                                [this.props.fromQuote ? 'check' : 'traveller']: item.owner.name,
                                "from": `${item.from.cityName}, ${item.from.countryName}`,
                                "to": `${item.to.cityName}, ${item.to.countryName}`,
                                "serviceType": item.serviceType? serviceTypes.find(a => a.value === item.serviceType)?
                                    serviceTypes.find(a => a.value === item.serviceType).text: "": "",
                                "deliverableType": item.serviceType === 3 || item.serviceType === 4? 'None': item.deliverableType? deliverTypes.find(a => a.value === item.deliverableType)?
                                    deliverTypes.find(a => a.value === item.deliverableType).text: "": "",
                                "date": item.date ? item.date.replace('Z', ' ').replace('T', ' ') : '',
                                "transportation": transportationTypes.find(type => type.value === item.transportation) ?
                                    transportationTypes.find(type => type.value === item.transportation).text : "",
                                "quoteRequested": item.quoteRequested,
                            }
                        }) : [];
                        totalDocs = schemaResponse.totalPages;
                        break;
                    case 'request':
                        result = schemaResponse.list;
                        total = schemaResponse.totalPages;
                        items = schemaResponse.list ? schemaResponse.list.map((item) => {
                            return {
                                "id": item.id,
                                "requester": item.owner.name,
                                "serviceType": item.serviceType? serviceTypes.find(a => a.value === item.serviceType)?
                                    serviceTypes.find(a => a.value === item.serviceType).text: "": "",
                                "deliverableType": item.serviceType === 3 || item.serviceType === 4? 'None': item.deliverableType? deliverTypes.find(a => a.value === item.deliverableType)?
                                    deliverTypes.find(a => a.value === item.deliverableType).text: "": "",
                                "from": `${item.from.cityName}, ${item.from.countryName}`,
                                "to": `${item.to.cityName}, ${item.to.countryName}`,
                                "deliveredBeforeDate": item.deliveredBeforeDate ?
                                    item.deliveredBeforeDate.replace('Z', ' ').replace('T', ' ') : '',
                                "status": item.status,
                                "ownerId": item.owner ? item.owner.id : ""
                            }
                        }) : [];
                        totalDocs = schemaResponse.totalPages;
                        break;
                    case 'ticket':
                        result = schemaResponse.list;
                        total = schemaResponse.totalPages;
                        items = schemaResponse.list ? schemaResponse.list.map((item) => {
                            return {
                                "id": item.id,
                                "owner": `${item.owner ? item.owner.name : ''}`,
                                "subject": `${item.subject}`,
                                "category": ticketCategories.find(a => a.value === item.category) ?
                                    ticketCategories.find(a => a.value === item.category).text : "",

                                "priority": ticketsPriorities.find(a => a.value === item.priority) ?
                                    ticketsPriorities.find(a => a.value === item.priority).text : "",
                                "ticketStatus": ticketStatuses.find(a => a.value === item.status) ?
                                    ticketStatuses.find(a => a.value === item.status).text : "",
                            }
                        }) : [];
                        totalDocs = schemaResponse.totalPages;
                        break;
                    case "notification":
                        result = schemaResponse.list;
                        total = schemaResponse.totalPages;
                        items = schemaResponse.list ? schemaResponse.list.map((item) => {
                            return {
                                "id": item.trigger === 1 ? item.tripId : item.trigger === 2 ? item.requestId : item.ticketId,
                                "sender": `${item.sender ? item.sender.name : ''}`,
                                "trigger": `${item.trigger === 1 ? 'Trip' : item.trigger === 2 ? 'Request' : 'Ticket'}`,
                                "createTime": item.createTime ?
                                    item.createTime.replace('Z', ' ').replace('T', ' ') : '',
                            }
                        }) : [];
                        totalDocs = schemaResponse.totalPages;
                        break;
                    default:
                        break

                }
                sortIcon = sortIcon === 'sort content descending' ? 'sort content ascending' : 'sort content descending'
                this.setState({
                    items: items,
                    total,
                    activePage,
                    isLoading: false,
                    isLoadingItems: false,
                    totalDocs,
                    getAll: !Helper.emptyString(all) ? !all : getAll,
                    result: result,
                    sortIcon: sortIcon,
                });

            } else {
                this.setState({
                    serverError: schemaResponse.error,
                    isLoading: false
                })
            }
        }

    }

    notify = (error, message, duration = 5000) =>
        (message) ? toast(
            error ?
                <div><Icon size="large" name='warning'/> {message}</div>
                : <div><Icon link name='check'/> {message}</div>,
            {
                autoClose: duration,
                className: css({
                    padding: '10px',
                    color: error ? '#912d2b !important' : 'teal !important',
                }),
                progressClassName: css({
                    background: error ? '#912d2b !important' : 'teal !important',
                }),
                // This position to determine where should the toast appear . (default top right)
                position: toast.POSITION.TOP_RIGHT,
            }) : null;

    handleDelete = async () => {
        const {model} = this.state;
        switch (model.name) {
            case 'trip':
                const response = await this.tripApi.deleteTrip({id: this.state.currentItemId});
                this.setState({
                    openDeleteModal: false,
                    currentTrip: {},
                    refreshKey: Helper.unique()
                });
                if (response.code === "SUCCESS000") {
                    this.props.notify(false, 'Deleted successfully');
                    this.setState({
                        isLoading: true,
                    });
                    this.getItems()
                } else {
                    this.props.notify(true, 'Error! Please try again')
                }
                break;
            case 'request':
                const result = await this.requestApi.deleteRequest({id: this.state.currentItemId});

                this.setState({
                    openDeleteModal: false,
                    currentTrip: {},
                    refreshKey: Helper.unique()
                });
                if (result.code === "SUCCESS000") {
                    this.props.notify(false, 'Deleted successfully');
                    this.setState({
                        isLoading: true,
                    });
                    this.getItems()
                } else {
                    this.props.notify(true, 'Error! Please try again')
                }
                break;
            default:
                break
        }
    };

    handlePagination = async (event, data) => {
        this.setState({loading: true});

        let result;
        let orders;
        let total;
        let items;
        let activePage;
        let totalDocs;
        const {
            model, modelApi, body, fromStateName, toStateName, getAll,
            fromCountryId,
            fromStateId, fromCityId, toCountryId, toStateId, toCityId, sort
        } = this.state;

        let requestBody = {pageNumber: data.activePage, sort: sort};
        let history = this.props.history ? this.props.history : this.props.props.props.history

        history.push({
            pathname: `/${model.schema}/${data.activePage}`,
        })
        this.props.match.params.pageNumber = data.activePage;
        if (fromCountryId) {
            body.fromCountryId = fromCountryId
        }
        if (fromStateId) {
            body.fromStateId = fromStateId
        }
        if (fromCityId) {
            body.fromCityId = fromCityId
        }
        if (toCountryId) {
            body.toCountryId = toCountryId
        }
        if (toStateId) {
            body.toStateId = toStateId
        }
        if (toCityId) {
            body.toCityId = toCityId
        }
        switch (model.name) {
            case 'trip':
                result = getAll ? await modelApi.getAll(requestBody) : await modelApi.getMine(requestBody);
                if (result.code === "SUCCESS000") {
                    result = result.data;
                    orders = result.list;

                    total = result.totalPages;
                    items = result.list.map((item) => {
                        let tripToReturn = {
                            "id": item.id,
                            "traveller": item.owner.name,
                            "from": `${item.from.cityName}, ${item.from.countryName}`,
                            "to": `${item.to.cityName}, ${item.to.countryName}`,
                            "ownerId": item.owner ? item.owner.id : ""
                        }
                        if (!this.props.fromLanding) {
                            tripToReturn['transportation'] = transportationTypes.find(type => type.value === item.transportation) ?
                                transportationTypes.find(type => type.value === item.transportation).text : "";
                            tripToReturn['data'] = item.date ? item.date.replace('Z', ' ').replace('T', ' ') : '';
                            tripToReturn['status'] = item.status;
                            tripToReturn['hidden'] = item.hidden;
                        } else {
                            tripToReturn['data'] = item.date ? item.date.replace('Z', ' ').replace('T', ' ') : '';
                            tripToReturn['status'] = item.status;
                            tripToReturn['hidden'] = item.hidden;
                        }
                        return tripToReturn
                    });
                    activePage = data.activePage;
                    totalDocs = result.totalPages;
                }
                break;
            case 'request':
                result = getAll ? await modelApi.getAll(requestBody) : await modelApi.getMine(requestBody);
                if (result.code === "SUCCESS000") {
                    result = result.data;
                    orders = result.list;

                    total = result.totalPages;
                    items = result.list.map((item) => {
                        return {
                            "id": item.id,
                            "requester": item.owner.name,
                            "from": `${item.from.cityName}, ${item.from.countryName}`,
                            "to": `${item.to.cityName}, ${item.to.countryName}`,
                            "itemName": item.itemName,
                            "deliveredBeforeDate": item.deliveredBeforeDate ? item.deliveredBeforeDate.replace('Z', ' ').replace('T', ' ') : '',
                            "ownerId": item.owner ? item.owner.id : ""
                        }
                    });
                    activePage = data.activePage;
                    totalDocs = result.totalPages;
                }
                break;
            case 'availableTrips':
                body.pageNumber = data.activePage;
                result = await modelApi.getAvailableForRequest(body);
                if (result.code === "SUCCESS000") {
                    result = result.data;
                    orders = result.list;

                    total = result.totalPages;
                    items = result.list.map((item) => {
                        return {
                            "id": item.id,
                            [this.props.fromQuote ? 'check' : 'traveller']: item.owner.name,
                            "from": `${item.from.cityName}, ${item.from.countryName}`,
                            "to": `${item.to.cityName}, ${item.to.countryName}`,
                            "date": item.date ? item.date.replace('Z', ' ').replace('T', ' ') : '',
                            "transportation": transportationTypes.find(type => type.value === item.transportation) ?
                                transportationTypes.find(type => type.value === item.transportation).text : "",
                            "quoteRequested": item.quoteRequested
                        }
                    });
                    activePage = data.activePage;
                    totalDocs = result.totalPages;
                }
                break;
            case 'ticket':
                body.pageNumber = data.activePage;
                result = await modelApi.getAll(body);
                if (result.code === "SUCCESS000") {
                    result = result.data;
                    orders = result.list;

                    total = result.totalPages;
                    items = result.list.map((item) => {
                        return {
                            "id": item.id,
                            "owner": `${item.owner ? item.owner.name : ''}`,
                            "subject": `${item.subject}`,
                            "category": ticketCategories.find(a => a.value === item.category) ?
                                ticketCategories.find(a => a.value === item.category).text : "",

                            "priority": ticketsPriorities.find(a => a.value === item.priority) ?
                                ticketsPriorities.find(a => a.value === item.priority).text : "",
                            "ticketStatus": ticketStatuses.find(a => a.value === item.status) ?
                                ticketStatuses.find(a => a.value === item.status).text : "",
                        }
                    });
                    activePage = data.activePage;
                    totalDocs = result.totalPages;
                }
                break;
            case 'notification':
                body.pageNumber = data.activePage;
                result = await modelApi.getAll(body);
                if (result.code === "SUCCESS000") {
                    result = result.data;
                    orders = result.list;

                    total = result.totalPages;
                    items = result.list.map((item) => {
                        return {
                            "id": item.id,
                            "sender": `${item.sender ? item.sender.name : ''}`,
                            "trigger": `${item.trigger === 1 ? 'Trip' : item.trigger === 2 ? 'Request' : 'Ticket'}`,
                            "createTime": item.createTime ?
                                item.createTime.replace('Z', ' ').replace('T', ' ') : '',
                        }
                    });
                    activePage = data.activePage;
                    totalDocs = result.totalPages;
                }
                break;
            default:
                break
        }

        this.setState({
            items,
            loading: false,
            activePage,
            total,
            totalDocs,
            result: orders
        })
    };

    sort = () => {
        let {sort} = this.state;
        sort = sort === 1 ? -1 : 1;
        this.setState({sort: sort}, this.getItems);
    };

    renderTableHeader = () => {
        const {headers, model, sortIcon} = this.state;
        return (
            <Table.Row>
                {
                    headers.map(cell => (
                        cell.title === 'Date' || cell.title === 'Delivered before date' ?
                            <Table.HeaderCell className={'sort-header'}
                                              key={Helper.unique()}
                                              width={cell.width}
                                              onClick={() => this.sort()}
                            >
                                    <span>
                                        {cell.title}
                                        <Icon name={sortIcon} size={'large'}/>
                                    </span>

                            </Table.HeaderCell>
                            : <Table.HeaderCell key={Helper.unique()} width={cell.width}>
                                {cell.title}
                            </Table.HeaderCell>))

                }

                <Table.HeaderCell
                    width={`${this.props.fromQuote || model.name === 'ticket' ? 0 : model.name === 'availableTrips' ? 2 :
                        model.name === 'tripRequest' ? 3 : model.name === 'quote' ? 2 : 1}`}
                    className={'custom-table-header-actions'}/>
            </Table.Row>
        )
    };

    showDelete = (tripId) => {
        this.setState({
            openDeleteModal: true,
            currentItemId: tripId
        })
    };

    showHideTrip = (item) => {
        this.setState({
            openRevealTripModal: true,
            currentTripId: item.id,
            currentObject: item
        })
    };

    hideDelete = () => this.setState({
        openDeleteModal: false,
        currentTrip: {}
    });

    showActiveItem = (item) => {
        this.setState({selectedItem: item})
    };

    show = (trip) => {
        this.setState({
            open: true,
            currentTrip: trip
        })
    };

    requestQuote = async () => {
        const response = await this.tripApi.createQuoteRequest(this.state.currentTrip.id, this.state.body.requestId);
        let {items, currentTrip} = this.state;
        let currentItem = items ? items.find(a => a.id === currentTrip.id) ? items.find(a => a.id === currentTrip.id) : {} : {};

        if (!Helper.isEmpty(currentItem)) {
            currentItem.quoteRequested = true
        }
        this.setState({
            open: false,
            currentTrip: {},
            items: items
        });
        if (response.code === "SUCCESS000") {
            this.notify(false, 'Operation completed successfully')
        } else {
            this.notify(true, response.data ? response.data.message : 'Error! Please try again')
        }
    };

    hide = () => this.setState({
        open: false,
        currentItemId: ""
    });

    renderQuoteModal = () => {
        return (
            <Modal style={{
                height: "fit-content",
                margin: 'auto',
            }} size={'tiny'} open={this.state.open}>
                <Modal.Header>
                    <Header as={'h3'} className={'modal-header-text'}>Confirmation</Header>
                </Modal.Header>
                <Modal.Content>
                    <p>Are you sure?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        className={'secondary-btn'}
                        onClick={this.hide}>
                        <Icon name='remove'/> No
                    </Button>
                    <Button onClick={() => this.requestQuote()}>
                        <Icon name='checkmark'/> Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    renderItemDetails = (item) => {
        let {model} = this.state;
        let id = model.name === 'tripRequest' ? item.requestId : item.id;
        let name = model.name === 'tripRequest' ? 'request' : model.name === 'quote' ? 'trip' : this.state.model.name;
        if (model.name === 'notification') {
            name = item.trigger === 1 ? 'trip' : item.trigger === 2 ? 'request' : 'ticket'
            if (item.trigger === 'Ticket') {
                this.props.history.push(`/support/view/${item.id}`);
            }
        }
        this.detailsModalRef.current.show(id, name);
    };

    renderDeleteModal = () => {
        return (
            <Modal style={{
                height: "fit-content",
                margin: 'auto',
            }} size={'tiny'} open={this.state.openDeleteModal}>
                <Modal.Header>
                    <p className={'modal-header-text'}>{'Are you sure?'}</p>
                </Modal.Header>
                <Modal.Actions>
                    <Button
                            onClick={() => this.handleDelete()}
                            icon='checkmark'
                            labelPosition='right'
                            content={'Yes'}/>
                    <Button className={'secondary-btn'} onClick={this.hideDelete}>{'No'}</Button>
                </Modal.Actions>
            </Modal>
        );
    };

    handleShowTrip = async () => {
        const {currentObject} = this.state;
        if (currentObject){
            const response =  currentObject.hidden? await this.tripApi.revealTrip({id: this.state.currentTripId}):
                await this.tripApi.hideTrip({id: this.state.currentTripId});
            this.setState({
                openRevealTripModal: false,
                currentObject: {},
                refreshKey: Helper.unique()
            });
            if (response.code === "SUCCESS000") {
                this.props.notify(false, 'Operation completed successfully');
                this.setState({
                    isLoading: true,
                });
                this.getItems()
            } else {
                this.props.notify(true, 'Error! Please try again')
            }
        }
    };

    hideShowTrip = () =>{
        this.setState({
            openRevealTripModal: false,
            currentObject: {}
        });
    }


    renderRevealTripModal = () => {
        return (
            <Modal style={{
                height: "fit-content",
                margin: 'auto',
            }} size={'tiny'} open={this.state.openRevealTripModal}>
                <Modal.Header>
                    <p className={'modal-header-text'}>{'Are you sure?'}</p>
                </Modal.Header>
                <Modal.Actions>
                    <Button
                            onClick={() => this.handleShowTrip()}
                            icon='checkmark'
                            labelPosition='right'
                            content={'Yes'}/>
                    <Button className={'secondary-btn'} onClick={this.hideShowTrip}>{'No'}</Button>
                </Modal.Actions>
            </Modal>
        );
    };

    quoteOperation = async (item) => {
        let {model, codeForm} = this.state;
        if (model.name === 'tripRequest') {
            let requestResponse;
            if (item.status < 2) {
                requestResponse = await this.requestApi.quoteOperations({
                    quoteId: item.id,
                    action: 2
                });
            } else {
                let requestBody = {
                    requestId: item.requestId,
                    status: item.status + 1,
                    code: codeForm.code ? codeForm.code : ''
                };

                requestResponse = await this.requestApi.updateRequestStatus(requestBody);
            }

            if (requestResponse.code === "SUCCESS000") {
                this.notify(false, 'Operation completed successfully')
            } else {
                this.notify(true, requestResponse.data ? requestResponse.data.message : 'Error! please try again')
            }
        } else {
            const response = await this.requestApi.quoteOperations({
                quoteId: item.id,
                action: 1
            });
            if (response.code === "SUCCESS000") {
                this.notify(false, 'Operation completed successfully')
            } else {
                this.notify(true, response.data ? response.data.message : 'Error! please try again')
            }
        }
    };

    updateTrip = async (status) => {
        let {currentItem} = this.state;
        const response = await this.tripApi.updateTripStatus({id: currentItem.id, status: status});
        this.setState({
            statusModal: false,
            currentItem: {}
        });
        if (response.code === "SUCCESS000") {

            this.notify(false, 'Operation completed successfully');
            this.setState({
                isLoading: true,
            });
            this.getItems()
        } else {
            this.notify(true, response.data ? response.data.message : 'Error! Please try again')
        }
    };

    hideStatus = () => {
        this.setState({
            statusModal: false,
            currentItem: {}
        })
    };

    renderStatusModal = () => {
        let {currentItem} = this.state;
        if (currentItem) {
            return (
                <Modal style={{
                    height: "fit-content",
                    margin: 'auto',
                }} size={'tiny'} open={this.state.statusModal}
                       closeIcon
                       onClose={() => this.hideStatus()}
                >
                    <Modal.Header>
                        <Header as={'h3'} className={'modal-header-text'}>Update status</Header>
                    </Modal.Header>
                    <Modal.Content>
                        <p>Are you sure?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            className={'secondary-btn'}
                            onClick={() => currentItem.status === 1 ? this.hideStatus() :
                                this.updateTrip(4)}>
                            <Icon name='remove'/>
                            {currentItem.status === 1 ? 'No' : 'Cancel trip'}
                        </Button>
                        <Button onClick={() => this.updateTrip(currentItem.status === 1 ? 2 : 3)}>
                            <Icon name='checkmark'/>
                            {currentItem.status === 1 ? 'Yes' : 'Complete trip'}
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        } else {
            return null
        }
    };

    updateStatus = (item) => {
        this.setState({
            statusModal: true,
            currentItem: item
        })
    };

    goToAdd = (item) => {
        let {model, result} = this.state;
        let currentItem = result.find(a => a.id === item.id)
        if (model.name === 'trip') {
            let history = this.props.history ? this.props.history : this.props.props.props.history;
            history.push({
                pathname: '/requests/add',
                state: {trip: currentItem}
            })
        } else if (model.name === 'request') {
            this.quoteModalRef.current.show(item.id);
        }
    };

    handleTripChange = (e, {value}) => {
        this.setState({selectedTripId: value}, () => {
            this.props.props.updateTripId(value)
        })
    };

    showCodeModal = (item) => {
        this.setState({
            openCodeModal: true,
            currentRequest: item
        }, () => {
            this.fieldValidations.push(
                ruleRunner("code", 'code', required)
            );
            this.validateState();
        })
    };

    handleUpdateStatus = async () => {
        let {codeForm, currentRequest, items} = this.state;
        this.setState({showErrors: true});
        if (!Helper.isEmpty(this.state.validationErrors)) return null;
        let requestBody = {
            requestId: currentRequest.status === 4 ? currentRequest.requestId : currentRequest.id,
            status: currentRequest.status + 1,
            code: codeForm.code
        };
        const response = await this.requestApi.updateRequestStatus(requestBody);
        if (response.code === "SUCCESS000") {
            let currentItem = items.find(a => a.id === currentRequest.id);
            currentItem.currentStatus = requestStatuses.find(a => a.value === currentItem.status + 1) ?
                requestStatuses.find(a => a.value === currentItem.status + 1).text : '';
            currentItem.status = currentItem.status + 1;
            this.setState({
                isLoading: true,
                openCodeModal: false,
                items: items,
                refreshKey: Helper.unique()
            }, () => {
                this.notify(false, 'Operation completed successfully');
            });
        } else {
            this.notify(true, response.data ? response.data.message : 'Error! please try again');
        }
    };


    hideCodeModal = () => this.setState({
        openCodeModal: false
    });

    handleFieldChanged = (event, data) => {
        this.setState({
            codeForm: update(this.state.codeForm, {
                code: {
                    $set: data.value
                }
            })
        }, this.validateState)
    };

    renderCodeModal = () => {
        const codeError = this.context.errorFor(this.state, 'code', null, true);
        let currentRequest = this.state.currentRequest;
        return (
            <Modal style={{
                height: "fit-content",
                margin: 'auto',
            }} size={'tiny'} open={this.state.openCodeModal}>
                <Modal.Header>
                    <Header as={'h3'} className={'modal-header-text'}>{`${currentRequest ? currentRequest.status === 4 ?
                        'Delivery code' : 'Picked Code' : ''}`}</Header>
                </Modal.Header>
                <Modal.Content>
                    <Form className={'quote-section'}>
                        <Form.Group widths={'equal'}>
                            <Form.Field inline
                                        required
                                        error={!!codeError}
                            >
                                <label>
                                    {`Please enter the ${currentRequest ? currentRequest.status === 4 ?
                                        'delivery' : 'picked' : ''} code`}</label>
                                <Input placeholder="Code"
                                       className={'quote-input'}
                                       value={!Helper.isEmpty(this.state.codeForm) ? this.state.codeForm.code : ''}
                                       onChange={(event, data) => this.handleFieldChanged(event, data)}/>
                                {codeError}
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive
                            onClick={() => this.handleUpdateStatus()}
                            icon='checkmark'
                            labelPosition='right'
                            content={'Change status'}/>
                    <Button className={'secondary-btn'} onClick={this.hideCodeModal}>{'Cancel'}</Button>
                </Modal.Actions>
            </Modal>
        );
    };

    renderRow = (item) => {
        let {model} = this.state;
        if (!item) return null;
        const keys = Object.keys(item);

        let showActions = true;
        if (localStorage.getItem('id')) {
            if (localStorage.getItem('id') === item.ownerId) {
                if (this.state.getAll) {
                    showActions = false
                }
            }
        }

        const cells = keys.map((key) => {
            if (key !== 'id' &&
                key !== 'confirmed'
                && key !== 'accepted'
                && key !== 'quoteRequested'
                && key !== 'status'
                && key !== 'hidden'
                && key !== 'requestId'
                && key !== 'ownerId')
                return (
                    <Table.Cell
                        key={Helper.unique()}
                        onClick={() => this.showActiveItem(item)}
                    >
                        {
                            key === 'check' ?
                                <Radio
                                    name='radioGroup'
                                    value={item.id}
                                    checked={this.state.selectedTripId ? this.state.selectedTripId === item.id : false}
                                    onChange={this.handleTripChange}
                                />
                                :
                                <span style={{whiteSpace: 'nowrap'}} className={`${item[key] === 'None'? 'empty-value': ''}`}>
                            {item[key]}
                        </span>
                        }

                    </Table.Cell>)
        }).filter(item => item);
        return <Table.Row key={Helper.unique()} className={`table-row-item ${item.hidden ? "with-background" : ""}`}
                          onClick={() => (this.props.rowAction !== "requestQuote" && model.name !== 'ticket')
                              ? this.renderItemDetails(item) :
                              this.props.rowAction === "requestQuote" ? null : this.editItem(item)}>
            {cells}
            <Table.Cell
                onClick={(e) =>
                    this.props.rowAction === "requestQuote" ? null : e.stopPropagation()}>
                {/*{
                    localStorage.getItem('accessToken') ?*/}
                {
                    model.name !== 'ticket'
                    && showActions
                        ?
                        <div className={'custom-table-actions'}>
                            {
                                this.props.rowAction === "requestQuote" && !this.props.fromQuote ?
                                    <Button disabled={item.quoteRequested}
                                            onClick={() => this.show(item)}>{'Request a quote'}</Button>
                                    : null
                            }
                            {
                                this.props.rowAction === "tripRequest"
                                && (item.status !== 5)
                                    ?
                                    <Button
                                        onClick={() => item.status === 4 ? this.showCodeModal(item) : this.quoteOperation(item)}
                                        disabled={item.confirmed && item.status === 2}
                                    >
                                        {
                                            `${!item.confirmed ?
                                                'Confirm' : item.confirmed && item.status === 2 ? 'Confirmed' : `Change to
                                    ${requestStatuses.find(a => a.value === item.status + 1) ?
                                                    requestStatuses.find(a => a.value === item.status + 1).text : ''}`}`
                                        }
                                    </Button>
                                    : this.props.rowAction === "quote" ?
                                    <Button onClick={() => this.quoteOperation(item)}
                                            disabled={this.props.status !== 1}
                                    >
                                        {`${item.accepted ? 'Accepted' : 'Accept'}`}
                                    </Button>
                                    : null
                            }
                            {
                                this.props.model.updateRoute && this.props.rowAction !== "requestQuote"
                                    // && !this.state.getAll
                                    ?
                                    <Popup
                                        trigger={<Icon name={'ellipsis horizontal'}
                                                       size={'small'}
                                                       link
                                        />}
                                        on={'click'}
                                        content={<div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                            {
                                                !this.state.getAll && model.name === 'trip'?
                                                        <span className={'item'}
                                                              onClick={() => this.showHideTrip(item)}>{`${item.hidden? 'Show trip': 'Hide trip'}`}</span>
                                                    : null
                                            }
                                            {
                                                localStorage.getItem('id') ?
                                                    localStorage.getItem('id') !== item.ownerId ?
                                                        <span className={'item'}
                                                              onClick={() => this.goToAdd(item)}>{model.name === 'trip' ? 'Send a new request' : 'Add a new quote'}</span>
                                                        : null
                                                    :
                                                    <span className={'item'}
                                                          onClick={() => this.goToAdd(item)}>{model.name === 'trip' ? 'Send a new request' : 'Add a new quote'}</span>
                                            }


                                            {
                                                this.props.model.updateRoute && !this.state.getAll ?
                                                    <span className={'item'}
                                                          onClick={() => this.editItem(item)}>Update</span>
                                                    :
                                                    null
                                            }
                                            {
                                                model.name === 'request' && item.status === 2 ?
                                                    <span className={'item'}
                                                          onClick={() => this.showCodeModal(item)}>Change to picked</span>
                                                    :
                                                    null
                                            }
                                            {
                                                model.name === 'trip' && (item.status === 1 || item.status === 2) && !this.state.getAll ?
                                                    <span className={'item'}
                                                          onClick={() => this.updateStatus(item)}
                                                    >
                                                        {`${item.status === 1 ? 'Change to ongoing' : item.status === 2 ? 'Complete or cancel trip' : ''}`}
                                                    </span>
                                                    :
                                                    null
                                            }

                                            {
                                                !this.state.getAll ?

                                                    <span className={'item danger-item'}
                                                          onClick={() => this.showDelete(item.id)}>Delete</span>
                                                    : null
                                            }

                                        </div>}
                                        hideOnScroll
                                        position={'bottom center'}
                                        style={{padding: '0'}}
                                        className={`table-popup ${model.name === 'trip' ? 'extra' : ''}`}
                                    />
                                    :
                                    null
                            }

                        </div>
                        : null}
                {/* : null
                }*/}


            </Table.Cell>
        </Table.Row>;
    };

    renderTableContent = () => {
        const {items} = this.state;
        return items.map((item) => this.renderRow(item))
    };

    addItem = () => {
        let {model} = this.state;

        if (model) {
            let history = this.props.history ? this.props.history : this.props.props.props.history
            let activePage = {
                pageNumber: this.state.activePage ? this.state.activePage : 1
            }
            history.push({
                pathname: `/${model.schema}/add`,
                state: {pageNumber: activePage}
            })
        }

    };

    handleStateChanged = async (e, {searchQuery}, field, options, loader, type) => {
        let {fromStateId, fromCityId, toStateId, toCityId} = this.state;
        this.setState(
            {[field]: searchQuery, [loader]: true}
            , async () => {
                let stateOptions = [];
                switch (type) {
                    case 'country':
                        const response = await this.tripApi.getCountries({filter: searchQuery});
                        if (response.data) {
                            stateOptions = response.data.map((option) => {
                                return {
                                    key: option.id,
                                    text: option.name,
                                    value: option.name,
                                }
                            });
                            this.setState({
                                [loader]: false,
                                [options]: stateOptions,
                                fromStateId: field === 'fromCountryId' ? '' : fromStateId,
                                fromCityId: field === 'fromCountryId' ? '' : fromCityId,
                                toStateId: field === 'toCountryId' ? '' : toStateId,
                                toCityId: field === 'toCountryId' ? '' : toCityId,
                            })
                        } else {
                            this.setState({
                                [loader]: false,
                                [options]: []
                            })
                        }
                        break;
                    case 'state':
                        this.setState({
                            [loader]: false,
                            fromCityId: field === 'fromStateId' ? '' : fromCityId,
                            toCityId: field === 'toStateId' ? '' : toCityId,
                        })
                        break;
                    case 'city':
                        this.setState({
                            [loader]: false
                        })
                        break;
                }

            })
    };

    showMore = () => {
        this.props.props.props.history.push(`${this.state.model.schema}/1`)
    };

    handleResultSelect = async (e, data, field, name, type) => {
        let selectedItem = data.options.find(a => a.value === data.value)
        let stateOptions = [];
        let {fromStateOptions, fromCityOptions, toStateOptions, toCityOptions} = this.state
        switch (type) {
            case 'country':
                const response = await this.tripApi.getStates({countryId: selectedItem.key});
                if (response.data) {
                    stateOptions = response.data.map((option) => {
                        return {
                            key: option.id,
                            text: option.name,
                            value: option.name,
                        }
                    });
                    this.setState({
                        [field]: selectedItem.key,
                        [name]: selectedItem.text,
                        fromStateOptions: field === 'fromCountryId' ? stateOptions : fromStateOptions,
                        toStateOptions: field === 'toCountryId' ? stateOptions : toStateOptions,
                        isLoading: true
                    }, () => {
                        this.getItems()
                    })
                } else {
                    this.setState({
                        fromStateOptions: field === 'fromCountryId' ? [] : fromStateOptions,
                        toStateOptions: field === 'toCountryId' ? [] : toStateOptions,
                    })
                }
                break;
            case 'state':
                const citiesResponse = await this.tripApi.getCities({stateId: selectedItem.key});
                if (citiesResponse.data) {
                    stateOptions = citiesResponse.data.map((option) => {
                        return {
                            key: option.id,
                            text: option.name,
                            value: option.name,
                        }
                    });
                    this.setState({
                        [field]: selectedItem.key,
                        [name]: selectedItem.text,
                        fromCityOptions: field === 'fromStateId' ? stateOptions : fromCityOptions,
                        toCityOptions: field === 'toStateId' ? stateOptions : toCityOptions,
                        isLoading: true
                    }, this.getItems)
                } else {
                    this.setState({
                        fromCityOptions: field === 'fromStateId' ? [] : fromCityOptions,
                        toCityOptions: field === 'toCityId' ? [] : toCityOptions,
                    })
                }
                break;
            case 'city':
                this.setState({
                    [field]: selectedItem.key,
                    [name]: selectedItem.text,
                    isLoading: true
                }, this.getItems);
                break;
        }

    };

    handleFieldChanged = (field, type = "") => {
        return (e, data) => {
            this.setState({
                [field]: data.value,
                isLoading: true
            }, this.getItems)
        }

    }

    render() {
        const {
            model, items, isLoading, refreshKey, toStateOptions, hideAction, fromCountryOptions,
            fromCountryName, fromCityOptions, fromCityName, toCountryOptions, toCountryName,
            toCityOptions, toCityName, showGridActions, getAll, fromStateOptions, fromStateName,
            toStateName, fromCountryId, fromStateId, toCountryId, toStateId, status, priority, category
        } = this.state;

        return (
            <div className={'table-container'} key={refreshKey}>
                {
                    showGridActions ?
                        <div>
                            <div className={'grid-button-section'}>
                                <Button className="grid-button" onClick={this.addItem}>
                                    <Icon name='plus square outline' size={'big'}/>
                                    {`New ${model.name}`}
                                </Button>
                                {
                                    localStorage.getItem('accessToken') && model.name !== 'ticket' ?
                                        <Button className="grid-button"
                                                onClick={() => this.getItems(getAll)}>
                                            <Icon name='list alternate' size={'big'}/>
                                            {`${getAll ? 'My' : 'All'} ${model.schema}`}
                                        </Button>
                                        : null
                                }

                            </div>
                        </div>
                        : null
                }
                {
                    (model.name === "request" || model.name === "trip") && showGridActions ?
                        <div className={'filter-section'}>
                            <Dropdown
                                onSearchChange={(event, data) => {
                                    this.handleStateChanged(event, data, 'fromCountryId',
                                        'fromCountryOptions', 'fromCountryLoader', 'country')
                                }}
                                options={fromCountryOptions}
                                value={fromCountryName}
                                onChange={(event, data) => this.handleResultSelect(event, data, 'fromCountryId', 'fromCountryName', 'country')}
                                button
                                className='icon'
                                floating
                                labeled
                                icon='filter'
                                search
                                text={fromCountryName ? '' : 'From country'}
                            />
                            <Dropdown
                                onSearchChange={(event, data) => {
                                    this.handleStateChanged(event, data, 'fromStateId',
                                        'fromStateOptions', 'fromStateLoader', 'state')
                                }}
                                options={fromStateOptions}
                                value={fromStateName}
                                onChange={(event, data) => this.handleResultSelect(event, data, 'fromStateId', 'fromStateName', 'state')}
                                button
                                className='icon'
                                floating
                                labeled
                                icon='filter'
                                search
                                text={fromStateName ? '' : 'From state'}
                                disabled={Helper.emptyString(fromCountryId)}
                            />
                            <Dropdown
                                onSearchChange={(event, data) => {
                                    this.handleStateChanged(event, data, 'fromCityId',
                                        'fromCityOptions', 'fromCityLoader', 'city')
                                }}
                                options={fromCityOptions}
                                value={fromCityName}
                                onChange={(event, data) => this.handleResultSelect(event, data, 'fromCityId', 'fromCityName', 'city')}
                                button
                                className='icon'
                                floating
                                labeled
                                icon='filter'
                                search
                                text={fromCityName ? '' : 'From city'}
                                disabled={Helper.emptyString(fromStateId)}
                            />
                            <Dropdown
                                onSearchChange={(event, data) => {
                                    this.handleStateChanged(event, data, 'toCountryId',
                                        'toCountryOptions', 'toCountryLoader', 'country')
                                }}
                                options={toCountryOptions}
                                value={toCountryName}
                                onChange={(event, data) => this.handleResultSelect(event, data, 'toCountryId', 'toCountryName', 'country')}
                                button
                                className='icon'
                                floating
                                labeled
                                icon='filter'
                                search
                                text={toCountryName ? '' : 'To country'}
                            />
                            <Dropdown
                                onSearchChange={(event, data) => {
                                    this.handleStateChanged(event, data, 'toStateId',
                                        'toStateOptions', 'toStateLoader', 'state')
                                }}
                                options={toStateOptions}
                                value={toStateName}
                                onChange={(event, data) => this.handleResultSelect(event, data, 'toStateId', 'toStateName', 'state')}
                                button
                                className='icon'
                                floating
                                labeled
                                icon='filter'
                                search
                                text={toStateName ? '' : 'To state'}
                                disabled={Helper.emptyString(toCountryId)}
                            />
                            <Dropdown
                                onSearchChange={(event, data) => {
                                    this.handleStateChanged(event, data, 'toCityId',
                                        'toCityOptions', 'toCityLoader', 'city')
                                }}
                                options={toCityOptions}
                                value={toCityName}
                                onChange={(event, data) => this.handleResultSelect(event, data, 'toCityId', 'toCityName', 'city')}
                                button
                                className='icon'
                                floating
                                labeled
                                icon='filter'
                                search
                                text={toCityName ? '' : 'To city'}
                                disabled={Helper.emptyString(toStateId)}
                            />
                        </div>
                        :
                        model.name === 'ticket' ?
                            <div className={'filter-section'}>
                                <Dropdown
                                    options={ticketCategories}
                                    value={category}
                                    onChange={this.handleFieldChanged('category')}
                                    button
                                    className='icon'
                                    floating
                                    labeled
                                    icon='filter'
                                    selection
                                    search
                                    fluid
                                    placeholder={'Category'}
                                />
                                <Dropdown
                                    options={ticketStatuses}
                                    value={status}
                                    onChange={this.handleFieldChanged('status')}
                                    button
                                    className='icon'
                                    floating
                                    labeled
                                    icon='filter'
                                    search
                                    selection
                                    placeholder={'Status'}
                                />
                                <Dropdown
                                    options={ticketsPriorities}
                                    value={priority}
                                    onChange={this.handleFieldChanged('priority')}
                                    button
                                    className='icon'
                                    floating
                                    labeled
                                    icon='filter'
                                    search
                                    selection
                                    placeholder={'Priority'}
                                />
                            </div>
                            :
                            null
                }

                <Table fixed unstackable selectable className={'align-right rtl'}>
                    <Table.Header fullWidth>
                        {this.renderTableHeader()}
                    </Table.Header>
                    <Table.Body>

                        {
                            isLoading ?
                                <Table.Cell className={'loader-cell'}>
                                    <Loader active size='small'/>
                                </Table.Cell>
                                :
                                <>
                                    {
                                        items && items.length ?
                                            this.renderTableContent()
                                            :
                                            <Table.Cell>
                                                <h5>No items to show</h5>
                                            </Table.Cell>


                                    }

                                    {
                                        hideAction ?
                                            null
                                            :
                                            this.state.totalDocs > 1 ?
                                                <Pagination defaultActivePage={1}
                                                            totalPages={this.state.total}
                                                            activePage={this.state.activePage}
                                                            pointing
                                                            secondary
                                                            firstItem={null}
                                                            lastItem={null}
                                                            onPageChange={this.handlePagination}/>
                                                :
                                                null
                                    }

                                </>
                        }

                    </Table.Body>
                </Table>
                {
                    hideAction ?
                        <span className={'show-more'} onClick={() => this.showMore()}>
                                        Show more
                                    </span>
                        : null
                }
                {this.renderQuoteModal()}
                {this.renderDeleteModal()}
                {this.renderStatusModal()}
                {this.renderRevealTripModal()}
                {this.renderCodeModal()}
                <ItemDetails ref={this.detailsModalRef} props={this}/>
                <QuoteModal ref={this.quoteModalRef} props={this}/>
            </div>
        );
    }
}

CustomTable.defaultProps = {
    isEditableTable: false
};

export default CustomTable;