import React, {Component} from "react"
import {
    Segment,
    Form,
    Input,
    Dropdown,
    Button,
    Loader,
    Dimmer,
    Message,
    Icon
} from "semantic-ui-react"
import {ticketCategories, ticketsPriorities} from "../../utils/static-data"
import update from "immutability-helper";
import Helper from "../../utils/helper";
import {ruleRunner, run} from "../../utils/ruleRunner";
import {required} from "../../utils/rules";
import AppContext from "../../../context/app-context";
import TicketsApi from '../api/tickets-api'


export default class AddTicket extends Component {
    static contextType = AppContext;
    fieldValidations = [
        ruleRunner("category", 'category', required),
        ruleRunner("priority", 'priority', required),
        ruleRunner("text", 'text', required),
        ruleRunner("subject", 'subject', required),
    ];

    constructor(props) {
        super(props);
        this.ticketsApi = new TicketsApi(this);
        this.state = {
            validationErrors: {},
            showErrors: false,
            loader: false,
            documentType: true,
            showTime: false,
            ticket: {
                //id: isUpdate ? props.match.params.id : "",
                category: 2,
                priority: 2,
                text: "",
                subject: "",
                attachments: []
            },
            filesName: []
        };
    }

    async componentDidMount() {
        this.validateState()
    }

    validateState = () => {
        this.setState({validationErrors: run(this.state.ticket, this.fieldValidations)});
    };


    handleFieldChanged = (field, type = "") => {
        return (e, data) => {
            let value = type === "number" ? parseFloat(data.value) :
                type === "check" ? data.checked : data.value;
            this.setState({
                ticket: update(this.state.ticket, {
                    [field]: {
                        $set: value
                    }
                })
            }, this.validateState)
        }

    };

    showErrorMessage = (errorObject = null) => {
        if (Helper.isEmpty(errorObject)) {
            this.setState({
                serverError: true,
                loaded: false,
                serverErrorMessage: 'errorPleaseTryAgain'
            })
        } else {
            this.setState({
                serverError: true,
                loaded: false,
                serverErrorMessage: errorObject.serverErrorMessage,
                validationErrors: errorObject.validationErrors
            })
        }
    };


    handleAction = async () => {
        this.setState({showErrors: true});
        if (!Helper.isEmpty(this.state.validationErrors)) return null;
        const {ticket} = this.state;
        this.setState({loader: true});
        const response = await this.ticketsApi.add(ticket);
        if (response.code === 'SUCCESS006') {
            this.props.notify(false, 'Added successfully');

            this.props.history.push(`/support/${this.props.location.state?
                this.props.location.state.pageNumber.pageNumber: 1}`);
        } else {
            let errorData = response.data;
            if (!Helper.isEmpty(errorData)) {
                if(Helper.isArray(errorData)) {
                    let errorObject = {
                        serverError: true,
                        validationErrors: {},
                    };
                    errorData.map((error) => {
                        let serverValidationError = Helper.errorObjectFormater(error);
                        if (!Helper.isEmpty(serverValidationError)) {
                            errorObject = {
                                serverError: true,
                                validationErrors: errorObject.validationErrors ? {...errorObject.validationErrors, ...serverValidationError} : {}
                            };
                        }
                    });
                    this.showErrorMessage(errorObject)
                } else{
                    let serverValidationError = Helper.errorObjectFormater(errorData);
                    if (!Helper.isEmpty(serverValidationError)) {
                        let errorObject = {
                            serverError: true,
                            validationErrors: serverValidationError,
                        };
                        this.showErrorMessage(errorObject)
                    }
                }

            } else {
                this.props.notify(true, 'Error, please try again!')
            }
        }
        this.setState({loader: false});
    };

    handleBack = () => {
        if (this.props.history) {
            this.props.history.goBack();
        }
    };

    handleImageChanged = (event, data) => {
        let file = event.target.files[0];
        if (file) {
            let {ticket, filesName} = this.state;
            if (filesName.length === 10) {
                this.props.notify(true, 'You can only upload a maximum of 10 files')
            } else {
                ticket.attachments.push(file);
                filesName.push(file.name);
                this.setState({
                    ticket: ticket,
                    filesName: filesName
                }, this.validateState)
            }
        }
    };

    deleteFile = (file) => {
        let {ticket, filesName} = this.state;
        ticket.attachments = ticket.attachments.filter(a => a.name !== file);
        filesName = filesName.filter(a => a !== file);
        this.setState({
            ticket: ticket,
            filesName: filesName
        }, this.validateState)
    };

    render() {
        let {ticket, loader, filesName} = this.state;

        const categoryError = this.context.errorFor(this.state, 'category', null, true);
        const priorityError = this.context.errorFor(this.state, 'priority', null, true);
        const textError = this.context.errorFor(this.state, 'text', null, true);
        const subjectError = this.context.errorFor(this.state, 'subject', null, true);

        return (
            <Form className={'add-ticket-form'}>
                <Segment className={"add-ticket-section"}>
                    <Form.Group widths={'equal'} inline>
                        <Form.Field inline
                                    required
                                    error={!!categoryError}
                        >
                            <label>Category</label>
                            <Dropdown
                                search
                                fluid
                                selection
                                options={ticketCategories}
                                selectOnBlur={false}
                                value={ticket.category}
                                placeholder={'Select'}
                                onChange={this.handleFieldChanged('category')}
                            />
                            {categoryError}
                        </Form.Field>
                        <Form.Field inline
                                    required
                                    error={!!priorityError}
                        >
                            <label>Priority</label>
                            <Dropdown
                                search
                                fluid
                                selection
                                options={ticketsPriorities}
                                selectOnBlur={false}
                                value={ticket.priority}
                                placeholder={'Select'}
                                onChange={this.handleFieldChanged('priority')}
                            />
                            {priorityError}
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths={'equal'} inline>
                        <Form.Field inline
                                    required
                                    error={!!textError}
                        >
                            <label>Text</label>
                            <Input placeholder="Text"
                                   className={'quote-input'}
                                   value={!Helper.isEmpty(ticket.text) ? ticket.text : ''}
                                   onChange={this.handleFieldChanged('text')}/>
                            {textError}
                        </Form.Field>
                        <Form.Field inline
                                    required
                                    error={!!subjectError}
                        >
                            <label>Subject</label>
                            <Input placeholder="Subject"
                                   className={'quote-input'}
                                   value={!Helper.isEmpty(ticket.subject) ? ticket.subject : ''}
                                   onChange={this.handleFieldChanged('subject')}/>
                            {subjectError}
                        </Form.Field>
                    </Form.Group>
                    <Form.Field className={'photo-field'} inline>
                        <label>Attachments</label>
                        <div className='wrapping-input'>
                            <button className='upload-btn' onClick={(event) => {
                                event.preventDefault();
                                this.inputRef.inputRef.current.click();
                            }}>Choose a file
                            </button>
                            {
                                filesName ?
                                    <div className={'files-names-section'}>
                                        {
                                            filesName.map((file) => {
                                                return <div className={'file-name'}>
                                        <span
                                            className='input-filename'>{file ? file : 'No file chosen'}</span>
                                                    <Icon name={'close'}
                                                          size={'small'}
                                                          onClick={() => this.deleteFile(file)}
                                                    />
                                                </div>
                                            })
                                        }
                                    </div>
                                    : null
                            }
                            {/*<div className={`${!Helper.emptyString(filesName)? 'with-top': ''} upload-section`}>
                                <Icon name={'upload'}/>
                            </div>*/}
                        </div>
                        <Input className='inputfile display-none' type="file"
                               ref={(ref) => this.inputRef = ref}
                               multiple
                               accept="image/x-png,image/gif,image/jpeg"
                               onChange={(event, data) => this.handleImageChanged(event, data)}>
                        </Input>
                    </Form.Field>
                    <Form.Field className={'btn-field'}>
                        <Button floated='right' className={'secondary-btn'}
                                onClick={this.handleBack}>
                            {"Back"}
                        </Button>
                        <Button floated='right' loading={loader} onClick={this.handleAction}>
                            {"Add"}
                        </Button>
                    </Form.Field>
                </Segment>
            </Form>
        );
    }
}
