import React, {Component} from "react"
import {Form, Checkbox, Button} from "semantic-ui-react";
import ProfileApi from "../../api/profile-api";

export default class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            emailNotificationsEnabled: props.data? props.data.emailNotificationsEnabled: false
        }
    }

    async componentDidMount() {
        const profileResponse = await ProfileApi.getProfileInfo({});
        if(profileResponse.code === "SUCCESS000"){
            this.setState({
                emailNotificationsEnabled: profileResponse.data.emailNotificationsEnabled
            })
        }
    }

    handleFieldChanged = () => {
        return (e, data) => {
            let answer = false;
            if (data.checked)
                answer = true;
            this.setState({
                emailNotificationsEnabled: answer
            })
        }
    };

    handleSubmit = async () => {
        let {emailNotificationsEnabled} = this.state;
        this.setState({loading: true});
        const response = await ProfileApi.update({emailNotificationsEnabled});
        this.setState({loading: false});
        if (response.code === 'SUCCESS005') {
            this.props.props.notify(false, 'Updated successfully')
        } else {
            this.props.props.notify(true, response.data? response.data.message: 'Error, please try again!')
        }
    };

    render() {
        let {loading, emailNotificationsEnabled} = this.state;
        return (
            <Form onSubmit={this.handleSubmit} className={'notification-form'}>
                <Form.Field inline>
                    <label>Enable email notifications</label>
                    <Checkbox toggle
                              checked={emailNotificationsEnabled}
                              onChange={this.handleFieldChanged()}
                    />
                </Form.Field>
                <Form.Field className={'btn-field'}>
                    <Button floated='right' loading={loading}>Update</Button>
                </Form.Field>
            </Form>
        );
    }
}