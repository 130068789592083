import axios from 'axios';
import Helper from '../utils/helper'

export default class AxiosApi {

static coreURL = "https://shipaik.com/api-core";
static ticketURL = "https://shipaik.com/api-ticket";
static authURL = "https://shipaik.com/api-auth";
    //static ApiURL = "http://localhost:24100";
static coreURL = "https://shipaik.com/api-core";
static ticketURL = "https://shipaik.com/api-ticket";
static ticketURL = "https://shipaik.com/api-ticket";

    static call = async (requestBody, path, method, formDataBody= false, forTicket= false, forAuth= false) => {
        let baseUrl = forTicket? this.ticketURL: forAuth? this.authURL: this.coreURL;
        let url = path ? `${baseUrl}${path}` : baseUrl;
        let accessToken = localStorage.getItem('accessToken');
        let headers = {
            "Content-Type": formDataBody? "multipart/form-data" : "application/json"
        };

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        try {
            const response = await axios[method](url
                , method === 'get' || method === 'delete'? {
                    headers: headers,
                    timeout: 120000
                } : requestBody, {
                    headers: headers,
                    timeout: 120000
                }, {crossDomain: true});

            return Helper.getResponseData(response);
        } catch (e) {
            if (e.response) {
                if(e.response.data){
                    if(e.response.data.messages[0].code === 'AUTH000'){
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('identifier');
                        localStorage.removeItem('email');
                        localStorage.removeItem('unreadNotificationsCount');

                        window.location.href = window.location.origin;
                    } else{
                        return Helper.getResponseData(e.response);
                    }
                }
                //return e.response
            } else
                return e;
        }
    }
}