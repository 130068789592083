import React, {Component} from "react"
import CustomTable from "../../table/custom-table"

export default class GetRequestAvailableTrips extends Component {
    constructor(props) {
        super(props)

        this.state = {
            body: {
                requestId: props ? props.props.state ? props.props.state.fromDetails ?
                    props.props.state.request.id :
                    props.props.state.requestId ? props.props.state.requestId : "" : "" : "",
                pageNumber: 1
            }
        }
    }


    render() {
        return (
            <div className={"available-requests-container"}>
                <CustomTable isEditableTable={true}
                             model={{
                                 title: '',
                                 name: 'availableTrips',
                                 actions: {
                                     view: 'getUsers',
                                     update: 'updateUser',
                                     delete: 'deleteUser'
                                 },
                                 headers: this.props.fromQuote ?
                                     [
                                         {title: "Check", width: 2},
                                         {title: "From", width: 3},
                                         {title: "To", width: 3},
                                         {title: "Service type", width: 2},
                                         {title: "Delivery type", width: 2},
                                         {title: "Date", width: 2},
                                         {title: "Transportation", width: 2},
                                     ]
                                     :
                                     [
                                         {title: "Traveller", width: 2},
                                         {title: "From", width: 2},
                                         {title: "To", width: 2},
                                         {title: "Service type", width: 2},
                                         {title: "Delivery type", width: 2},
                                         {title: "Date", width: 2},
                                         {title: "Transportation", width: 2},
                                     ],
                                 schema: 'trips',
                             }}
                             items={this.state.items}
                             totalPages={this.state.totalPages}
                             body={this.state.body}
                             rowAction={'requestQuote'}
                             fromQuote={this.props.fromQuote}
                             {...this.props}
                />
            </div>
        );
    }
}