import React, {Component} from 'react';
import {Header, List} from 'semantic-ui-react'

export default class FAQs extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className={'static-section'}>
                <div className={'images-section'}>
                    <img alt={'img'} src={'faq.svg'}/>
                </div>
                <div className={'text-section'}>
                    <Header as={'h2'}>What is “Volumetric Weight”?</Header>
                    <List bulleted>
                        <List.Item>
                            <Header as={'h2'}>
                                Volumetric Weight is weight measurement method whichever is the greatest will be used.
                                </Header>
                        </List.Item>
                    </List>
                    <Header as={'h2'}>How is “Volumetric Weight” is calculated?</Header>
                    <List bulleted>
                        <List.Item>
                            <Header as={'h2'}>
                                Measure and multiply the box size of parcel (Height x Width x Depth) divided by either 4000 if measurement is in centimeter or divided by 245 if measurement is in inches.
                            </Header>
                        </List.Item>
                    </List>
                </div>
            </div>

        );
    }
}
