import React, {Component} from 'react';
import {Header, List} from 'semantic-ui-react'

export default class About extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        return (
            <div className={'static-section'}>
                <div className={'images-section'}>
                    <img alt={'img'} src={'about.svg'}/>
                </div>
                <div className={'text-section'}>
                    <Header as={'h2'}>Where did the name “<h2 className={'app-name'}>ShipAIK </h2>”come from?</Header>
                    <List bulleted>
                        <List.Item>
                            <Header as={'h2'}>
                                <h2 className={'app-name'}>ShipAIK </h2>(English) stands for Ship + AIK “Assistance in Kind”
                            </Header>
                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                <h2 className={'app-name'}>ShipAIK </h2>(Arabic – Traditional) means make a wish, some return it to Aladdin and magic lantern story.
                            </Header>
                        </List.Item>
                    </List>
                    <Header as={'h2'}>Why users prefer “<h2 data-heading="ShipAIK" className={'app-name'}>ShipAIK </h2>”?</Header>
                    <Header as={'h2'}><h2 data-heading="ShipAIK" className={'app-name'}>ShipAIK </h2>is the answer for all the following:</Header>
                    <List bulleted>
                        <List.Item>
                            <Header as={'h2'}>
                                Requester:</Header>
                            <List.List>
                                <List.Item><Header as={'h2'}>Are you struggling to get items you need from overseas, or reach service that is not available somewhere else!</Header></List.Item>
                                <List.Item><Header as={'h2'}>Do you need <strong style={{fontWeight: 'bold'}}>urgent</strong> shipment in no time, that normally requires few days in rapid worldwide services!</Header></List.Item>
                                <List.Item><Header as={'h2'}>Or, are you facing difficulties get items delivered to your unsupported destination, with flexible <strong style={{fontWeight: 'bold'}}>conditions!</strong></Header></List.Item>
                                <List.Item><Header as={'h2'}>Or, do you or any of your beloved need a help <strong style={{fontWeight: 'bold'}}>service</strong> during a travel!</Header></List.Item>
                                <List.Item><Header as={'h2'}>Or, are you flexible with time; but looking for <strong style={{fontWeight: 'bold'}}>cheaper</strong> and more <strong style={{fontWeight: 'bold'}}>efficient</strong> service than anything else available!</Header></List.Item>
                            </List.List>

                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Traveler:</Header>
                            <List.List>
                                <List.Item><Header as={'h2'}>Are you willing to help others while traveling, and at the same time, make decent income, or even cover traveling expenses!</Header></List.Item>
                                <List.Item><Header as={'h2'}>Or, do you have unused luggage weight that can be utilized</Header></List.Item>
                            </List.List>

                        </List.Item>
                    </List>
                    <Header as={'h2'}>What is “<h2 className={'app-name'}>ShipAIK </h2>”?</Header>
                    <List bulleted>
                        <List.Item>
                            <Header as={'h2'}>
                                <h2 className={'app-name'}>ShipAIK </h2>is the first international social community courier service provider; <h2 className={'app-name'}>ShipAIK </h2>connects requester with travelers and vice versa
                            </Header>
                        </List.Item>
                    </List>
                </div>
            </div>

        );
    }
}
