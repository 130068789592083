import React, {Component} from 'react'
import TicketsApi from "../api/tickets-api"
import {
    Segment, List, Grid, Label, Header, Form, Input, Icon, Button, Placeholder, Dimmer, Loader
} from "semantic-ui-react"
import {ticketCategories, ticketsPriorities, ticketStatuses} from "../../utils/static-data"
import PhotoModal from "../../table/photo-modal";
import Helper from "../../utils/helper"
import {Tab} from "semantic-ui-react/dist/commonjs/modules/Tab";

export default class TicketDetails extends Component {

    constructor(props) {
        super(props);
        this.ticketsApi = new TicketsApi(this);
        this.photoModalRef = React.createRef();
        this.state = {
            id: props.match.params.id,
            replyText: "",
            loader: true
        }
    }

    getTicketDetails = async () => {
        let body = {
            id: this.state.id
        }
        const response = await this.ticketsApi.getTicketInfo(body)
        if (response.code === "SUCCESS000") {
            let ticket = response.data;
            let firstSegment = {
                category: ticketCategories.find(a => a.value === ticket.category) ?
                    ticketCategories.find(a => a.value === ticket.category).text : "",

                priority: ticketsPriorities.find(a => a.value === ticket.priority) ?
                    ticketsPriorities.find(a => a.value === ticket.priority).text : "",
                status: ticketStatuses.find(a => a.value === ticket.status) ?
                    ticketStatuses.find(a => a.value === ticket.status).text : "",
                subject: ticket.subject,
                owner: ticket.owner ? ticket.owner.name : "",
                attachments: !Helper.emptyString(ticket.attachmentUrls)? ticket.attachmentUrls: []
            };
            this.setState({
                ticket: ticket,
                firstSegment: firstSegment,
                loader: false
            })
        } else {
            this.props.notify(true, 'Error, please try again!')
        }
    }

    async componentDidMount() {
        this.getTicketDetails();
    }

    showPhotoModal = (value) => {
        this.photoModalRef.current.showModal(value);
    };

    closeTicket = async () => {
        let {ticket, id} = this.state
        let body = {
            status: ticket.status
        };
        const response = await this.ticketsApi.closeTicket(body, id);
        if (response.code === "SUCCESS000") {
            this.props.notify(false, 'Operation completed successfully!');
            this.props.history.push(`/support/${this.props.location.state ?
                this.props.location.state.pageNumber.pageNumber : 1}`);

        } else {
            this.props.notify(true, 'Error, please try again!')
        }
    }

    renderTicketInfo = () => {
        let {firstSegment, ticket} = this.state;
        let firstItemsRow = [];
        let secondItemsRow = [];
        if (firstSegment) {
            Object.entries(firstSegment).forEach((oneItem, index) => {
               // if (index === 0 || index === 2 || index === 4) {
                    firstItemsRow.push(
                        <Grid.Row>
                            <Grid.Column computer={16}>
                                <List>
                                    <List.Item>
                                        <p>{oneItem[0].charAt(0).toUpperCase() + oneItem[0].slice(1)}:</p>
                                        {
                                            oneItem[0] === 'attachments' ?
                                                <div className={'attachments-section'}>
                                                    <div className={'attachments'}>
                                                        {
                                                            oneItem[1].map((attachment, index) => {
                                                                return <a target={''}
                                                                          href={'#'}
                                                                          onClick={() => this.showPhotoModal(attachment)}
                                                                >
                                                                    {`Attachment ${index + 1}`}</a>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <p>{oneItem[1]}</p>
                                        }
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                    )
                //}
                /*else {
                    secondItemsRow.push(
                        <List>
                            <List.Item>
                                <p>{oneItem[0].charAt(0).toUpperCase() + oneItem[0].slice(1)}:</p>
                                {
                                    oneItem[0] === 'attachments' ?
                                        <div className={'attachments-section'}>
                                            <div className={'attachments'}>
                                                {
                                                    oneItem[1].map((attachment, index) => {
                                                        return <a target={''}
                                                                  href={'#'}
                                                                  onClick={() => this.showPhotoModal(attachment)}
                                                        >
                                                            {`Attachment ${index + 1}`}</a>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        :
                                        <p>{oneItem[1]}</p>
                                }
                            </List.Item>
                        </List>
                    )
                }*/
            });
            return (
                <Grid className={'info'}>
                    {firstItemsRow}
                    {/*<Grid.Row cloumns={2}>
                        <Grid.Column computer={16} tablet={8} mobile={8}>
                            {firstItemsRow}
                        </Grid.Column>
                        <Grid.Column computer={16} tablet={8} mobile={8}>
                            {secondItemsRow}
                        </Grid.Column>
                    </Grid.Row>*/}
                    {
                        ticket.status === 2 ?
                            <Grid.Row>
                                <Button onClick={() => this.closeTicket()}>
                                    Close ticket
                                </Button>
                            </Grid.Row>
                            : null

                    }
                </Grid>
            )
        }
    };

    handleFieldChanged = () => {
        return (e, data) => {
            if (data.value) {
                this.setState({
                    replyText: data.value
                })
            } else {
                this.setState({
                    replyText: ""
                })
            }
        }
    };

    reply = async () => {
        let {replyText, id, ticket} = this.state;
        if (Helper.emptyString(replyText)) {
            return null
        } else {
            ticket.replies.push({text: 'placeholder'})
            this.setState({
                ticket: ticket,
                replyText: ""
            }, async () => {
                let body = {text: replyText}
                const response = await this.ticketsApi.replyToTicket(body, id);
                if (response.code === 'SUCCESS000') {
                    this.getTicketDetails()
                }
            })
        }
    };

    renderTicketReplies = () => {
        let {ticket, replyText} = this.state;
        let replies = [];
        if (ticket) {
            ticket.replies.map((reply) => {
                let ownerReply = false
                if (reply.text !== 'placeholder') {
                    ownerReply = reply.owner.id === localStorage.getItem('id');
                }

                /*localStorage.getItem('identifier') ?
                localStorage.getItem('identifier') === reply.sender : false;*/
                replies.push(
                    <div className={`${ownerReply ||  reply.text === 'placeholder'? 'right' : ''} reply-section`}>
                        {
                            reply.text === 'placeholder' ?
                                <Placeholder className={'right'}>
                                    <Placeholder.Image/>
                                </Placeholder>
                                :
                                <>
                                    <Header as={"h3"} className={`${ownerReply ? 'right' : 'left'} reply`}>
                                        {reply.text}
                                    </Header>
                                    <p>
                                        <span>{reply.owner.name}</span>
                                        {` at ${reply.sendTime ? reply.sendTime.replace('Z', ' ').replace('T', ' ') : ''}`}
                                    </p>
                                </>
                        }
                    </div>
                )
            })
        }

        return (
            <>
                <div className={'replies-section'}>
                    {replies}
                </div>
                <Form className={'typing-section'} onSubmit={() => this.reply}>
                    <Form.Field>
                        <Input
                            placeholder={'Reply'}
                            value={replyText ? replyText : ""}
                            onChange={this.handleFieldChanged()}/>
                    </Form.Field>
                    <Form.Field>
                        <Button
                            onClick={() => this.reply()}>
                            <Icon name={'reply'} size={'large'}/>
                        </Button>
                    </Form.Field>
                </Form>
            </>
        )
    };

    render() {
        let {loader} = this.state;
        return (
            <Segment className={"ticket-details"}>
                <Grid>
                    <Grid.Row column={2}>
                        <Dimmer active={loader}>
                            <Loader active={loader} size={'small'}/>
                        </Dimmer>
                        <Grid.Column computer={5} tablet={16} mobile={16}>
                            <Segment className={'ticket-info'}>
                                <Label>Info</Label>
                                {this.renderTicketInfo()}
                            </Segment>
                        </Grid.Column>
                        <Grid.Column computer={11} tablet={16} mobile={16}>
                            <Segment className={'ticket-replies'}>
                                <Label>Replies</Label>
                                {this.renderTicketReplies()}
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <PhotoModal ref={this.photoModalRef} props={this}/>
            </Segment>
        )
    }
}
