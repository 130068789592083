import React, {Component} from 'react';
import {Tab, Menu, Loader, Icon} from 'semantic-ui-react'
import EmailPassword from "./email-password-component/email-password"
import Notifications from "./notifications-component/notifications"
import ProfileApi from '../api/profile-api'

class ProfileTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: true
        }
    }

    async componentDidMount() {
        const response = await ProfileApi.getProfileInfo({});
        if (response.code === 'SUCCESS000') {
            this.setState({
                data: response.data,
                loading: false
            })
        }
    }

    render() {
        let width = `${100 / 2}%`;
        let {loading} = this.state;
        let panes = [{
            menuItem: (
                <Menu.Item key='basic' style={{width: width, textAlign: "center"}}>
                    <h4 className={'tab-header'}>Name & password
                        <Icon style={{marginLeft: '15px'}} name={'arrow alternate circle right'}/>
                    </h4>
                </Menu.Item>
            ),
            render: () => <Tab.Pane style={{padding: loading? '30px': ''}}>
                {
                    loading ?
                        <Loader active size='small'/>
                        :
                        <EmailPassword props={this.props} data={this.state.data}/>
                }
            </Tab.Pane>,
        },
            {
                menuItem: (
                    <Menu.Item key='email_template' style={{width: width, textAlign: "center"}}>
                        <h4 className={'tab-header'}>Notifications
                            <Icon style={{marginLeft: '15px'}} name={'arrow alternate circle right'}/>
                        </h4>
                    </Menu.Item>
                ),
                render: () => <Tab.Pane><Notifications props={this.props} data={this.state.data}/></Tab.Pane>,
            }
        ];

        return (
            <div>
                <Tab
                    className={'profile-tabs'}
                    menu={{attached: false}}
                    panes={panes}/>
            </div>
        );
    }
}

export default ProfileTabs;