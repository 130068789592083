import React, {Component} from "react"
import CustomTable from "../../table/custom-table"

export default class Requests extends Component{
    constructor(props){
        super(props);
        this.state = {
            items: []
        }
    }

    addTrip = () => {
        this.props.history.push('/requests/add')
    };

    render() {
        return (
            <div className={"requests-container"}>
                <CustomTable isEditableTable={true}
                             model={{
                                 title: '',
                                 name: 'request',
                                 actions: {
                                     view: 'getUsers',
                                     update: 'updateUser',
                                     delete: 'deleteUser'
                                 },
                                 headers: [
                                     {title: "Requester", width: 3},
                                     {title: "Service type", width: 2},
                                     {title: "Delivery type", width: 2},
                                     {title: "From", width: 3},
                                     {title: "To", width: 3},
                                     {title: "Delivered before date", width: 2},
                                     ],
                                 schema: 'requests',
                                 updateRoute: (id) => `update/${id}`,
                             }}
                             showGridActions= {this.props.hideAction? false: true}
                             items={this.state.items}
                             {... this.props}
                />
            </div>
        );
    }
}