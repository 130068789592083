import React, {Component} from 'react';
import {Modal, Button, Icon, Message, Input, Segment, Form, Header, Label} from "semantic-ui-react";
import GetRequestAvailableTrips from "../requests/src/get-reuest-available-trips"
import DatePicker from "react-datepicker";
import Helper from "../utils/helper";
import TimezonePicker from 'react-timezone';
import {timezones} from 'react-timezone';
import AddEmail from "../login/src/add-email";
import {ruleRunner, run} from "../utils/ruleRunner";
import {required} from "../utils/rules";
import AppContext from "../../context/app-context";
import update from "immutability-helper";
import RequestApi from "../requests/api/request-api";
import moment from "moment"

class QuoteModal extends Component {
    static contextType = AppContext;
    fieldValidations = [];

    constructor(props) {
        super(props);
        this.requestApi = new RequestApi(this);
        let selectedTimezone = timezones.find(a => a.name === 'UTC');
        let initialTimezone = 'UTC';
        let timezone = selectedTimezone.offset;
        this.state = {
            validationErrors: {},
            showErrors: false,
            show: false,
            form: {
                date: '',
                dateTime: "00:00:00",
                dateTimezone: timezone,
                dateTimezoneValues: initialTimezone,
                verificationCode: '',
                email: '',
                quote: "",
                deliveryDate: "",
                time: "00:00:00",
                timezone: timezone,
                deliveryDateTimezone: initialTimezone,
            },
            selectedTripId: "",
            showPrice: false,
            loader: false
        }
        this.addEmailRef = React.createRef();
    }

    validateState = () => {
        this.setState({validationErrors: run(this.state.form, this.fieldValidations)});
    };

    show = (requestId) => {
        this.setState({
            show: true,
            requestId: requestId
        })
    };

    /*changeTimezone = (timezone) => {
        let selectedTimezone = timezones.find(a => a.name === timezone);
        this.setState({
            form: update(this.state.form, {
                timezone: {
                    $set: selectedTimezone.offset
                }
            })
            , timezone: timezone
        }, this.validateState)
    };

    handleDateChanged = (event, data) => {
        this.setState({
            form: update(this.state.form, {
                date: {
                    $set: event
                }
            })
        }, this.validateState)
    };*/

    handlePriceChanged = (event, {value}) => {
        this.setState({
            form: update(this.state.form, {
                quote: {
                    $set: parseFloat(value)
                }
            })
        }, this.validateState)
    };

    showForm = () => {
        let {showForm} = this.state;
        this.setState({
            showForm: !showForm
        }, () => {
            this.fieldValidations.push(
                ruleRunner("quote", 'quote', required),
                ruleRunner("deliveryDate", 'deliveryDate', required),
            );
            this.validateState();
        })
    };

    renderNewTripForm = () => {
        let {showForm, form, loader, showTime, showTimeInput} = this.state
        const deliveryDateError = this.context.errorFor(this.state, 'deliveryDate', null, true);
        const dateError = this.context.errorFor(this.state, 'date', null, true);
        const quoteError = this.context.errorFor(this.state, 'quote', null, true);
        return <div>
            <Message
                visible
                warning
                header={<><Icon name={'warning sign'}/> Attention</>}
                content={'New TRIP will be created, along with your quotation.'}
                style={{width: "100%", marginBottom: '30px', textAlign: 'center'}}
            />
            <AddEmail ref={this.addEmailRef} props={this}/>
            {
                showForm ?
                    <div>
                        <Segment className={'quotation-segment'}>
                            <Label>Quotation Details</Label>
                            <Form className={'quote-section'}>
                                <Form.Group widths={'equal'}>
                                    <Form.Field inline
                                                required
                                                error={!!quoteError}
                                    >
                                        <label>Please enter a quote</label>
                                        <Input placeholder="Quote"
                                               className={'quote-input'}
                                               type={'number'}
                                               value={form.quote}
                                               onChange={(event, data) => this.handlePriceChanged(event, data)}/>
                                        <Header as={'h4'}>$</Header>
                                        {quoteError}
                                    </Form.Field>
                                    <Form.Group widths={'equal'}>
                                        <Form.Field inline
                                                    required
                                                    error={!!dateError}
                                        >
                                            <label>Date</label>
                                            <div className={'date-section'}>
                                                <DatePicker
                                                    placeholder={'Select a date'}
                                                    dateFormat="Pp"
                                                    timeFormat="HH:mm:ss"
                                                    showTimeSelect
                                                    minDate={moment().toDate()}
                                                    timeIntervals={60}
                                                    selected={form.date ? new Date(form.date) : ""}
                                                    onChange={(event, data) => this.handleDateChanged(event, data, 'date')}
                                                    isClearable={false}
                                                    useWeekdaysShort={true}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    showTimeInput
                                                    dropdownMode="select"
                                                    timeCaption={'time'}
                                                    value={form.date ? Helper.timeStampToDateTimeAsString(form.date.toString(), false) : ''}
                                                    className={'datepicker-text'}
                                                />
                                                <Form.Field inline className={'show-time-field'}>
                                                    {
                                                        showTimeInput ?
                                                            <label onClick={() => this.setState({showTimeInput: false})}>
                                                                Hide time <Icon name={'clock outline'} size={'large'}/>
                                                            </label>
                                                            :
                                                            <label onClick={() => this.setState({showTimeInput: true})}>
                                                                Show time <Icon name={'clock outline'} size={'large'}/>
                                                            </label>
                                                    }

                                                </Form.Field>
                                                <Form.Field inline
                                                            className={'time-input'}>
                                                    {
                                                        showTimeInput ?
                                                            <>
                                                                <label>Time</label>
                                                                <div className={'time-section'}>
                                                                    <DatePicker
                                                                        selectsStart
                                                                        /*showPreviousMonths={false}
                                                                        placeholder={'Select a date'}
                                                                        dateFormat="Pp"*/
                                                                        timeFormat="HH:mm:ss"
                                                                        showTimeSelect={true}
                                                                        showTimeSelectOnly={true}
                                                                        timeIntervals={60}
                                                                        //minDate={moment().toDate()}
                                                                        //selected={trip.time ? trip.time : ""}
                                                                        onChange={(event, data) => this.handleDateChanged(event, data, "dateTime")}
                                                                        isClearable={false}
                                                                        /* useWeekdaysShort={true}
                                                                         showMonthDropdown
                                                                         showYearDropdown*/
                                                                        //showTimeInput
                                                                        dropdownMode="select"
                                                                        //timeCaption={'time'}
                                                                        value={form.dateTime ? form.dateTime : '00:00:00'}
                                                                        className={'datepicker-text'}
                                                                    />
                                                                    <TimezonePicker
                                                                        value={form.dateTimezoneValues}
                                                                        onChange={(timezone) => {
                                                                            this.changeTimezone(timezone, "dateTimezone", "dateTimezoneValues")
                                                                        }}
                                                                        inputProps={{
                                                                            placeholder: 'Select Timezone...',
                                                                            name: 'timezone'
                                                                        }}
                                                                    />
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </Form.Field>
                                            </div>
                                            {dateError}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Field inline
                                                required
                                                error={!!deliveryDateError}
                                                className={'date-input'}
                                    >
                                        <label>Delivered before date</label>
                                        <div className={'date-section'}>
                                            <DatePicker
                                                selectsStart
                                                showPreviousMonths={false}
                                                placeholder={'Select a date'}
                                                dateFormat="Pp"
                                                // timeFormat="HH:mm:ss"
                                                //showTimeSelect
                                                //timeIntervals={60}
                                                minDate={moment().toDate()}
                                                selected={form.deliveryDate ? new Date(form.deliveryDate) : ""}
                                                onChange={(event, data) => this.handleDateChanged(event, data, "deliveryDate")}
                                                isClearable={false}
                                                useWeekdaysShort={true}
                                                showMonthDropdown
                                                showYearDropdown
                                                //showTimeInput
                                                dropdownMode="select"
                                                //timeCaption={'time'}
                                                value={form.deliveryDate ? Helper.timeStampToDateTimeAsString(form.deliveryDate.toString(), false) : ''}
                                                className={'datepicker-text'}
                                            />
                                        </div>
                                        {deliveryDateError}
                                    </Form.Field>
                                    <Form.Field inline className={'show-time-field'}>
                                        {
                                            showTime ?
                                                <label onClick={() => this.setState({showTime: false})}>
                                                    Hide time <Icon name={'clock outline'} size={'large'}/>
                                                </label>
                                                :
                                                <label onClick={() => this.setState({showTime: true})}>
                                                    Show time <Icon name={'clock outline'} size={'large'}/>
                                                </label>
                                        }

                                    </Form.Field>
                                    <Form.Field inline
                                                className={'time-input'}>
                                        {
                                            showTime ?
                                                <>
                                                    <label>Time</label>
                                                    <div className={'time-section'}>
                                                        <DatePicker
                                                            selectsStart
                                                            /*showPreviousMonths={false}
                                                            placeholder={'Select a date'}
                                                            dateFormat="Pp"*/
                                                            timeFormat="HH:mm:ss"
                                                            showTimeSelect={true}
                                                            showTimeSelectOnly={true}
                                                            timeIntervals={60}
                                                            //minDate={moment().toDate()}
                                                            //selected={trip.time ? trip.time : ""}
                                                            onChange={(event, data) => this.handleDateChanged(event, data, "time")}
                                                            isClearable={false}
                                                            /* useWeekdaysShort={true}
                                                             showMonthDropdown
                                                             showYearDropdown*/
                                                            //showTimeInput
                                                            dropdownMode="select"
                                                            //timeCaption={'time'}
                                                            value={form.time ? form.time : '00:00:00'}
                                                            className={'datepicker-text'}
                                                        />
                                                        <TimezonePicker
                                                            value={form.deliveryDateTimezone}
                                                            onChange={(timezone) => {
                                                                this.changeTimezone(timezone)
                                                            }}
                                                            inputProps={{
                                                                placeholder: 'Select Timezone...',
                                                                name: 'timezone'
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                null
                                        }

                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Segment>
                        <Button loading={loader} onClick={() => this.addQuote()}>Add a quote</Button>
                        <Button className={'secondary-btn'}
                                onClick={this.handleCancel}>Cancel</Button>
                    </div>
                    : null
            }
        </div>
    };

    updateEmailForm = (field, value) => {
        this.setState({
            form: update(this.state.form, {
                [field]: {
                    $set: value
                }
            })
        }, this.validateState)
    };

    updateTripId = (tripId) => {
        this.setState({
            selectedTripId: tripId,
            showPrice: true
        }, () => {
            this.fieldValidations.push(
                ruleRunner("quote", 'quote', required)
            );
            this.validateState()
        })
    };

    addQuote = async () => {
        this.setState({showErrors: true});
        if (this.addEmailRef.current) {
            this.addEmailRef.current.validateState("", true);
            if (!Helper.isEmpty(this.addEmailRef.current.state.validationErrors)) return null
        }


        if (!Helper.isEmpty(this.state.validationErrors)) return null;
        this.setState({loader: true});

        let {requestId, form, selectedTripId} = this.state;
        let deliveredBeforeDate = Helper.toIsoString(form.deliveryDate, form.timezone, false);
        deliveredBeforeDate = `${deliveredBeforeDate.substring(0, 10)}T${form.time}${deliveredBeforeDate.substring(10)}`;

        let date = Helper.toIsoString(form.date, form.dateTimezone, false);
        date = `${date.substring(0, 10)}T${form.dateTime}${date.substring(10)}`;
        let requestBody = {
            requestId,
            deliveryDate: deliveredBeforeDate,
            deliveryDateTimezone: form.deliveryDateTimezone,
            price: form.quote
        }
        if (localStorage.getItem('accessToken')) {
            requestBody.existingTrip = {
                id: selectedTripId
            }
        } else {
            requestBody.newTrip = {
                date:date,
                dateTimezone: form.dateTimezoneValues,
                guestVerification: {
                    email: form.email,
                    verificationCode: form.verificationCode
                }
            }
        }
        const response = await this.requestApi.createQuote(requestBody);
        if (response.code === "SUCCESS000") {
            let form = {
                date: '',
                verificationCode: '',
                email: '',
                deliveryDate: "",
                time: "00:00:00",
                quote: ""
            }
            this.setState(
                {
                    show: false,
                    loader: false,
                    showPrice: false,
                    showForm: false,
                    form: form,
                    timezone: ""
                }
                , () => {
                    this.props.props.notify(false, 'Operation completed successfully')
                })
        } else {
            this.setState(
                {loader: false}
                , () => {
                    this.props.props.notify(true, response.data ? response.data.message : 'Error! please try again')
                })
        }
    };

    handleCancel = () => {
        let form = {
            date: '',
            verificationCode: '',
            email: '',
            quote: "",
            deliveryDate: "",
            time: "00:00:00",
        }
        this.setState({
            show: false,
            showPrice: false,
            showForm: false,
            form: form,
            timezone: ""
        })
    }

    handleDateChanged = (event, data, field) => {
        let newEvent = field === 'time' || field === 'dateTime' ? Helper.timeStampToDateTimeAsString(event, true, true)
            : Helper.timeStampToDateTimeAsString(event, false);
        this.setState({
            form: update(this.state.form, {
                [field]: {
                    $set: field === 'time' || field === 'dateTime' ? newEvent : event
                }
            })
        }, this.validateState)
    };

    changeTimezone = (timezone, field1 = 'timezone', field2 = 'deliveryDateTimezone') => {
        let selectedTimezone = timezones.find(a => a.name === timezone)

        this.setState({
            form: update(this.state.form, {
                [field1]: {
                    $set: selectedTimezone.offset
                }, [field2]: {
                    $set: timezone
                }
            })
            , timezone: timezone
        }, this.validateState)
    }

    render() {
        let {showPrice, loader, form, showTime} = this.state;
        const quoteError = this.context.errorFor(this.state, 'quote', null, true);
        const deliveryDateError = this.context.errorFor(this.state, 'deliveryDate', null, true);
        return (
            <Modal
                className={`quote-modal ${localStorage.getItem('accessToken') ? '' : 'custom'}`}
                onClose={() => this.setState({show: false, showPrice: false})}
                open={this.state.show}
                closeOnDimmerClick={false}
                closeIcon
            >
                <Modal.Header>Add a new quote</Modal.Header>
                <Modal.Content>
                    {
                        localStorage.getItem('accessToken') ?
                            <>
                                <GetRequestAvailableTrips fromQuote={true} props={this}/>
                                {
                                    showPrice ?
                                        <div>
                                            <Segment className={'quotation-segment'}>
                                                <Label>Quotation details</Label>
                                                <Form className={'quote-section'}>
                                                    <Form.Group widths={'equal'}>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!quoteError}
                                                        >
                                                            <label>Please enter a quote</label>
                                                            <Input placeholder="Quote"
                                                                   className={'quote-input'}
                                                                   type={'number'}
                                                                   value={form.quote}
                                                                   onChange={(event, data) => this.handlePriceChanged(event, data)}/>
                                                            <Header as={'h4'}>$</Header>
                                                            {quoteError}
                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    required
                                                                    error={!!deliveryDateError}
                                                                    className={'date-input'}
                                                        >
                                                            <label>Delivered before date</label>
                                                            <div className={'date-section'}>
                                                                <DatePicker
                                                                    selectsStart
                                                                    showPreviousMonths={false}
                                                                    placeholder={'Select a date'}
                                                                    dateFormat="Pp"
                                                                    // timeFormat="HH:mm:ss"
                                                                    //showTimeSelect
                                                                    //timeIntervals={60}
                                                                    minDate={moment().toDate()}
                                                                    selected={form.deliveryDate ? new Date(form.deliveryDate) : ""}
                                                                    onChange={(event, data) => this.handleDateChanged(event, data, "deliveryDate")}
                                                                    isClearable={false}
                                                                    useWeekdaysShort={true}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    //showTimeInput
                                                                    dropdownMode="select"
                                                                    //timeCaption={'time'}
                                                                    value={form.deliveryDate ? Helper.timeStampToDateTimeAsString(form.deliveryDate.toString(), false) : ''}
                                                                    className={'datepicker-text'}
                                                                />
                                                            </div>
                                                            {deliveryDateError}
                                                        </Form.Field>
                                                        <Form.Field inline className={'show-time-field'}>
                                                            {
                                                                showTime ?
                                                                    <label
                                                                        onClick={() => this.setState({showTime: false})}>
                                                                        Hide time <Icon name={'clock outline'}
                                                                                        size={'large'}/>
                                                                    </label>
                                                                    :
                                                                    <label
                                                                        onClick={() => this.setState({showTime: true})}>
                                                                        Show time <Icon name={'clock outline'}
                                                                                        size={'large'}/>
                                                                    </label>
                                                            }

                                                        </Form.Field>
                                                        <Form.Field inline
                                                                    className={'time-input'}>
                                                            {
                                                                showTime ?
                                                                    <>
                                                                        <label>Time</label>
                                                                        <div className={'time-section'}>
                                                                            <DatePicker
                                                                                selectsStart
                                                                                /*showPreviousMonths={false}
                                                                                placeholder={'Select a date'}
                                                                                dateFormat="Pp"*/
                                                                                timeFormat="HH:mm:ss"
                                                                                showTimeSelect={true}
                                                                                showTimeSelectOnly={true}
                                                                                timeIntervals={60}
                                                                                //minDate={moment().toDate()}
                                                                                //selected={trip.time ? trip.time : ""}
                                                                                onChange={(event, data) => this.handleDateChanged(event, data, "time")}
                                                                                isClearable={false}
                                                                                /* useWeekdaysShort={true}
                                                                                 showMonthDropdown
                                                                                 showYearDropdown*/
                                                                                //showTimeInput
                                                                                dropdownMode="select"
                                                                                //timeCaption={'time'}
                                                                                value={form.time ? form.time : '00:00:00'}
                                                                                className={'datepicker-text'}
                                                                            />
                                                                            <TimezonePicker
                                                                                value={form.deliveryDateTimezone}
                                                                                onChange={(timezone) => {
                                                                                    this.changeTimezone(timezone)
                                                                                }}
                                                                                inputProps={{
                                                                                    placeholder: 'Select Timezone...',
                                                                                    name: 'timezone'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }

                                                        </Form.Field>
                                                    </Form.Group>
                                                </Form>
                                            </Segment>
                                            <Button loading={loader} onClick={() => this.addQuote()}>Add a
                                                quote</Button>
                                            <Button className={'secondary-btn'}
                                                    onClick={this.handleCancel}>Cancel</Button>
                                        </div>
                                        : null
                                }
                            </>
                            : this.renderNewTripForm()
                    }

                </Modal.Content>
            </Modal>
        );
    }
}

export default QuoteModal;