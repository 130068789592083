import React, {Component} from 'react';
import {Header, List, Tab, Accordion, Icon} from 'semantic-ui-react'

export default class AdviseTabs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeIndex: 0
        }
    }

    handleClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({activeIndex: newIndex})
    }

    render() {
        const {activeIndex} = this.state;
        const panes = [
            {
                menuItem: 'Packaging Advise', render: () =>
                    <Tab.Pane>
                        <div className={'static-section process'}>
                            <div className={'images-section'}>
                                <img alt={'img'} src={'process.svg'}/>
                            </div>
                            <div className={'text-section'}>
                                <Header as={'h2'}>How “<h2 className={'app-name'}>ShipAIK </h2>” works? </Header>
                                <List bulleted>
                                    <List.Item>
                                        <Header as={'h2'}>
                                            Requester:</Header>
                                        <List.List>
                                            <List.Item><Header as={'h2'}>Place your request</Header></List.Item>
                                            <List.Item><Header as={'h2'}>Get quotations from
                                                Travelers</Header></List.Item>
                                            <List.Item><Header as={'h2'}>Select a quote and agree a
                                                deal</Header></List.Item>
                                            <List.Item><Header as={'h2'}>Get your request delivered and payment
                                                processed</Header></List.Item>
                                        </List.List>

                                    </List.Item>
                                    <List.Item>
                                        <Header as={'h2'}>
                                            Traveler:</Header>
                                        <List.List>
                                            <List.Item><Header as={'h2'}>Share your trip details</Header></List.Item>
                                            <List.Item><Header as={'h2'}>Get requests from
                                                Requesters</Header></List.Item>
                                            <List.Item><Header as={'h2'}>Agree deals (as many as you
                                                can)</Header></List.Item>
                                            <List.Item><Header as={'h2'}>Accomplish agreed requests and payment
                                                processed</Header></List.Item>
                                        </List.List>

                                    </List.Item>
                                </List>
                            </div>
                        </div>
                    </Tab.Pane>
            },
            {
                menuItem: 'Labeling Advise', render: () =>
                    <Tab.Pane>
                        <div className={'static-section process'}>
                            <div className={'images-section'}>
                                <img alt={'img'} src={'process.svg'}/>
                            </div>
                            <div className={'text-section'}>
                                <Header as={'h2'}>How “<h2 className={'app-name'}>ShipAIK </h2>” works? </Header>
                                <List bulleted>
                                    <List.Item>
                                        <Header as={'h2'}>
                                            Requester:</Header>
                                        <List.List>
                                            <List.Item><Header as={'h2'}>Place your request</Header></List.Item>
                                            <List.Item><Header as={'h2'}>Get quotations from
                                                Travelers</Header></List.Item>
                                            <List.Item><Header as={'h2'}>Select a quote and agree a
                                                deal</Header></List.Item>
                                            <List.Item><Header as={'h2'}>Get your request delivered and payment
                                                processed</Header></List.Item>
                                        </List.List>

                                    </List.Item>
                                    <List.Item>
                                        <Header as={'h2'}>
                                            Traveler:</Header>
                                        <List.List>
                                            <List.Item><Header as={'h2'}>Share your trip details</Header></List.Item>
                                            <List.Item><Header as={'h2'}>Get requests from
                                                Requesters</Header></List.Item>
                                            <List.Item><Header as={'h2'}>Agree deals (as many as you
                                                can)</Header></List.Item>
                                            <List.Item><Header as={'h2'}>Accomplish agreed requests and payment
                                                processed</Header></List.Item>
                                        </List.List>

                                    </List.Item>
                                </List>
                            </div>
                        </div>
                    </Tab.Pane>
            },
        ]
        return (
            <Accordion fluid styled className={'advices-tabs'}>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={this.handleClick}
                >
                    <Icon name='dropdown'/>
                    Packaging Advise
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                    <div className={'static-section advice'}>
                        <div className={'images-section advice'}>
                            <img alt={'img'} src={'Group-758-1.svg'}/>
                        </div>
                        <div className={'text-section'}>
                            <List bulleted>
                                <List.Item>
                                    <Header as={'h2'}>
                                        Make sure parcels are protected, handled and packaged in order to deliver in best possible conditions.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        Avoid <strong style={{fontWeight: 'bold'}}>reuse or use</strong> of damaged packaging units.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        Parcel content should <strong style={{fontWeight: 'bold'}}>properly fit</strong>within the packaging; Oversized packaging might collapse; tight filled package might burst.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        Using suitable <strong style={{fontWeight: 'bold'}}>durable packaging</strong> would protect the content, as well as its value.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        <strong style={{fontWeight: 'bold'}}>Cushioning</strong> is highly recommended to eliminate content movements inside the packaging,
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        <strong style={{fontWeight: 'bold'}}>Heavy delicate content</strong>should have sufficient cushioning starting from below the item, on the sides and above, prior closing the package.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        <strong style={{fontWeight: 'bold'}}>Fragile items</strong> should be placed at package center with sufficient cushioning all around, avoiding content movement, and avoid touching the package interior.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        Use <strong style={{fontWeight: 'bold'}}>soft cushioning</strong> to surround each unit of special items like hard-drives …etc.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        <strong style={{fontWeight: 'bold'}}>Liquids or semi-liquids</strong> should be kept in tight leakproof containers, stored within sealed plastic bag, to prevent surrounding damages.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        <strong style={{fontWeight: 'bold'}}>Heavy-smelling substance or greasy containers</strong> should be sealed with suitable tape, and wrapped with grease resistance paper, to prevent surrounding damages.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        Cover and firmly tape <strong style={{fontWeight: 'bold'}}>sharp or protruding parts</strong> with sufficient layers of corrugated strips.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        Before sealing, check <strong style={{fontWeight: 'bold'}}>package final weight</strong> that is still within acceptable limits, reduce the weight if needed or possible, taking in consideration above points.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        Seal the package sufficiently, avoiding <strong style={{fontWeight: 'bold'}}>unnecessary extreme sealing</strong> causing difficulty while opening the package, considering customs rights to open all shipments for inspection purposes.
                                    </Header>
                                </List.Item>
                            </List>
                        </div>
                    </div>
                </Accordion.Content>

                <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={this.handleClick}
                >
                    <Icon name='dropdown'/>
                    Labeling Advise
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                    <div className={'static-section advice'}>
                        <div className={'images-section'}>
                            <img alt={'img'} src={'labeled-package.svg'}/>
                        </div>
                        <div className={'text-section'}>
                            <List bulleted>
                                <List.Item>
                                    <Header as={'h2'}>
                                        Appropriate Labeling helps distinguish similar parcels, and deliver the right parcel to the awaiting receiver; mainly when delivering few similar parcels to different receivers simultaneously.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        Label parcels with <strong style={{fontWeight: 'bold'}}>“↑↑” UPWARD, “☂” KEEP DRY, FRAGILE or HANDLE WITH CARE symbols</strong> where applicable.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        It is recommended to have the <strong style={{fontWeight: 'bold'}}>receiver address and contact</strong> on the label, and sender address and contact where applicable, along with traveler contact.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        Provide <strong style={{fontWeight: 'bold'}}>complete addresses and contacts</strong>, preferred in printing.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        For <strong style={{fontWeight: 'bold'}}>handwritten label</strong> use clear readable writing, recommending capital letters use.
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h2'}>
                                        Keep <strong style={{fontWeight: 'bold'}}>copy of the label</strong> inside the package for ease reference in case outer label peeled-off or damaged.
                                    </Header>
                                </List.Item>
                            </List>
                        </div>
                    </div>
                </Accordion.Content>
            </Accordion>

        );
    }
}
