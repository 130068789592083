export default class ErrorMessages {
    static isRequired = fieldName => `${fieldName} is required field`;
}


//12
//21
//22 for from and to state
export const errorsMessages = [
    //User error

    {code: "USER000", field: "serviceType", message: "Invalid identifier and/or password!"},
    {code: "USER001", field: "fromState", message: "Invalid email address!"},
    {code: "USER002", field: "phoneNumberCountryCode", message: "The international calling code is invalid!"},
    {code: "USER003", field: "email", message: "Invalid email address format!"},
    {code: "USER004", field: "phoneNumberNSN", message: "Phone number must be between 8 and 11 digits!"},
    {
        code: "USER005",
        field: "password",
        message: "The password must be between 8 and 32 characters and contain 1 upper case letter, 1 small case l latter, 1 number and 1 special character at least! (Special characters are !@#$%^&*-)!"
    },
    {
        code: "USER005",
        field: "newPassword",
        message: "The password must be between 8 and 32 characters and contain 1 upper case letter, 1 small case l latter, 1 number and 1 special character at least! (Special characters are !@#$%^&*-)!"
    },
    {code: "USER006", field: "weightUnit", message: "Invalid identifier and/or verification code!"},
    {
        code: "USER007",
        field: "documentPrice",
        message: "This verification code is expired, used or invalid! please request a new one."
    },
    {code: "USER008", field: "weightUnitPrice", message: "This account is not active!"},
    {code: "USER009", field: "volumetricPrice", message: "Invalid email and/or verification code!"},
    {code: "USER011", field: "oldPassword", message: "Old password is not correct!"},
    {
        code: "USER012",
        field: "firstName",
        message: "First name must contain english or arabic letters only and be between 5 and 50 letters in length!"
    },
    {
        code: "USER013",
        field: "lastName",
        message: "Last name must contain english or arabic letters only and be between 5 and 50 letters in length!"
    },


    /*"USER002": {"The international calling code is invalid!", 400},
"USER003": {"Invalid email address format!", 400},
"USER004": {"Phone number must be between 8 and 11 digits!", 400},*/

//Trips error

    {code: "TRIP000", field: "serviceType", message: "Invalid trip type!"},
    {code: "TRIP001", field: "fromStateId", message: "Trip source state is invalid!"},
    {code: "TRIP002", field: "transportation", message: "Trip transportation can be plane, car, train or ship!"},
    {code: "TRIP003", field: "date2", message: "Trip date must be greater than now by 24 hours at least!"},
    {code: "TRIP004", field: "deliverableType", message: "Deliverable type can be anything or documents only!"},
    {code: "TRIP005", field: "weight", message: "Weight must be greater than zero!"},
    {code: "TRIP006", field: "weightUnit", message: "Weight unit can be Kg or Lbs only!"},
    {code: "TRIP007", field: "documentPrice", message: "Document price must be greater than zero!"},
    {code: "TRIP008", field: "weightUnitPrice", message: "Weight price must be greater than zero!"},
    {code: "TRIP009", field: "volumetricPrice", message: "Volumetric price must be greater than zero!"},
    {code: "TRIP010", field: "itemEstimatedDeliveryCharge", message: "Trip not found!"},
    {
        code: "TRIP011",
        field: "itemEstimatedInsurance",
        message: "A quote request has been already requested for this request!"
    },
    {code: "TRIP012", field: "deliverToAddress", message: "Only scheduled trips can be changed to ongoing ones!"},
    {code: "TRIP013", field: "name", message: "Only ongoing trips can be changed to completed ones!"},
    {code: "TRIP014", field: "quantity", message: "Only scheduled and ongoing trips can be cancelled!"},
    {code: "TRIP015", field: "weight", message: "Invalid trip status!"},
    {code: "TRIP016", field: "weightUnit", message: "Only scheduled trips can be deleted!"},
    {code: "TRIP017", field: "itemPhoto", message: "Only ongoing trips can their requests be updated!"},
    {code: "TRIP018", field: "deliverableType", message: "No confirmed quotes for this request!"},
    {code: "TRIP019", field: "itemUrl", message: "Only confirmed trips can be switched to being delivered!"},
    {code: "TRIP020", field: "itemPhoto", message: "Only being delivered trips can be switched to delivered!"},
    {code: "TRIP021", field: "deliverToAddress", message: "Invalid request status!"},


    {code: "TRIP023", field: "fromCountryId", message: "Trip source country are invalid!"},
    {code: "TRIP024", field: "fromCityId", message: "Trip source destination city are invalid!"},
    {code: "TRIP028", field: "toCountryId", message: "Trip destination country are invalid!"},
    {code: "TRIP029", field: "toStateId", message: "Trip destination state is invalid!"},
    {code: "TRIP030", field: "toCityId", message: "Trip destination city are invalid!"},
    {code: "TRIP031", field: "toCityId", message: "Trip is already revealed!"},
    {code: "TRIP032", field: "toCityId", message: "Pick code is invalid!"},
    {code: "TRIP033", field: "toCityId", message: "Trips which have non-scheduled status can't be updated!"},
    {code: "TRIP034", field: "serviceType", message: "Service type must be greater than 0!"},

    //Requests errors
    {code: "REQUEST000", field: "serviceType", message: "Invalid request type!"},
    {
        code: "REQUEST001",
        field: "deliveredBeforeDate2",
        message: "Request delivery date must be greater than now by 72 hours at least!"
    },
    {
        code: "REQUEST002",
        field: "deliverToAddress",
        message: "Request delivery address can consist of letters, numbers, dash, period and comma and it must be between 10 and 250 in length!"
    },
    {code: "REQUEST003", field: "paymentMethod", message: "Invalid payment method!"},
    {
        code: "REQUEST004",
        field: "pickUpAddress",
        message: "Request pickup address can consist of letters, numbers, dash, period and comma and it must be between 10 and 250 in length!"
    },
    {code: "REQUEST005", field: "length", message: "Item length must be greater than zero!"},
    {code: "REQUEST006", field: "width", message: "Item width must be greater than zero!"},
    {code: "REQUEST007", field: "height", message: "Item height must be greater than zero!"},
    {
        code: "REQUEST008",
        field: "description",
        message: "Request description must consists of english letters, arabic letters and numbers only and it must be between 10 and 150 in length!"
    },
    {code: "REQUEST009", field: "itemDimensionUnit", message: "Invalid dimension unit!"},
    {
        code: "REQUEST010",
        field: "estimatedDeliveryCharge",
        message: "Estimated delivery charge must be greater than zero!"
    },
    {code: "REQUEST011", field: "itemEstimatedInsurance", message: "Estimated insurance must be greater than zero!"},
    {code: "REQUEST012", field: "deliverToAddress", message: "Estimated item price must be greater than zero!!"},
    {
        code: "REQUEST013",
        field: "name",
        message: "Item name must consists of english letters, arabic letters and numbers only and it must be between 5 and 50 in length!"
    },
    {code: "REQUEST014", field: "quantity", message: "Item quantity must be greater than zero!"},
    {code: "REQUEST015", field: "weight", message: "Item weight must be greater than zero!"},
    {code: "REQUEST016", field: "weightUnit", message: "Invalid weight unit!"},
    {code: "REQUEST017", field: "photoUrl", message: "Invalid item photo. Allowed extensions are JPG,JPEG and PNG!"},
    {code: "REQUEST018", field: "deliverableType", message: "Deliverable type can be anything or documents only!"},
    {code: "REQUEST019", field: "url", message: "Invalid item URL!"},
    {code: "REQUEST020", field: "photoUrl", message: "Item photo size must be smaller than 2MB!"},
    {code: "REQUEST021", field: "deliverToAddress", message: "Request not found!"},
    {code: "REQUEST022", field: "fromStateId", message: "Request source state is invalid!"},
    {code: "REQUEST023", field: "deliverToAddress", message: "Request quote price must be greater than zero!"},
    {code: "REQUEST024", field: "deliverToAddress", message: "The request has already quote provided by that trip!"},
    {code: "REQUEST025", field: "deliverToAddress", message: "The request doesn't have quote requests for that trip!"},
    {code: "REQUEST026", field: "deliverToAddress", message: "The request has already an accepted quote!"},
    {code: "REQUEST027", field: "deliverToAddress", message: "The request doesn't have quotes provided by that trip!"},
    {
        code: "REQUEST028",
        field: "deliverToAddress",
        message: "Quotes that are not accepted by the requester can't be confirmed!"
    },
    {
        code: "REQUEST029",
        field: "deliverToAddress",
        message: "Confirmed, being delivered and delivered requests can't be deleted!"
    },
    {
        code: "REQUEST030",
        field: "deliverToAddress",
        message: "The request can't be attached to that trip. Either of deliverable type, from, to, or dates don't match!"
    },
    {
        code: "REQUEST032",
        field: "deliverToAddress",
        message: "Only newly created requests can be status changed!"
    },

    {code: "REQUEST033", field: "fromCountryId", message: "Request source country is invalid!"},
    {code: "REQUEST034", field: "fromCityId", message: "Request source city is invalid!"},
    {
        code: "REQUEST035",
        field: "deliverToAddress",
        message: "The combination of country, state and city is invalid!"
    },
    {
        code: "REQUEST036",
        field: "deliverToAddress",
        message: "The request already have another confirmed quote!"
    },
    {
        code: "REQUEST038",
        field: "toCountryId",
        message: "Request destination country are invalid!"
    },
    {
        code: "REQUEST039",
        field: "toStateId",
        message: "Request destination state is invalid!"
    },
    {
        code: "REQUEST040",
        field: "toCityId",
        message: "Request destination city is invalid!"
    },
    {
        code: "REQUEST041",
        field: "age",
        message: "Age must be greater than 0 and smaller than 131!"
    },
    {
        code: "REQUEST042",
        field: "nationality",
        message: "Invalid nationality!"
    },
    {
        code: "REQUEST043",
        field: "languages",
        message: "Languages can consists of english letters, arabic letters and numbers only and it must be between 5 and 50 in length!"
    },
    {
        code: "REQUEST044",
        field: "medicalStatus",
        message: "Medical status can consists of english letters, arabic letters and numbers only and it must be less than 150 in length!"
    },
    {
        code: "REQUEST045",
        field: "diet",
        message: "Diet can consists of english letters, arabic letters and numbers only and it must be less than 100 in length!"
    },
    {
        code: "REQUEST046",
        field: "needs",
        message: "Needs can consists of english letters, arabic letters and numbers only and it must be less than 100 in length!"
    },
    {
        code: "REQUEST047",
        field: "airportFrom",
        message: "From Airport can consists of english letters, arabic letters and numbers only and it must be between 5 and 50 in length!"
    },
    {
        code: "REQUEST048",
        field: "airportTo",
        message: "To Airport can consists of english letters, arabic letters and numbers only and it must be between 5 and 50 in length!"
    },
    {
        code: "REQUEST049",
        field: "flightNumber",
        message: "Flight Number can consists of english letters, arabic letters and numbers only and it must be between 3 and 100 in length!"
    },
    {
        code: "REQUEST050",
        field: "disablilities",
        message: "Invalid disability!"
    },
    {
        code: "REQUEST051",
        field: "additionalAccessories",
        message: "Additional Accessories can consists of english letters, arabic letters and numbers only and it must be less than 100 in length!"
    },
    {
        code: "REQUEST052",
        field: "food",
        message: "Food can consists of english letters, arabic letters and numbers only and it must be less than 100 in length!"
    },
    {
        code: "REQUEST053",
        field: "medicalCertificate",
        message: "Medical Certificate can consists of english letters, arabic letters and numbers only and it must be less than 100 in length!"
    },
    {
        code: "REQUEST054",
        field: "transportCertificate",
        message: "Transport Certificate can consists of english letters, arabic letters and numbers only and it must be less than 100 in length!"
    },
    {
        code: "REQUEST055",
        field: "species",
        message: "Species can consists of english letters, arabic letters and numbers only and it must be between 2 and 100 in length!"
    },


    // Tickets error
    {
        code: "TICKET000",
        field: "category",
        message: "Invalid ticket category!"
    },
    {
        code: "TICKET001",
        field: "priority",
        message: "Invalid ticket priority!"
    },
    {
        code: "TICKET002",
        field: "subject",
        message: "The ticket subject can consists of english letters, arabic letters, numbers and characters ,?!()_-' only and it must be between 5 and 100 in length!"
    },
    {
        code: "TICKET003",
        field: "text",
        message: "The ticket text/reply can consists of english letters, arabic letters, numbers and characters ,?!()_-' only and it must be between 10 and 350 in length!"
    },
    {
        code: "TICKET009",
        field: "attachments",
        message: "Invalid attachment. Allowed extensions are JPG,JPEG and PNG!"
    },
    {
        code: "TICKET010",
        field: "attachments",
        message: "Max attachment size is 2MB!"
    },
    {
        code: "TICKET011",
        field: "attachments",
        message: "Max attachments count is 10!"
    }
];