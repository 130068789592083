import React, {Component} from 'react'
import CustomTable from "../../requests/src/requests";

export default class Tickets extends Component {

    constructor(props){
        super(props);
        this.state = {
            items: []
        }
    }

    render() {
        return (
            <div className={"tickets-container"}>
                <CustomTable isEditableTable={true}
                             model={{
                                 title: '',
                                 name: 'ticket',
                                 actions: {
                                     view: 'getUsers',
                                     update: 'updateUser',
                                     delete: 'deleteUser'
                                 },
                                 headers: [
                                     {title: "Owner", width: 3},
                                     {title: "Subject", width: 4},
                                     {title: "Category", width: 3},
                                     {title: "Priority", width: 3},
                                     {title: "Status", width: 3},

                                 ],
                                 schema: 'support',
                                 updateRoute: (id) => `view/${id}`,
                             }}
                             showGridActions= {this.props.hideAction? false: true}
                             items={this.state.items}
                             {... this.props}
                />
            </div>
        )
    }
}
