import React, {Component} from 'react';
import {Header, List} from 'semantic-ui-react'

export default class Process extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        return (
            <div className={'static-section process'}>
                <div className={'images-section'}>
                    <img alt={'img'} src={'process.svg'}/>
                </div>
                <div className={'text-section'}>
                    <Header as={'h2'}>How “<h2 className={'app-name'}>ShipAIK </h2>” works? </Header>
                    <List bulleted>
                        <List.Item>
                            <Header as={'h2'}>
                                Requester:</Header>
                                <List.List>
                                    <List.Item><Header as={'h2'}>Place your request</Header></List.Item>
                                    <List.Item><Header as={'h2'}>Get quotations from Travelers</Header></List.Item>
                                    <List.Item><Header as={'h2'}>Select a quote and agree a deal</Header></List.Item>
                                    <List.Item><Header as={'h2'}>Get your request delivered and payment processed</Header></List.Item>
                                </List.List>

                        </List.Item>
                        <List.Item>
                            <Header as={'h2'}>
                                Traveler:</Header>
                                <List.List>
                                    <List.Item><Header as={'h2'}>Share your trip details</Header></List.Item>
                                    <List.Item><Header as={'h2'}>Get requests from Requesters</Header></List.Item>
                                    <List.Item><Header as={'h2'}>Agree deals (as many as you can)</Header></List.Item>
                                    <List.Item><Header as={'h2'}>Accomplish agreed requests and payment processed</Header></List.Item>
                                </List.List>

                        </List.Item>
                    </List>
                </div>
            </div>

        );
    }
}
