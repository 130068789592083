import React, {Component} from 'react';
import {Modal, Image} from "semantic-ui-react";
class PhotoModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,

        }
    }

    showModal = (image) => {
        this.setState({
            show: true,
            image: image
        })
    };

    render() {
        return (
            <Modal
                className={'photo-modal'}
                onClose={() => this.setState({show: false})}
                open={this.state.show}
            >
                <Modal.Header>Item photo</Modal.Header>
                <Modal.Content>
                    <Image size='medium' src={`${this.state.image? this.state.image: 'https://react.semantic-ui.com/images/wireframe/image-square.png'}`}/>
                </Modal.Content>
            </Modal>
        );
    }
}

export default PhotoModal;