import AxiosApi from '../../api/axios-api';


export default class NotificationApi {

    constructor(props) {
        this.body = props;
    }

    getUnreadNotificationsCount = async (body) => {

        return await AxiosApi.call(body, `/notification/unread-count`, 'get');
    }

    getAll = async (body) => {
        return await AxiosApi.call(body, `/notification/${body.pageNumber}`, 'get');
    }
}