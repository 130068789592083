import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Menu, Dropdown, Header, Icon, Loader} from "semantic-ui-react";
import NotificationApi from "./notification/api/notification-api";
import ItemDetails from "./table/item-details";
import InfiniteScroll from "react-infinite-scroll-component";
import {slide as Burger} from 'react-burger-menu'


class MainLayout extends Component {
    constructor(props) {
        super(props);
        this.pathName = props ? props.location.pathname.substring(1) : 'trips';
        this.notificationApi = new NotificationApi(this);
        this.state = {
            notificationsCount: localStorage.getItem("unreadNotificationsCount") ? localStorage.getItem("unreadNotificationsCount") : 0,
            notifications: [],
            firsCall: true,
            pageNumber: 1,
            activeItem: this.pathName !== 'home' ? this.pathName : 'home',
            hideLayout: this.pathName === 'sign-in' || this.pathName === 'sign-up' || this.pathName === 'reset-password',
            loading: true,
            innerWidth: window.innerWidth,
            menuOpen: false
        };
        this.detailsModalRef = React.createRef();
        this.user = localStorage.getItem('accessToken')
    }


    changeInnerWidth = () => {
        this.setState({
            innerWidth: window.innerWidth
        })
    }

    componentWillMount() {
        window.onresize = this.changeInnerWidth;
    }

    handleItemClick = (e, {name}) => {
        this.setState({
            activeItem: name,
            menuOpen: false
        }, () => {
            this.props.history.push(`/${name === 'home' ? '' : name === 
            'support' || name === 'trips' || name === 'requests' ? name + '/1': name}`)
        })
    };

    getNotifications = async (clicked = false) => {
        let {pageNumber, notifications, notificationsCount} = this.state;
        if (notifications.length >= 500) {
            this.setState({hasMore: false});
            return;
        }
        let requestedPageNumber = clicked ? 1 : pageNumber;
        const response = await this.notificationApi.getAll({pageNumber: requestedPageNumber});
        this.setState({loading: false});
        if (response.code === "SUCCESS000") {
            let newNotificationCount = notificationsCount > 10 && requestedPageNumber === 1 ? notificationsCount - 10 :
                notificationsCount < 10 ? 0 : notificationsCount;
            if (response.data.totalPages > 0) {
                let items = clicked ? response.data.list : notifications.concat(response.data.list);
                let hasMore = requestedPageNumber + 1 <= response.data.totalPages;
                localStorage.setItem("unreadNotificationsCount", "0");
                this.setState({
                    notifications: items,
                    pageNumber: requestedPageNumber + 1,
                    hasMore: hasMore,
                    notificationsCount: newNotificationCount
                })
            }
            return null
        } else {
            return null
        }
    };

    getNotificationsCount = async () => {
        const response = await this.notificationApi.getUnreadNotificationsCount();
        if (response.code === "SUCCESS000") {
            this.setState({
                notificationsCount: response.data.count,
                firstCall: false,

            })
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        let hideLayout = nextProps.location.pathName === '/sign-in' || nextProps.location.pathName === '/sign-up'
            || nextProps.location.pathName === '/reset-password';
        this.setState({
            hideLayout: hideLayout
        })
    }

    componentDidMount() {
        if (this.user) {
            this.interval = setInterval(() => this.getNotificationsCount(), 30000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    removeLayout = () => {
        let navBar = document.getElementById('navbar');
        if (navBar) {
            navBar.style.display = 'none'
        }
        this.props.history.push("/sign-in");
        window.location.reload(true)
    };

    notificationClicked = (e, notification) => {
        let id = '',
            name = '';
        if (notification.trigger === 1) {
            id = notification.tripId;
            name = 'trip';
        } else if (notification.trigger === 2){
            id = notification.requestId;
            name = 'request';
        } else {
            this.props.history.push(`/support/view/${notification.ticketId}`);
            id = notification.ticketId;
            name = 'ticket';
        }
        this.detailsModalRef.current.show(id, name, notification);
    };

    refresh = () => {
    };

    createNotificationText = (notification) => {
        let nameHeader = <Header as={'h3'}>
            {notification.sender.name}
        </Header>;
        let notificationText = null;
        if (notification) {
            switch (notification.action) {
                case 1:
                    if (notification.type === 1) {
                        notificationText = <p className={'notification-text'}>
                            {nameHeader}
                            {" asked for a quote."}
                        </p>
                    } else if (notification.type === 2) {
                        notificationText = <p className={'notification-text'}>
                            {nameHeader}
                            {" created a quote for you."}
                        </p>
                    } else if(notification.type === 5){
                        notificationText = <p className={'notification-text'}>
                            {nameHeader}
                            {" has created a new ticket."}
                        </p>
                    }
                    break;
                case 2:
                    notificationText = <p className={'notification-text'}>
                        {nameHeader}
                        {" accepted your quote."}
                    </p>
                    break;
                case 3:
                    notificationText = <p className={'notification-text'}>
                        {nameHeader}
                        {" confirmed his quote, please check your email."}
                    </p>
                    break;
                case 4:
                    notificationText = <p className={'notification-text'}>
                        {nameHeader}
                        {" request is waiting for your confirmation!"}
                    </p>
                    break;
                case 5:
                    notificationText = <p className={'notification-text'}>
                        {"The traveller has cancelled his trip!"}
                    </p>
                    break;
                case 6:
                    notificationText = <p className={'notification-text'}>
                        {"The traveller has updated his trip and his quote has been revoked!!"}
                    </p>
                    break;
                case 7:
                    if (notification.type === 4) {
                        if (notification.trigger === 1) {
                            notificationText = <p className={'notification-text'}>
                                {nameHeader}
                                {" has confirmed that you've picked his request."}
                            </p>
                        } else {
                            notificationText = <p className={'notification-text'}>
                                {"You've confirmed that your request has been picked by the traveler."}
                            </p>
                        }
                    }
                    break;
                case 8:
                    if (notification.type === 4) {
                        if (notification.trigger === 1) {
                            notificationText = <p className={'notification-text'}>
                                {"You've confirmed that you're delivering a request."}
                            </p>
                        } else {
                            notificationText = <p className={'notification-text'}>
                                {"Your request is being delivered by "}
                                {nameHeader}.
                            </p>
                        }
                    }
                    break;
                case 9:
                    if (notification.type === 4) {
                        if (notification.trigger === 1) {
                            notificationText = <p className={'notification-text'}>
                                {"The requester's confirmed that you've delivered his request successfully."}
                            </p>
                        } else {
                            notificationText = <p className={'notification-text'}>
                                {"You've confirmed that your request has been delivered successfully."}
                            </p>
                        }
                    }
                    break;
                case 10:
                    if (notification.type === 3 && notification.trigger === 2) {
                        notificationText = <p className={'notification-text'}>
                            {nameHeader}
                            {" has just started his trip."}
                        </p>
                    }
                    break;
                case 11:
                    if (notification.type === 5 && notification.trigger === 3) {
                        notificationText = <p className={'notification-text'}>
                            {nameHeader}
                            {" has added a new reply to the ticket."}
                        </p>
                    }
                    break;
                case 12:
                    if (notification.type === 5 && notification.trigger === 3) {
                        notificationText = <p className={'notification-text'}>
                            {"Your ticket has been closed."}
                        </p>
                    }
                    break;
                default:
                    break
            }
        }
        return notificationText
    };

    handleStateChange(state) {
        this.setState({menuOpen: state.isOpen})
    }

    render() {
        const {activeItem, notificationsCount, notifications, firstCall, hideLayout, loading, innerWidth, isOpen} = this.state;
        let userName = localStorage.getItem('identifier');
        let user = localStorage.getItem('accessToken');
        if (this.pathName === 'status') {
            return null;
        } else {
            return (
                <div className={'main-container'}>
                    {
                        !hideLayout ?
                            innerWidth <= 1024 ?
                                <div className={'navbar-section mobile'}>
                                    <Burger right isOpen={this.state.menuOpen}
                                            onStateChange={(state) => this.handleStateChange(state)}
                                    >
                                        <Menu.Item
                                            name='home'
                                            onClick={this.handleItemClick}
                                        >
                                            <img src={'/shipaik-logo.png'}
                                                 alt={'img'}
                                            />
                                        </Menu.Item>
                                        {
                                            user ?
                                                <Menu.Item
                                                    name='profile'
                                                    onClick={this.handleItemClick}>
                                                    <span><Icon name='user outline' size={'big'}/>{userName}</span>
                                                </Menu.Item>
                                                :
                                                <Menu.Item name='login'
                                                           onClick={() => this.removeLayout()
                                                           }>
                                                    Login / Sign up
                                                </Menu.Item>
                                        }

                                        <Menu.Item
                                            name='about'
                                            active={activeItem === 'about'}
                                            onClick={this.handleItemClick}
                                        />
                                        <Menu.Item
                                            name='process'
                                            active={activeItem === 'process'}
                                            onClick={this.handleItemClick}
                                        />
                                        <Menu.Item
                                            name='faqs'
                                            active={activeItem === 'faqs'}
                                            onClick={this.handleItemClick}
                                        >
                                            FAQs
                                        </Menu.Item>
                                        <Menu.Item
                                            name='trips'
                                            active={activeItem === 'trips'}
                                            onClick={this.handleItemClick}
                                        />
                                        <Menu.Item
                                            name='requests'
                                            active={activeItem === 'requests'}
                                            onClick={this.handleItemClick}
                                        />
                                        {
                                            user ?
                                                <Menu.Item
                                                    name='logout'
                                                    onClick={this.handleItemClick}>
                                                    Sign out
                                                </Menu.Item>
                                                : null
                                        }
                                    </Burger>
                                    {
                                        user ?
                                            <Menu.Menu position='left'>
                                                <Dropdown className={'notification-section'}
                                                          onClick={() => this.getNotifications(true)}
                                                          item trigger={(
                                                    <span>
                                <Icon name='bell' size={'large'}/>
                                                        {
                                                            notificationsCount === 0 && firstCall ?
                                                                this.getNotificationsCount()
                                                                :
                                                                notificationsCount > 0 ?
                                                                    <div
                                                                        className="ui red label mini circular notifications-counts">
                                                                        {
                                                                            notificationsCount > 99 ? '99+' : notificationsCount
                                                                        }
                                                                    </div>

                                                                    : null
                                                        }
                            </span>
                                                )}>
                                                    {
                                                        notifications ?
                                                            <Dropdown.Menu id={'notification'}
                                                                           className={'notification-list'}>
                                                                {
                                                                    loading ?
                                                                        <Dropdown.Item className={'loader-item'}>
                                                                            <Loader active size='small'/>
                                                                        </Dropdown.Item>
                                                                        :
                                                                        <InfiniteScroll
                                                                            dataLength={notifications ? notifications.length : 0}
                                                                            next={this.getNotifications}
                                                                            hasMore={this.state.hasMore}
                                                                            loader={<h4>Loading...</h4>}
                                                                            refreshFunction={this.refresh}
                                                                            endMessage={notifications.length === 0 ? <h4
                                                                                style={{
                                                                                    textAlign: 'center',
                                                                                    color: 'black',
                                                                                    marginTop: '0px',
                                                                                    padding: '15px 30px'
                                                                                }}>
                                                                                No notifications to show!</h4> : ''}
                                                                            pullDownToRefresh
                                                                            pullDownToRefreshThreshold={50}
                                                                        >
                                                                            {notifications.map((notification, index) => {
                                                                                return <Dropdown.Item id={index}
                                                                                                      key={index}
                                                                                                      className={`notification notification${index} ${notification ? notification
                                                                                                          .status === 1 ?
                                                                                                          'notification-background' :
                                                                                                          'notification-without-background' : ''}
                                                                        ${index === notifications.length - 1 ? 'last-notification' : ''}`}
                                                                                                      onClick={(e) => this.notificationClicked(e, notification)}>

                                                                                    {/*<Header
                                                                                    as={'h4'}>{notification.sender.name}</Header>*/}
                                                                                    {this.createNotificationText(notification)}
                                                                                    <Header
                                                                                        as={'h4'}>{notification.createTime ? notification.createTime.replace('Z', ' ').replace('T', ' ') : ''}</Header>

                                                                                </Dropdown.Item>
                                                                            })}

                                                                        </InfiniteScroll>
                                                                }
                                                                <p className={'more-section'} onClick={() => this.props.history.push("/notifications/1")}>
                                                                    Show more
                                                                </p>
                                                            </Dropdown.Menu>
                                                            : null
                                                    }


                                                </Dropdown>
                                            </Menu.Menu>
                                            : null
                                    }
                                </div>
                                :
                                <Menu id={"navbar"} borderless className={"navbar-section"}>
                                    <Menu.Item
                                        name='home'
                                        onClick={this.handleItemClick}
                                    >
                                        <img src={'/shipaik-logo.png'}
                                             alt={'img'}
                                             className={'logo-img'}
                                        />
                                    </Menu.Item>
                                    <Menu.Item
                                        name='about'
                                        active={activeItem === 'about'}
                                        onClick={this.handleItemClick}
                                    />
                                    <Menu.Item
                                        name='process'
                                        active={activeItem === 'process'}
                                        onClick={this.handleItemClick}
                                    />
                                    <Menu.Item
                                        name='faqs'
                                        active={activeItem === 'faqs'}
                                        onClick={this.handleItemClick}
                                    >
                                        FAQs
                                    </Menu.Item>
                                    <Menu.Item
                                        name='trips'
                                        active={activeItem === 'trips'}
                                        onClick={this.handleItemClick}
                                    />
                                    <Menu.Item
                                        name='requests'
                                        active={activeItem === 'requests'}
                                        onClick={this.handleItemClick}
                                    />
                                    {
                                        user ?
                                            <Menu.Menu position='right'>
                                                <Dropdown className={'notification-section'}
                                                          onClick={() => this.getNotifications(true)}
                                                          item trigger={(
                                                    <span>
                                <Icon name='bell' size={'large'}/>
                                                        {
                                                            notificationsCount === 0 && firstCall ?
                                                                this.getNotificationsCount()
                                                                :
                                                                notificationsCount > 0 ?
                                                                    <div
                                                                        className="ui red label mini circular notifications-counts">
                                                                        {
                                                                            notificationsCount > 99 ? '99+' : notificationsCount
                                                                        }
                                                                    </div>

                                                                    : null
                                                        }
                            </span>
                                                )}>
                                                    {
                                                        notifications ?
                                                            <Dropdown.Menu id={'notification'}
                                                                           className={'notification-list'}>
                                                                {
                                                                    loading ?
                                                                        <Dropdown.Item className={'loader-item'}>
                                                                            <Loader active size='small'/>
                                                                        </Dropdown.Item>
                                                                        :
                                                                        <InfiniteScroll
                                                                            dataLength={notifications ? notifications.length : 0}
                                                                            next={this.getNotifications}
                                                                            hasMore={this.state.hasMore}
                                                                            loader={<h4>Loading...</h4>}
                                                                            refreshFunction={this.refresh}
                                                                            endMessage={notifications.length === 0 ? <h4
                                                                                style={{
                                                                                    textAlign: 'center',
                                                                                    color: 'black',
                                                                                    marginTop: '0px',
                                                                                    padding: '15px 30px'
                                                                                }}>
                                                                                No notifications to show!</h4> : ''}
                                                                            pullDownToRefresh
                                                                            pullDownToRefreshThreshold={50}
                                                                        >
                                                                            {notifications.map((notification, index) => {
                                                                                return <Dropdown.Item id={index}
                                                                                                      key={index}
                                                                                                      className={`notification notification${index} ${notification ? notification
                                                                                                          .status === 1 ?
                                                                                                          'notification-background' :
                                                                                                          'notification-without-background' : ''}
                                                                        ${index === notifications.length - 1 ? 'last-notification' : ''}`}
                                                                                                      onClick={(e) => this.notificationClicked(e, notification)}>

                                                                                    {/*<Header
                                                                                    as={'h4'}>{notification.sender.name}</Header>*/}
                                                                                    {this.createNotificationText(notification)}
                                                                                    <Header
                                                                                        as={'h4'}>{notification.createTime ? notification.createTime.replace('Z', ' ').replace('T', ' ') : ''}</Header>

                                                                                </Dropdown.Item>
                                                                            })}

                                                                        </InfiniteScroll>
                                                                }
                                                                <p className={'more-section'} onClick={() => this.props.history.push("/notifications/1")}>
                                                                    Show more
                                                                </p>
                                                            </Dropdown.Menu>
                                                            : null
                                                    }


                                                </Dropdown>
                                                <Dropdown className={'profile-section'} item trigger={(
                                                    <span><Icon name='user outline' size={'big'}/>{userName}</span>
                                                )}>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item disabled>
                                                            <Icon name={'mail'}/>
                                                            {localStorage.getItem('email')}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => this.props.history.push("/profile")}>
                                                            Profile
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => this.props.history.push("/logout")}>
                                                            Logout
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Menu.Menu>
                                            :
                                            <Menu.Menu position='right'>
                                                <img
                                                    src={'https://cdn1.iconfinder.com/data/icons/arrow-symbols-interface/32/arrow-36-1024.png'}
                                                    onClick={() =>
                                                        this.removeLayout()
                                                    }
                                                    alt={'text'}
                                                />
                                            </Menu.Menu>
                                    }

                                </Menu>
                            : null
                    }

                    <div className={'children'}>
                        {this.props.children}
                    </div>
                    <div className={'footer-section'}>
                        <div className="footer">
                            <Menu>
                                {/* <Menu.Item>
                                    ©️ 2022 Shipaik. All rights reserved.
                                </Menu.Item>*/}
                                {
                                    user ?
                                        <Menu.Item
                                            className={'underline'}
                                            name='support'
                                            active={activeItem === 'support'}
                                            onClick={this.handleItemClick}
                                        />
                                        : null
                                }
                                <Menu.Item
                                    className={'underline'}
                                    name='disclaimer'
                                    active={activeItem === 'disclaimer'}
                                    onClick={this.handleItemClick}
                                />
                                <Menu.Item
                                    className={'underline'}
                                    name='Advises'
                                    active={activeItem === 'Advises'}
                                    onClick={this.handleItemClick}
                                />
                                <Menu.Menu position='right'>
                                    <p>
                                        ©️ 2022 Shipaik. All rights reserved.
                                    </p>
                                </Menu.Menu>
                            </Menu>
                        </div>
                    </div>
                    <ItemDetails ref={this.detailsModalRef} props={this}/>
                </div>
            );
        }
    }
}

export default withRouter(MainLayout);